import React, { useRef, useState, useContext } from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Divider, Paper, Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import GoogleLogin from "react-google-login"
import ReCAPTCHA from "react-google-recaptcha"

import AuthContext from "../../contexts/authContext"
import { loginUser } from "../../services/auth"

const useStyles = makeStyles((theme) => ({
  login: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    background: "rgb(1,157,177)",
    background: "linear-gradient(0deg, rgba(1,157,177,1) 0%, rgba(164,199,75,1) 100%)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  inputUser: {
    margin: "1rem",
    maxWidth: "25rem",
    width: "100%",
  },
  inputPassword: {
    margin: "1rem",
    maxWidth: "25rem",
    width: "100%",
  },
  error: {
    color: "red",
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginTop: "0.5rem",
  },
  paper: {
    width: "100%",
    maxWidth: "28rem",
    padding: "4rem",
    maxHeight: "45rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonLogin: {
    width: "100%",
    maxWidth: "20rem",
    marginTop: "1rem",
    background: "#A4C74B",
    boxShadow: "none",
    borderRadius: "2rem",
    color: "#ffffff",
    fontWeight: "bolder",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    "&:hover": {
      boxShadow: "none",
      background: "#A4C74B",
    },
  },
  divider: {
    background: "red",
    maxWidth: "20rem",
    width: "100%",
    margin: "1rem",
  },
  buttonGoogle: {
    width: "100%",
    maxWidth: "20rem",
    marginTop: "1rem",
    boxShadow: "none",
    background: "#019DB1",
    borderRadius: "2rem",
    display: "flex",
    justifyContent: "center",
    color: "#ffffff",
    fontWeight: "bolder",
    textTransform: "uppercase",
    letterSpacing: "0.75px",
    "&:hover": {
      boxShadow: "none",
      background: "#019DB1",
    },
  },
  contentImg: {},
  img: {
    width: "15rem",
  },
  h6: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1.4rem",
    marginBottom: "0.5rem",
  },
  p: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1.1rem",
    marginBottom: "2rem",
    letterSpacing: "0.15px",
    fontWeight: "600",
  },
  captchaError: {
    fontSize: "12px",
    color: "red",
    fontStyle: "italic",
  },
}))

const clientId = window.processEnv.REACT_APP_CLIENTE_ID

const Login = () => {
  const classes = useStyles()
  const { setAuth } = useContext(AuthContext)
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [empatyInput, setEmpatyInput] = useState(false)

  const [errorGoogle, setErrorGoogle] = useState(false)

  const [validCaptcha, setValidCaptcha] = useState(null)

  const captcha = useRef(null)

  const handleChangeUserName = (e) => {
    const name = e.target.value
    setUserName(name)
    setEmpatyInput(false)
  }

  const handleChangePassword = (e) => {
    const password = e.target.value
    setPassword(password)
    setEmpatyInput(false)
  }

  const fetchLogin = async (user, password, mode) => {
    setIsLoading(true)
    try {
      const resp = await loginUser(user, password, mode)
      setAuth(resp)
    } catch (e) {
      console.log(e)
      setLoginError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()
    if (userName && password && validCaptcha) {
      fetchLogin(userName, password, "default")
      setEmpatyInput(false)
    } else if (!userName || !password) {
      setEmpatyInput(true)
    } else if (!validCaptcha) {
      setValidCaptcha(false)
    }
  }

  const handleLoginSuccess = (response) => {
    const tokenID = response.tokenId
    const email = response.profileObj.email
    fetchLogin(tokenID, email, "google")
  }

  const handleLoginFailure = (error) => {
    setErrorGoogle(true)
  }

  const onChangeCaptcha = (value) => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true)
    }
  }

  return (
    <div className={classes.login}>
      <Paper className={classes.paper}>
        <div className={classes.contentImg}>
          <img src="/logo-redsalud.png" alt="logo" className={classes.img} />
        </div>
        <div>
          <h6 className={classes.h6}>Hola!</h6>
          <p className={classes.p}>Iniciá sesión con tus datos para accedera tu sistema :)</p>
        </div>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleLogin}>
          <FormControl className={classes.inputUser} variant="outlined">
            <InputLabel htmlFor="outlined-basic" style={{ color: (loginError || empatyInput) && "red" }}>
              Correo electrónico o usuario
            </InputLabel>
            <OutlinedInput
              error={loginError || empatyInput}
              id="outlined-basic"
              type="text"
              value={userName}
              onChange={handleChangeUserName}
              required
              labelWidth={190}
            />
          </FormControl>
          <FormControl className={classes.inputPassword} variant="outlined">
            <InputLabel htmlFor="input-password" style={{ color: (loginError || empatyInput) && "red" }}>
              Contraseña
            </InputLabel>
            <OutlinedInput
              error={loginError || empatyInput}
              id="input-password"
              type={showPassword ? "text" : "password"}
              value={password}
              autoComplete="current-password"
              onChange={handleChangePassword}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={75}
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ReCAPTCHA
              ref={captcha}
              sitekey={window.processEnv.REACT_APP_CAPTCHA_KEY}
              onChange={onChangeCaptcha}
              style={{
                transform: "scale(0.77)",
                WebkitTransform: "scale(0.77)",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          {validCaptcha === false && (
            <Typography className={classes.captchaError}>Por favor acepta el captcha</Typography>
          )}
          {loginError && <p className={classes.error}>El usuario o la contraseña son incorrectas.</p>}
          {empatyInput && <p className={classes.error}>El usuario y la contraseña son requeridos.</p>}
          <Button type="submit" variant="contained" className={classes.buttonLogin}>
            INICIAR SESIÓN
          </Button>
        </form>
        <GoogleLogin
          clientId={clientId}
          buttonText="INICIAR con google"
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFailure}
          isSignedIn={true}
          render={(renderProps) => (
            <Button
              className={classes.buttonGoogle}
              onClick={() => {
                if (validCaptcha) renderProps.onClick()
                else setValidCaptcha(false)
              }}
              disabled={renderProps.disabled || validCaptcha === false}
            >
              INICIAR con google
            </Button>
          )}
        />
        {errorGoogle && <p className={classes.error}>Ocurrio un error al intentar hacer login con Google.</p>}
      </Paper>
    </div>
  )
}

export default Login
