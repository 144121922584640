import API from "../../utils/api"

const getAllBedsCovid19 = () => {
  const path = `/sheets/covid19`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllBedsGesCaec = () => {
  const path = `/sheets/ges-caec`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllBedsGrdCosalud = () => {
  const path = `/sheets/grd-consalud`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllBedsGrdFonasa = () => {
  const path = `/sheets/grd-fonasa`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editBedCovid19ByAdmissionCode = (admissionCode, data) => {
  const path = `/sheets/covid19/${admissionCode}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editBedGesCaecByAdmissionCode = (admissionCode, data) => {
  const path = `/sheets/ges-caec/${admissionCode}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editBedGrdConsaludByAdmissionCode = (admissionCode, data) => {
  const path = `/sheets/grd-consalud/${admissionCode}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editBedGrdFonasaByAdmissionCode = (admissionCode, data) => {
  const path = `/sheets/grd-fonasa/${admissionCode}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteBedCovid19 = (admissionCode, bedCode) => {
  const path = `/sheets/covid19/${admissionCode}?bedCode=${bedCode}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteBedGesCaec = (admissionCode, bedCode) => {
  const path = `/sheets/ges-caec/${admissionCode}?bedCode=${bedCode}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteBedGrdConsalud = (admissionCode, bedCode) => {
  const path = `/sheets/grd-consalud/${admissionCode}?bedCode=${bedCode}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteBedGrdFonasa = (admissionCode, bedCode) => {
  const path = `/sheets/grd-fonasa/${admissionCode}?bedCode=${bedCode}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createBedCovid19 = (data) => {
  const path = `/sheets/covid19`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createBedGesCaec = (data) => {
  const path = `/sheets/ges-caec`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createBedGrdSalud = (data) => {
  const path = `/sheets/grd-consalud`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createBedGrdFonasa = (data) => {
  const path = `/sheets/grd-fonasa`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllPatientes = () => {
  const path = `/beds/patients`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export {
  getAllBedsCovid19,
  getAllBedsGesCaec,
  getAllBedsGrdCosalud,
  getAllBedsGrdFonasa,
  editBedCovid19ByAdmissionCode,
  editBedGesCaecByAdmissionCode,
  editBedGrdConsaludByAdmissionCode,
  editBedGrdFonasaByAdmissionCode,
  deleteBedCovid19,
  deleteBedGesCaec,
  deleteBedGrdConsalud,
  deleteBedGrdFonasa,
  createBedCovid19,
  createBedGesCaec,
  createBedGrdSalud,
  createBedGrdFonasa,
  getAllPatientes,
}
