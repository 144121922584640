import * as React from "react"

import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  spinner: {
    border: "4px solid #009DB1",
    borderLeftColor: "transparent",
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    animation: "$spin 1s ease infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}))

export default function LoadingComponent() {
  const classes = useStyles()
  return <div className={classes.spinner}></div>
}
