import React, { useState } from "react"
// EXTERNAL COMPONENTS
import { makeStyles, Input } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const EditBedInput = ({ bedCode, setSingleBedCode, setEditSingleBed }) => {
  const classes = useStyles()
  const [value, setValue] = useState(bedCode)

  const handleChangeEditBed = ({ target }) => {
    const { value } = target
    setSingleBedCode(value.toUpperCase())
    setValue(value.toUpperCase())
  }

  return (
    <>
      <Input
        className={classes.addBedInput}
        placeholder={!value ? "CÓDIGO DE CAMA" : value}
        inputProps={{ "aria-label": "description" }}
        disableUnderline={true}
        onChange={(e) => handleChangeEditBed(e)}
        autoFocus
        value={value}
      />
      <CloseIcon onClick={() => setEditSingleBed({ index: null, state: false })} />
    </>
  )
}

export default EditBedInput
