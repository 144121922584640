export const architechtureStyles = (theme) => ({
  header: {
    padding: "0rem 0 2rem 0",
    marginTop: "1.3rem",
  },
  title: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32.2px",
    lineHeight: "44px",
    display: "flex",
    alignItems: "center",
    color: "#566573",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
  contentAdd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  iconAdd: {
    backgroundColor: "#009DB1",
    marginRight: "2rem",
    marginBottom: "0.5rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
  },
  iconAdd2: {
    backgroundColor: "#009DB1",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
    fontSize: "14px",
    width: "13.5rem",
    marginBottom: "1.875rem",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
  },
  unitContainer: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #BDBDBD",
    borderBottom: "1px solid #BDBDBD",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#DADADA",
    },
  },
  unitContainer2: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #BDBDBD",
    borderBottom: "1px solid #BDBDBD",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
  },
  unitContainerError: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid red",
    borderBottom: "1px solid red",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#DADADA",
    },
  },
  unitContainer3: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #BDBDBD",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
    // flexDirection: "column",
    "&:hover": {
      backgroundColor: "#DADADA",
    },
  },
  unitContainer4: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #BDBDBD",
    borderBottom: "1px solid #BDBDBD",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#DADADA",
    },
  },
  unitContainerEdit: {
    backgroundColor: "#F5F5F5",
    width: "calc(100% + 4rem)",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    margin: "0 0 0 -2rem",
    justifyContent: "space-between",
  },
  areaContainer: {
    backgroundColor: "#F5F5F5",
    width: "186px",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    justifyContent: "space-between",
    marginTop: "2.313rem",
  },
  areaContainerError2: {
    backgroundColor: "#F5F5F5",
    width: "186px",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
    borderRadius: "4px",
    justifyContent: "space-between",
    marginTop: "2.313rem",
  },

  unitName: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "33px",
    textTransform: "uppercase",
    color: "#566573",
    padding: "2rem",
  },
  unitEditName: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    width: "100%",
    lineHeight: "33px",
    textTransform: "uppercase",
    color: "#566573",
    padding: "2rem",
    display: "flex",
  },
  unitEditName2: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    width: "100%",
    lineHeight: "33px",
    color: "#566573",
    padding: "2rem",
    display: "flex",
  },
  unitEditUrl: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    width: "100%",
    lineHeight: "33px",
    color: "#566573",
    padding: "2rem",
    display: "flex",
  },
  editUnitP: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#566573",
  },
  editUnitIcon: {
    marginRight: ".625rem",
    color: "#566573",
  },
  editContainer: {
    display: "flex",
    marginRight: "2.75rem",
    width: "20.438rem",
  },
  editContainer1: {
    display: "flex",
    width: "20.438rem",
    width: "40%",
    justifyContent: "flex-end",
  },
  editContainer2: {
    display: "flex",
    width: "20.438rem",
    width: "33%",
    justifyContent: "flex-end",
  },
  editContainer4: {
    display: "flex",
    width: "20.438rem",
    width: "40%",
    justifyContent: "center",
  },
  edit2Container: {
    display: "flex",
    marginRight: "2.75rem",
    marginTop: "2.313rem",
  },
  editContainer3: {
    display: "flex",
    width: "33rem",
  },
  tabs: {
    marginTop: "2rem",
    width: "80vw",
  },
  tab: {
    // width: "170px",
    color: "#000000",
    letterSpacing: "0.75px",
    fontWeight: "bold",
    alignSelf: "flex-end",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  card: {
    width: "100%",
    cursor: "pointer",
    maxWidth: "32.5rem",
    minWidth: "23rem",
    margin: "1rem 3.125rem 3.125rem 2.813rem",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
    border: "1px solid #BDBDBD",
    borderRadius: "4px",
  },
  contentCode: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    height: "3.438rem",
    borderRadius: "4px 4px 0 0",
  },
  contentCode2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADADA",
    height: "3.438rem",
    borderRadius: "4px 4px 0 0",
  },
  contentCode3: {
    border: "1px solid #009DB1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    height: "3.438rem",
    borderRadius: "4px 4px 0 0",
  },
  floorError: {
    border: "1px solid red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    height: "3.438rem",
    borderRadius: "4px 4px 0 0",
  },
  textData: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#535461",
    // width: "60%",
  },
  textData1: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#535461",
  },
  textData2: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#535461",
    marginLeft: "6rem",
  },
  contentBedsGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  services: {
    margin: "2.188rem 2.5rem 0 4rem",
  },
  addServiceContent: {
    margin: "2.188rem 2.5rem 0 4rem",
    height: "8.125rem",
  },
  addServiceContent2: {
    margin: "2.188rem 2.5rem 0rem 2.5rem",
    height: "4.125rem",
  },
  addServiceContent3: {
    margin: "2.188rem 2.5rem 0rem 0.5rem",
    height: "4.125rem",
  },
  serviceItem: {
    "&::marker": {
      color: "#008000",
    },
    width: "80%",
  },
  serviceItemDivHover: {
    backgroundColor: "#DADADA",
    display: "flex",
  },
  singleUnitHover: {
    backgroundColor: "#DADADA",
    display: "flex",
    width: "100%",
  },
  serviceItemDiv: {
    display: "flex",
    width: "100%",
  },
  addServiceContainer3: {
    "&::marker": {
      color: "#008000",
    },
  },
  serviceSpan: {
    color: "#535461",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
  },
  serviceSpan2: {
    color: "#000000",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
  },
  addServiceContainer: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    textTransform: "uppercase",
    color: "#535461",
    marginBottom: "2.5rem",
    marginTop: "2.5rem",
  },
  addServiceContainer2: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    textTransform: "uppercase",
    color: "#000000",
    marginBottom: "2.5rem",
    marginTop: "2.5rem",
  },
  addServiceSpan: {
    marginLeft: "1rem",
    fontSize: "20px",
    lineHeight: "27px",
  },
  textDataSpan: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#535461",
    marginLeft: "1rem",
  },
  addUnitForm: {
    marginLeft: "2.25rem",
    width: "100%",
  },
  addUnitForm2: {
    width: "100%",
  },
  addAreaForm: {
    width: "100%",
  },
  addUnitInput: {
    color: "#BDBDBD",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "33px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    "&.Mui-focused": {
      color: "#566573",
    },
  },
  addAreaInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "153px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  addAreaInput4: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "153px",
    marginLeft: "1rem",
  },
  addAreaInput3: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "153px",
    marginLeft: "0",
    marginRight: "auto",
  },

  addServiceInput2: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12.5px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "153px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  checkBoxBed: {
    marginRight: "-2rem",
  },
  checkBoxBed1: {
    marginRight: "-1rem",
  },
  addAreaInput2: {
    fontFamily: "Open Sans",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "24px",
    textAlignLast: "center",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "153px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  addAreaInput3: {
    fontFamily: "Open Sans",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "24px",
    textAlignLast: "center",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },

  addServiceInput: {
    width: "25rem",
    height: "2.5rem",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    alignItems: "center",
    color: "#BDBDBD",
    paddingLeft: "1rem",
    "&.Mui-focused": {
      color: "#566573",
    },
  },
  serviceError: {
    width: "25rem",
    height: "2.5rem",
    border: "1px solid red",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    alignItems: "center",
    color: "#BDBDBD",
    paddingLeft: "1rem",
    "&.Mui-focused": {
      color: "#566573",
    },
  },
  closeIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "12rem",
    justifyContent: "center",
    cursor: "pointer",
  },
  closeIconContainer1: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    cursor: "pointer",
  },
  closeIconContainer3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  closeIconContainer4: {
    display: "flex",
    alignItems: "center",
    marginRight: "2.3rem",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  saveIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    cursor: "pointer",
  },
  saveIconContainer2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2rem",
    cursor: "pointer",
  },
  saveIconContainer3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
    marginTop: "1.563rem",
    marginBottom: "2rem",
  },
  saveIconContainer4: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "1rem",
    flexDirection: "column",
  },
  saveIconContainer5: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
  },
  saveIconContainer6: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  saveIconContainer7: {
    width: "12rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  saveIconContainer8: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
    cursor: "pointer",
  },
  buildingIcon: {
    width: "5.833rem",
    height: "6.667rem",
    backgroundColor: "#566573",
  },
  svgContainer: {
    width: "12.5rem",
    height: "12.5rem",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "4.375rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "-2.5rem",
      height: "auto",
      width: "auto",
    },
  },
  textAndButtonContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "33.125rem",
    height: "2.375rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3.6rem",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "11.6rem",
    // },
  },
  textP: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#566573",
  },
  textP2: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#566573",
  },
  addAreaButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      marginTop: "0rem",
    },
    marginTop: "2rem",
  },
  addUnitIcon: {
    backgroundColor: "#009DB1",

    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
  },
  addUnitLink: {
    textDecoration: "none",
    width: "100%",
  },
  addUnitLink1: {
    textDecoration: "none",
  },
  linkContainer: {
    width: "90%",
  },
  areaAndEditContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  areaContainer2: {
    backgroundColor: "#F5F5F5",
    width: "186px",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  serviceError2: {
    backgroundColor: "#F5F5F5",
    width: "186px",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  areaContainerError: {
    backgroundColor: "#F5F5F5",
    width: "186px",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  areaContainer3: {
    backgroundColor: "#F5F5F5",
    width: "100%",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  areaContainer4: {
    backgroundColor: "#F5F5F5",
    width: "15.625rem",
    height: "1.938rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  areaContainer5: {
    backgroundColor: "#F5F5F5",
    width: "26.25rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009DB1",
    borderRadius: "4px",
    justifyContent: "space-between",
  },
  editContainerV2: {
    display: "flex",
    marginRight: "2.75rem",
  },
  areaAndEditContainer2: {
    display: "flex",
    width: "100%",
  },
  serviceAndEditContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  noServiceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  noServiceP: {
    marginBottom: "0.926rem",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#535461",
  },
  newFloorInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#BDBDBD",
    "&.Mui-focused": {
      color: "#566573",
    },
  },
  formControlLabel: {
    color: "#566573",
  },
  none: {
    display: "none",
  },
  svgMainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "row",
    // },
  },
  closeIconContainer2: {
    alignSelf: "center",
    marginTop: ".5rem",
    marginLeft: ".5rem",
    cursor: "pointer",
  },
  serviceContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkBoxContainer: {
    marginTop: "1rem",
  },
  roomCard: {
    width: "100%",
    padding: "1.3rem",
    maxWidth: "23rem",
    minWidth: "23rem",
    marginBottom: "2.5rem",
    marginRight: "2rem",
    height: "13rem",
    marginLeft: "2.3rem",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  roomCardContentCode: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "2rem",
  },
  roomCardTextData: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  roomCardDivider: {
    margin: "0.7rem 0",
  },
  roomCardContentText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "2rem 0 8px 0",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    textTransform: "uppercase",
    color: "#6E6E6E",
  },
  roomCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "3.75rem",
  },
  addBedContentText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    textTransform: "uppercase",
    color: "#6E6E6E",
  },
  iconsMainContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "20%",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  liContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "3rem",
  },
  iconMainContainer2: {
    display: "flex",
    justifyContent: "flex-end",
    height: "30%",
  },
  iconMainContainer3: {
    display: "flex",
    // justifyContent: "flex-end",
    width: "10%",
  },
  iconMainContainer4: {
    display: "flex",
    justifyContent: "flex-end",
    width: "40%",
    padding: "2.2rem",
  },
  iconContainer2: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  moveTitleContainer: {
    backgroundColor: "#F5F5F5",
    width: "100%",
    marginTop: "4.375rem",
    borderRadius: "4px",
    border: "1px solid #BDBDBD",
    height: "3.75rem",
    display: "flex",
    alignItems: "center",
    padding: "0.625rem",
  },
  moveTitleText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#566573",
  },
  moveContentContainer: {
    marginTop: "3.75rem",
    display: "flex",
  },
  moveContentFirstDiv: {
    width: "50%",
    display: "flex",
    marginLeft: "1rem",
    flexDirection: "column",
  },
  selectedBeds: {
    width: "33rem",
    minHeight: "15.875rem",
    border: "1px solid #BDBDBD",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  moveContentSecondDiv: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    minHeight: "31.25rem",
  },
  wrongBed: {
    width: "29.375rem",
    textAlign: "center",
    marginTop: "1.125rem",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#566573",
  },
  wrongBedSpan: {
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: ".5rem",
  },
  selectedBedsP: {
    marginTop: "4.375rem",
    marginLeft: "1rem",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#000000",
  },
  selectedBedsDiv: {
    display: "flex",
    marginTop: "3.75rem",
    width: "28.125rem",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: "3.75rem",
  },
  selectedBedNumber: {
    marginLeft: "1rem",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#000000",
  },
  moveContentSingleDiv: {
    minHeight: "6.25rem",
    width: "100%",
    borderBottom: "1px solid #DADADA",
    display: "flex",
  },
  moveContentSingleDivArea: {
    minHeight: "6.25rem",
    width: "100%",
    borderBottom: "1px solid #DADADA",
    display: "flex",
    paddingTop: "1.875rem",
    paddingBottom: "1.875rem",
  },
  lastDiv: {
    minHeight: "6.25rem",
    width: "100%",
    display: "flex",
  },
  firstColumn: {
    width: "30%",
    display: "flex",
    alignItems: "center",
  },
  firstColumnP: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
  },
  firstColumnP2: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#566573",
  },
  secondColumn: {
    width: "70%",
    display: "flex",
    alignItems: "center",
  },
  moveFormControlLabel: {
    color: "#566573",
  },
  formControlMove: {
    width: "90%",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: "2rem",
    cursor: "pointer",
  },
  iconContainer3: {
    width: "15%",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  iconContainer4: {
    width: "10%",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  unitIconContainer: {
    display: "flex",
    width: "100%",
  },
  addBedInput: {
    width: "18.625rem",
    borderRadius: "4px",
    border: "1px solid #009DB1",
    paddingLeft: "1rem",
  },
  bedError: {
    width: "18.625rem",
    borderRadius: "4px",
    border: "1px solid red",
    paddingLeft: "1rem",
  },
  subserviceCreateContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "2.5rem",
    justifyContent: "space-between",
  },
  subServiceContainer2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subServiceInputContainer: {
    width: "35rem",
    border: "1px solid #009DB1",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
  },
  subserviceError: {
    width: "35rem",
    border: "1px solid red",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
  },
  addSubserviceInput: {
    color: "#BDBDBD",
    width: "100%",
    fontSize: "20px",
    fontStyle: "normal",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "normal",
    lineHeight: "27px",
    marginLeft: "1rem",
    "&.Mui-focused": {
      color: "#566573",
    },
  },
  editContainerV2: {
    display: "flex",
    marginRight: "2.75rem",
  },
  small: {
    color: "#566573",
    cursor: "pointer",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: "600",
    lineHeight: "19px",
    marginBottom: "3px",
    textDecoration: "underline",
  },
  small1: {
    color: "#566573",
    cursor: "pointer",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: "600",
    lineHeight: "19px",
    marginBottom: "3px",
    marginTop: "2.25rem",
    textAlign: "center",
  },
  small2: {
    color: "#566573",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: "600",
    lineHeight: "19px",
    marginBottom: "3px",
    textDecoration: "underline",
  },
  subServiceContainerChild: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  subServiceNameContainer: {
    display: "flex",
    alignItems: "center",
    width: "25%",
  },
  subServiceNameContainer2: {
    display: "flex",
    alignItems: "center",
    width: "60%",
  },
  subServiceNameContainerHover: {
    display: "flex",
    alignItems: "center",
    width: "25%",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
  },
  subserviceP: {
    color: "#566573",
    fontSize: "20px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: "normal",
    lineHeight: "27px",
  },
  bedCodeError: {
    color: "#F56E69",
  },
  updatedBed: {
    color: "#008000",
  },
  selectAllBedsContainer: {
    backgroundColor: "#F5F5F5",
    width: "136ch",
    marginTop: "4.375rem",
    borderRadius: "4px",
    border: "1px solid #BDBDBD",
    height: "3.75rem",
    display: "flex",
    alignItems: "center",
    padding: "0.625rem",
    marginLeft: "2.3rem",
    justifyContent: "space-between",
  },
  selectAllFormControlLabel: {
    color: "#566573",
    display: "flex",
    flexDirection: "row-reverse",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "-0.01em",
  },
  // DELETE MODAL
  deleteModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30rem",
    height: "26.25rem",
    backgroundColor: "#FFFFFF",
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperChild: {
    width: "25rem",
    height: "23rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperChild1: {
    width: "25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  deleteModalTitleP: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "30px",
    marginTop: "1rem",
    color: "#566573",
    textTransform: "capitalize",
  },
  deleteText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    color: "#566573",
    marginTop: "2rem",
    textAlign: "center",
  },
  deleteText1: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    color: "#566573",
    marginTop: "4rem",
    textAlign: "center",
  },
  deleteTextSpan: {
    color: "black",
    fontWeight: "600",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "18px",
    textTransform: "uppercase",
  },
  deleteTextSpan1: {
    color: "black",
    fontWeight: "600",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "17px",
    textTransform: "uppercase",
  },
  deleteButtonContainer: {
    display: "flex",
    marginTop: "5rem",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButtonContainer1: {
    display: "flex",
    marginTop: "4rem",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    backgroundColor: "#009DB1",
    marginBottom: "0.5rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
    textTransform: "uppercase",
  },
  noDeleteButton: {
    marginBottom: "0.5rem",
    color: "#566573",
    backgroundColor: "#FFFFFF",
    marginRight: "2rem",
  },
  pointer: {
    cursor: "pointer",
  },
  servicesHoverContainer: {
    display: "flex",
    flexDirection: "column",
    height: "5.5rem",
  },
})
