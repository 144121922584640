import API from "../../utils/api"

const loginUser = (username, password, mode) => {
  const path = "/login"
  const data = mode === "default" ? { username, password } : { googleToken: username, email: password }
  return API.post(path, data).then((response) => {
    if (response.data.accessToken) {
      const updateUser = { ...response.data, accessToken: `0000000000 ${response.data.accessToken}` }
      localStorage.setItem("user", JSON.stringify(updateUser))
    }
    return response.data
  })
}

const logoutUser = () => {
  localStorage.clear()
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

export { loginUser, logoutUser, getCurrentUser }
