import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"

// INTERNAL COMPONENTS
import InfraestructureHeader from "../InfraestructureHeader"
import {
  getUnit,
  getAreaName,
  getFloorName,
  getAllServices,
  getSubServices,
  createSingleSubService,
  createSingleService,
  getAllBeds,
  //AGREGADO DAVID
  editService,
} from "../../services/architechture"
import { architechtureStyles } from "../../utils/architechtureStyles"
import { a11yProps } from "../../views/Sheets"
import { TabPanel } from "../../views/Sheets"
import BedStateCard from "../BedStateCard"
import EditSubserviceInput from "../EditSubserviceInput"
import { svg } from "../../utils/svgHelper"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { GreenCheckbox } from "../../views/Architecture"
import DeleteSubserviceModal from "../DeleteSubserviceModal"
import DeleteServiceModal from "../DeleteServiceModal"
import { editSubService } from "../../services/architechture"
import PopUp from "../../components/PopUp"
import EditServiceInput from "../EditServiceInput"

// EXTERNAL COMPONENTS
import { makeStyles, Tabs, Tab, Button, Input, FormControlLabel } from "@material-ui/core"
import OpenWithIcon from "@material-ui/icons/OpenWith"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const Services = () => {
  const classes = useStyles()
  const params = useParams()
  const [unit, setUnit] = useState("")
  const [area, setArea] = useState("")
  const [floor, setFloor] = useState("")
  const [services, setServices] = useState([])
  const [tab, setTab] = useState(0)
  const [serviceId, setServiceId] = useState(null)
  const [subservices, setSubservices] = useState([])
  const [addSubService, setAddSubService] = useState(false)
  const [subserviceName, setSubserviceName] = useState("")
  const [addBed, setAddBed] = useState({ index: null, state: false })
  const [moveBed, setMoveBed] = useState({ index: null, state: false })
  const [createNewService, setCreateNewService] = useState(false)
  const [serviceName, setServiceName] = useState("")
  const [checked, setChecked] = useState([])
  const [hover, setHover] = useState({ index: null, state: false })
  const [hoverService, setHoverService] = useState({ index: null, state: false })
  const [editSingleSub, setEditSingleSub] = useState({ index: null, state: false })
  const [openDeleteSubserviceModal, setOpenDeleteSubserviceModal] = useState(false)
  const [subserviceInfo, setSubserviceInfo] = useState({})
  const [valueEditSub, setValueEditSub] = useState("")
  const [subServiceEdit, setSubServiceEdit] = useState(true)
  const [allSelected, setAllSelected] = useState(false)
  const [editSingleService, setEditSingleService] = useState({ index: null, state: false, id: null })
  const [valueEditService, setValueEditService] = useState("")
  // const [beds, setBeds] = useState([])
  const [value, setValue] = useState(0)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [openPopUpSubservice, setOpenPopUpSubservice] = useState(false)
  const [openPopUpBed, setOpenPopUpBed] = useState(false)
  const [openPopUpEditBed, setOpenPopUpEditBed] = useState(false)
  const [openPopUpEditSub, setOpenPopUpEditSub] = useState(false)
  const [allBeds, setAllBeds] = useState([])
  const [openPopUpEditService, setOpenPopUpEditService] = useState(false)
  const [openPopUpDeleteService, setOpenPopUpDeleteService] = useState(false)
  const [openPopUpDeleteSubservice, setOpenPopUpDeleteSubservice] = useState(false)
  const [openPopUpDeleteBed, setOpenPopUpDeleteBed] = useState(false)

  const [openPopUpSubError, setOpenPopUpSubError] = useState(false)
  const [openPopUpBedError, setOpenPopUpBedError] = useState(false)
  const [openPopUpServError, setOpenPopUpServError] = useState(false)
  const [editSingleBed, setEditSingleBed] = useState({ index: null, state: false })

  const [openServiceModal, setOpenServiceModal] = useState(false)
  const [serviceModalId, setServiceModalId] = useState(null)
  const [serviceModalName, setServiceModalName] = useState(null)

  const onCloseServiceModal = () => {
    setOpenServiceModal(false)
  }

  const {
    setMoveSelectedBeds,
    setContextSubServiceName,
    detailsUnit,
    onOpenPopUpMoveBed,
    onClosePopUpMoveBed,
    openPopUpMoveBed,
  } = useContext(DashboardUnit)

  const getBeds = async (subServiceId) => {
    if (subServiceId && params.areaId && params.unit && params.floorId && serviceId) {
      const resp = await getAllBeds(
        Number(params.unit),
        Number(params.areaId),
        Number(params.floorId),
        Number(serviceId),
        Number(subServiceId),
      )

      setAllBeds(resp)
    }
  }

  const handleChangeAllSelected = (e, x) => {
    getBeds(x)
    setAllSelected(e.target.checked)
  }

  // ERROR IN SERVICE CREATION
  const [serviceError, setServiceError] = useState(false)

  // ERROR IN SUBSERVICE CREATION
  const [subserviceError, setSubserviceError] = useState(false)

  const onCloseDeleteSubserviceModal = () => {
    setOpenDeleteSubserviceModal(false)
  }

  const getDetails = async () => {
    const unit = await getUnit(params.unit)
    setUnit(unit.name)

    const area = await getAreaName(params.areaId)
    setArea(area.name)

    const floor = await getFloorName(params.floorId)
    setFloor(floor.name)

    const services = await getAllServices(params.unit, params.areaId, params.floorId)
    setServices(services)
    setServiceId(services[0]?.serviceId)
  }

  const getAllSubServices = async () => {
    if (serviceId) {
      const resp = await getSubServices(
        Number(params.unit),
        Number(params.areaId),
        Number(params.floorId),
        Number(serviceId),
      )
      setSubservices(resp)
    }
  }
  //FUNCION QUE ABRE O CIERRA LOS POPUPS
  const onActivePopUp = (fn, boolean) => {
    fn(boolean)
  }

  const handleChangeCreateService = ({ target }) => {
    setServiceError(false)
    const { value } = target
    setServiceName(value)
  }

  const handleSubserviceChange = ({ target }) => {
    setSubserviceError(false)
    const { value } = target
    setSubserviceName(value)
  }

  const handleChangeTab = (e, newValue) => {
    setTab(newValue)
    setMoveBed({ index: null, state: false })
  }
  const handleClickTab = (x) => {
    setServiceId(x)
  }

  const handleClickDischargeSubservice = () => {
    setAddSubService(false)
    setSubserviceName("")
    setSubserviceError(false)
  }

  const handleSubmitSubservice = async () => {
    try {
      if (!subserviceName) setSubserviceError(true)
      if (subserviceName) {
        const resp = await createSingleSubService({
          name: subserviceName,
          unitId: params.unit,
          areaId: params.areaId,
          floorId: params.floorId,
          serviceId,
          autoCreate: true,
        })
        setSubserviceError(false)
        if (resp.data) {
          onActivePopUp(setOpenPopUpSubError, true)
        } else {
          if (serviceId) {
            const resp = await getSubServices(
              Number(params.unit),
              Number(params.areaId),
              Number(params.floorId),
              Number(serviceId),
            )
            setSubservices(resp)
            setAddSubService(false)
            setSubserviceName("")
            onActivePopUp(setOpenPopUpSubservice, true)
          }
        }
      }
    } catch (e) {
      console.log("soy el error", e)
    }
  }

  const handleEditSubservice = async (subServiceId, valueEditSub) => {
    try {
      if (subServiceId && valueEditSub) {
        const resp = await editSubService(subServiceId, { name: valueEditSub })
        setEditSingleSub({ index: null, state: false })
        setSubServiceEdit(!subServiceEdit)
        onActivePopUp(setOpenPopUpEditSub, true)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getDetails()
  }, [params, subServiceEdit])

  useEffect(() => {
    getAllSubServices()
  }, [serviceId, subServiceEdit])

  const handleClickDischargeChanges = () => {
    setServiceName("")
    setCreateNewService(false)
    setTab(0)
    setServiceError(false)
  }

  const handleClickMoveAllBeds = () => {
    setMoveSelectedBeds(allBeds)
  }

  const handleClickMoveBeds = (x) => {
    setContextSubServiceName(x.name)
    setMoveSelectedBeds(checked)
  }

  const handleSubmitService = async () => {
    if (!serviceName) setServiceError(true)
    if (serviceName) {
      const resp = await getFloorName(params.floorId)
      setServiceError(false)
      const singleService = await createSingleService({
        name: serviceName,
        unitId: Number(params.unit),
        areaId: Number(params.areaId),
        floorId: Number(params.floorId),
        urgencyService: resp.urgencyFloor,
        autoCreate: true,
      })

      if (singleService.data) {
        onActivePopUp(setOpenPopUpServError, true)
      } else {
        const newServices = await getAllServices(params.unit, params.areaId, params.floorId)
        setServices(newServices)
        setServiceName("")
        setCreateNewService(false)
        onActivePopUp(setOpenPopUp, true)
      }
    }
  }
  //AGREGADO SERVICES DAVID

  const handleEditService = (index, state, id) => {
    setEditSingleService({ index, state, id })
    handleClickTab(id)
  }

  const handleSubmitEditService = async () => {
    try {
      const respServiceEdit = await editService(editSingleService.id, { name: valueEditService, urgencyService: 1 })
      setEditSingleService({ ...editSingleService, state: false })
      getDetails()
      setValue(0)
      onActivePopUp(setOpenPopUpEditService, true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <InfraestructureHeader unit={unit} floor={floor} area={area} />
      <Tabs
        value={value}
        onChange={handleChangeTab}
        indicatorColor={editSingleService.state || createNewService ? "red" : "secondary"}
        className={classes.tabs}
      >
        {services?.map((x, i) => {
          return (
            <div
              onMouseEnter={() =>
                !editSingleSub.state &&
                !editSingleBed.state &&
                !addSubService &&
                !createNewService &&
                setHoverService({ index: i, state: true })
              }
              onMouseLeave={() =>
                !editSingleSub.state &&
                !editSingleBed.state &&
                !addSubService &&
                !createNewService &&
                setHoverService({ index: i, state: false })
              }
              onClick={() =>
                !editSingleSub.state && !editSingleBed.state && !addSubService && !createNewService && setValue(i)
              }
              style={{ height: "5.5rem" }}
            >
              {!editSingleService.state ? (
                <div className={classes.iconMainContainer2}>
                  {hoverService.index === i && hoverService.state ? (
                    <div className={classes.iconContainer}>
                      <EditIcon onClick={() => handleEditService(i, true, x.serviceId)} className={classes.pointer} />
                      <DeleteIcon
                        onClick={() => {
                          setServiceModalName(x.name)
                          setServiceModalId(x.serviceId)
                          setOpenServiceModal(true)
                        }}
                        className={classes.pointer}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              {editSingleService.state && editSingleService.index === i ? (
                <EditServiceInput serviceName={x.name} setValueEditService={setValueEditService} />
              ) : (
                <Tab
                  label={x.name}
                  {...a11yProps(i)}
                  className={classes.tab}
                  disabled={
                    editSingleService.state ||
                    editSingleSub.state ||
                    editSingleBed.state ||
                    addSubService ||
                    createNewService
                  }
                  onClick={() => handleClickTab(x.serviceId)}
                />
              )}
            </div>
          )
        })}

        {services.length && !editSingleService.state ? (
          <Tab
            label="+ AGREGAR SERVICIO"
            {...a11yProps(2)}
            className={classes.tab}
            disabled={editSingleSub.state || editSingleBed.state || addSubService}
            onClick={() => setCreateNewService(true)}
            style={{ display: createNewService && services.length ? "none" : null }}
          />
        ) : (
          <div className={classes.editContainerV2}>
            <div
              className={classes.closeIconContainer}
              onClick={() => {
                setEditSingleService({ index: null, state: false, id: null })
              }}
            >
              <CloseIcon className={classes.editUnitIcon} />
              <p className={classes.editUnitP}>Descartar Cambios</p>
            </div>
            <div className={classes.saveIconContainer6} onClick={() => handleSubmitEditService()}>
              <SaveIcon className={classes.editUnitIcon} />
              <p className={classes.editUnitP}>Guardar Cambios</p>
            </div>
          </div>
        )}

        {createNewService && services.length ? (
          <>
            <div className={classes.serviceAndEditContainer}>
              <div className={serviceError ? classes.serviceError2 : classes.areaContainer2}>
                <Input
                  className={classes.addServiceInput2}
                  placeholder="NOMBRE DEL SERVICIO"
                  inputProps={{ "aria-label": "description" }}
                  disableUnderline={true}
                  onChange={(e) => handleChangeCreateService(e)}
                  autoFocus
                  value={serviceName}
                />
              </div>
              <div className={classes.editContainerV2}>
                <div className={classes.closeIconContainer} onClick={() => handleClickDischargeChanges()}>
                  <CloseIcon className={classes.editUnitIcon} />
                  <p className={classes.editUnitP}>Descartar Cambios</p>
                </div>

                <div className={classes.saveIconContainer} onClick={() => handleSubmitService()}>
                  <SaveIcon className={classes.editUnitIcon} />
                  <p className={classes.editUnitP}>Guardar Cambios</p>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Tabs>
      {createNewService && services.length ? (
        <>
          <div className={classes.svgContainer}>
            <div>{svg}</div>
          </div>
          <div className={classes.textAndButtonContainer}>
            <p className={classes.textP2}>Todavía no hay subservicios en este servicio</p>

            <div className={classes.addAreaButton}>
              <Button variant="contained" className={classes.addUnitIcon} startIcon={<AddIcon />} disabled>
                Agregar subservicio
              </Button>
            </div>
          </div>
        </>
      ) : null}
      {!createNewService &&
        services?.map((x, i) => {
          return (
            <TabPanel value={value} index={i} key={i}>
              <div className={classes.subServiceContainer2}>
                {addSubService ? (
                  <div className={classes.subserviceCreateContainer}>
                    <div className={subserviceError ? classes.subserviceError : classes.subServiceInputContainer}>
                      <Input
                        className={classes.addSubserviceInput}
                        placeholder="Nombre del Subservicio"
                        inputProps={{ "aria-label": "description" }}
                        disableUnderline={true}
                        onChange={(e) => handleSubserviceChange(e)}
                        autoFocus
                        value={subserviceName}
                      />
                    </div>
                    <div className={classes.editContainerV2}>
                      <div className={classes.closeIconContainer} onClick={() => handleClickDischargeSubservice()}>
                        <CloseIcon className={classes.editUnitIcon} />
                        <p className={classes.editUnitP}>Descartar Cambios</p>
                      </div>

                      <div className={classes.saveIconContainer7} onClick={() => handleSubmitSubservice()}>
                        <SaveIcon className={classes.editUnitIcon} />
                        <p className={classes.editUnitP}>Guardar Cambios</p>
                      </div>
                    </div>
                  </div>
                ) : moveBed.state ? null : (
                  <small
                    onClick={() =>
                      !editSingleService.state && !editSingleBed.state && !editSingleSub.state && setAddSubService(true)
                    }
                    className={
                      editSingleService.state || editSingleBed.state || editSingleSub.state
                        ? classes.small2
                        : classes.small
                    }
                  >
                    + Agregar subservicio
                  </small>
                )}
                {subservices?.map((x, i) => {
                  return (
                    <div key={i}>
                      <div className={classes.subServiceContainerChild}>
                        <div
                          className={
                            !editSingleSub.state && !editSingleBed.state && hover.state && hover.index === i
                              ? classes.subServiceNameContainerHover
                              : classes.subServiceNameContainer2
                          }
                          onMouseEnter={() =>
                            !editSingleService.state &&
                            !editSingleBed.state &&
                            !addSubService &&
                            setHover({ index: i, state: true })
                          }
                          onMouseLeave={() =>
                            !editSingleService.state &&
                            !editSingleBed.state &&
                            !addSubService &&
                            setHover({ index: i, state: false })
                          }
                        >
                          {editSingleSub.index === i && editSingleSub.state ? (
                            <>
                              <EditSubserviceInput subserviceName={x.name} setValueEditSub={setValueEditSub} />
                              {/* <CloseIcon onClick={() => setEditSingleSub({ index: null, state: false })} /> */}
                            </>
                          ) : (
                            <>
                              <p className={classes.subserviceP}>{x.name}</p>
                              <div className={hover.state && hover.index === i ? classes.pointer : classes.none}>
                                <EditIcon onClick={() => setEditSingleSub({ index: i, state: true })} />
                                <DeleteIcon
                                  onClick={() => {
                                    setSubserviceInfo(x)
                                    setOpenDeleteSubserviceModal(true)
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        {moveBed.state && moveBed.index === x.subServiceId ? (
                          <div
                            className={classes.closeIconContainer4}
                            onClick={() => setMoveBed({ index: null, state: false })}
                          >
                            <CloseIcon className={classes.editUnitIcon} />
                            <p className={classes.editUnitP}>Descartar Cambios</p>
                          </div>
                        ) : null}

                        {moveBed.state ? null : (
                          <>
                            {editSingleSub.index === i && editSingleSub.state ? (
                              <>
                                <div className={classes.editContainerV2}>
                                  <div
                                    className={classes.closeIconContainer3}
                                    onClick={() => setEditSingleSub({ index: i, state: false })}
                                  >
                                    <CloseIcon className={classes.editUnitIcon} />
                                    <p className={classes.editUnitP}>Descartar Cambios</p>
                                  </div>

                                  <div
                                    className={classes.saveIconContainer7}
                                    onClick={() => handleEditSubservice(x.subServiceId, valueEditSub)}
                                  >
                                    <SaveIcon className={classes.editUnitIcon} />
                                    <p className={classes.editUnitP}>Guardar Cambios</p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className={classes.contentAdd}>
                                <Button
                                  onClick={() => setMoveBed({ index: x.subServiceId, state: true })}
                                  variant="contained"
                                  className={classes.iconAdd}
                                  startIcon={<OpenWithIcon />}
                                  disabled={
                                    editSingleService.state ||
                                    editSingleSub.state ||
                                    editSingleBed.state ||
                                    addSubService
                                  }
                                >
                                  MOVER CAMA
                                </Button>

                                <Button
                                  variant="contained"
                                  className={classes.iconAdd}
                                  startIcon={<AddIcon />}
                                  onClick={() => setAddBed({ index: x.subServiceId, state: true })}
                                  disabled={
                                    editSingleService.state ||
                                    editSingleSub.state ||
                                    editSingleBed.state ||
                                    addSubService
                                  }
                                >
                                  AGREGAR CAMA
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {moveBed.state && moveBed.index === x.subServiceId ? (
                        <div className={classes.selectAllBedsContainer}>
                          <p className={classes.moveTitleText}>Seleccionar camas</p>
                          <div>
                            <FormControlLabel
                              className={classes.selectAllFormControlLabel}
                              label="SELECCIONAR TODAS"
                              control={
                                <GreenCheckbox
                                  name="checkedG"
                                  onChange={(e) => handleChangeAllSelected(e, x.subServiceId)}
                                  checked={allSelected}
                                />
                              }
                            />
                          </div>
                        </div>
                      ) : null}

                      <BedStateCard
                        areaId={params?.areaId}
                        subServiceId={x?.subServiceId}
                        unitId={params?.unit}
                        floorId={params?.floorId}
                        serviceId={serviceId}
                        setAddBed={setAddBed}
                        addBed={addBed}
                        setMoveBed={setMoveBed}
                        moveBed={moveBed}
                        checked={checked}
                        setChecked={setChecked}
                        tab={tab}
                        subservices={subservices}
                        allSelected={allSelected}
                        editSingleService={editSingleService}
                        editSingleSub={editSingleSub}
                        editSingleBed={editSingleBed}
                        setEditSingleBed={setEditSingleBed}
                        addSubService={addSubService}
                        onOpenPopUpMoveBed={onOpenPopUpMoveBed}
                        onActivePopUp={onActivePopUp}
                        setOpenPopUpDeleteBed={setOpenPopUpDeleteBed}
                        setOpenPopUpBedError={setOpenPopUpBedError}
                        setOpenPopUpEditBed={setOpenPopUpEditBed}
                        setOpenPopUpBed={setOpenPopUpBed}
                      />
                      {(moveBed.state && moveBed.index === x.subServiceId && checked.length) ||
                      (moveBed.state && moveBed.index === x.subServiceId && allSelected) ? (
                        <div className={classes.contentAdd}>
                          <Link to="/architecture/moveBeds" className={classes.addUnitLink1}>
                            <Button
                              variant="contained"
                              className={classes.iconAdd}
                              startIcon={<OpenWithIcon />}
                              onClick={() => (allSelected ? handleClickMoveAllBeds() : handleClickMoveBeds(x))}
                            >
                              MOVER CAMAS SELECCIONADAS
                            </Button>
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
              </div>
            </TabPanel>
          )
        })}
      <PopUp open={openPopUp} onClose={() => onActivePopUp(setOpenPopUp, false)} title="Servicio agregado con éxito" />
      <PopUp
        open={openPopUpSubservice}
        onClose={() => onActivePopUp(setOpenPopUpSubservice, false)}
        title="Subservicio agregado con éxito"
      />
      <PopUp
        open={openPopUpBed}
        onClose={() => onActivePopUp(setOpenPopUpBed, false)}
        title="Cama agregada con éxito"
      />
      <PopUp
        open={openPopUpEditBed}
        onClose={() => onActivePopUp(setOpenPopUpEditBed, false)}
        title="Cama editada con éxito"
      />
      <PopUp
        open={openPopUpEditSub}
        onClose={() => onActivePopUp(setOpenPopUpEditSub, false)}
        title="Subservicio editado con éxito"
      />
      <PopUp
        open={openPopUpEditService}
        onClose={() => onActivePopUp(setOpenPopUpEditService, false)}
        title="Servicio editado con éxito"
      />
      <PopUp
        open={openPopUpDeleteService}
        onClose={() => onActivePopUp(setOpenPopUpDeleteService, false)}
        title="Servicio eliminado con éxito"
      />
      <PopUp
        open={openPopUpDeleteBed}
        onClose={() => onActivePopUp(setOpenPopUpDeleteBed, false)}
        title="Cama eliminada con éxito"
      />
      <PopUp open={openPopUpMoveBed} onClose={onClosePopUpMoveBed} title="Camas movidas con éxito" />
      <PopUp
        open={openPopUpDeleteSubservice}
        onClose={() => onActivePopUp(setOpenPopUpDeleteSubservice, false)}
        title="Subservicio eliminado con éxito"
      />
      <PopUp
        open={openPopUpSubError}
        onClose={() => onActivePopUp(setOpenPopUpSubError, false)}
        errorPopUp={true}
        title="Error al crear Subservicio, nombre repetido"
      />
      <PopUp
        open={openPopUpBedError}
        onClose={() => onActivePopUp(setOpenPopUpBedError, false)}
        errorPopUp={true}
        title="Error Cama con nombre repetido"
      />
      <PopUp
        open={openPopUpServError}
        onClose={() => onActivePopUp(setOpenPopUpServError, false)}
        errorPopUp={true}
        title="Error al crear Servicio, nombre repetido"
      />
      <DeleteSubserviceModal
        open={openDeleteSubserviceModal}
        onClose={onCloseDeleteSubserviceModal}
        subserviceInfo={subserviceInfo}
        setSubservices={setSubservices}
        onActivePopUp={onActivePopUp}
        setOpenPopUpDeleteSubservice={setOpenPopUpDeleteSubservice}
      />
      <DeleteServiceModal
        open={openServiceModal}
        onClose={onCloseServiceModal}
        deleteServiceId={serviceModalId}
        deleteServiceName={serviceModalName}
        setSingleService={setServices}
        unitId={params?.unit}
        areaId={params?.areaId}
        floorId={params?.floorId}
        setValue={setValue}
        getAllSubServices={getAllSubServices}
        getDetails={getDetails}
        onActivePopUp={onActivePopUp}
        setOpenPopUpDeleteService={setOpenPopUpDeleteService}
      />
    </>
  )
}

export default Services
