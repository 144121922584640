import React from 'react'
import XLSX from 'sheetjs-style';
import { handleExcelData } from '../utils/excelData';

export const useCreateExcelFile = ({ data, fileName, type}) => {

    const name = `${fileName}.xlsx`;
    
    const exportToExcel = ()=>{ 

        const dataParsed = handleExcelData(type, data)
        const worksheet = XLSX.utils.json_to_sheet(dataParsed)
        const workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workbook, worksheet, type)

        
        if(type === 'historico media medico'){
            const max_width = data.reduce((w, r) => Math.max(w, r.name.length), 20);
            const merge = [
              ];

            for (let index = 0; index < dataParsed.length + 1; index++) {
                merge.push({ s: { r: index, c: 4 }, e: { r: index, c: 5 } })
            }

            worksheet["!merges"] = merge;
            worksheet["!cols"] = [ { wch: max_width }, { wch: 35 }, { wch: 20 }, { wch:25 } ];

        }else if(type === 'historico media por piso'){
            const max_width = data.reduce((w, r) => Math.max(w, r.unitName.length), 20);
            const merge = [
              ];

            for (let index = 0; index < dataParsed.length + 1; index++) {
                merge.push({ s: { r: index, c: 4 }, e: { r: index, c: 5 } })
            }

            worksheet["!merges"] = merge;
            worksheet["!cols"] = [ { wch: max_width }, { wch: max_width }, { wch: 35 }, { wch:20 } ];
        }else{
            worksheet["!cols"] = [ { wch: 20 }, { wch: 25 } ];
        }

        XLSX.writeFile(workbook, name, { compression: true });
    }

    return { 
        exportToExcel
    }
}
