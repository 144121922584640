import React, { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { getSingleArea, deleteArea, getAreas } from "../../services/architechture"
import DashboardUnit from "../../contexts/dashboardUnitContext"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteAreaModal = ({ open, onClose, selectedArea, setSelectedArea, error, setError, onOpenPopUpDeleteArea }) => {
  const { setAreas, areas } = useContext(DashboardUnit)
  const classes = useStyles()
  const [floors, setFloors] = useState([])

  const getAllFloorsFromArea = async () => {
    try {
      const resp = await getSingleArea(selectedArea?.unitId, selectedArea?.areaId)
      setFloors(resp)
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickDeleteArea = async () => {
    try {
      const deleteResp = await deleteArea(selectedArea?.areaId)
      if (deleteResp) {
        const getAllAreasFromUnit = await getAreas(selectedArea?.unitId)
        setAreas(getAllAreasFromUnit)
      }
      onOpenPopUpDeleteArea()
      onClose()
    } catch (e) {
      setError(true)
      console.log(e)
    }
  }

  const handleClickSeeArea = () => {
    setError(false)
    setFloors([])
    onClose()
    setSelectedArea({})
  }

  useEffect(() => {
    getAllFloorsFromArea()
  }, [selectedArea])

  useEffect(() => {
    return () => setSelectedArea({})
  }, [])

  return (
    <div>
      {!error ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.deleteModal}
          open={open}
          onClose={onClose}
          closeAfterTransition
        >
          <div className={classes.paper}>
            <div className={classes.paperChild}>
              <div>{warningSvg}</div>

              <p className={classes.deleteModalTitleP}>Borrar Área</p>
              <p className={classes.deleteText}>
                ¿Desea borrar el área <span className={classes.deleteTextSpan}>{selectedArea?.name}</span>?
              </p>
              <div className={classes.deleteButtonContainer}>
                <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                  NO
                </Button>

                {!floors.length ? (
                  <Link to="/architecture/2" className={classes.addUnitLink1}>
                    <Button
                      variant="contained"
                      className={classes.deleteButton}
                      onClick={() => handleClickDeleteArea()}
                    >
                      SI, BORRAR ÁREA
                    </Button>
                  </Link>
                ) : (
                  <Button variant="contained" className={classes.deleteButton} onClick={() => handleClickDeleteArea()}>
                    SI, BORRAR ÁREA
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.deleteModal}
          open={open}
          onClose={onClose}
          closeAfterTransition
        >
          <div className={classes.paper}>
            <div className={classes.paperChild}>
              <div>{warningSvg}</div>

              <p className={classes.deleteText1}>
                No es posible borrar <span className={classes.deleteTextSpan1}>{selectedArea?.name}</span> porque tiene
                pisos, servicios, subservicios y camas.
              </p>

              <div className={classes.deleteButtonContainer1}>
                <Button variant="contained" className={classes.deleteButton} onClick={() => handleClickSeeArea()}>
                  VER ÁREA
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default DeleteAreaModal
