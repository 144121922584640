import React, { useEffect, useState, useContext } from "react"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { getAllBeds, getAreaName, getSingleArea, getAllServices, editBed } from "../../services/architechture"
import AddBedCard from "../../components/AddBedCard"
import EditBedInput from "../../components/EditBedInput"
import { GreenCheckbox } from "../../views/Architecture"
import { svgError, svgCheck } from "../../utils/svgHelper"
import DeleteBedModal from "../DeleteBedModal"
import DashboardUnit from "../../contexts/dashboardUnitContext"
// EXTERNAL COMPONENTS
import { Divider, makeStyles, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core"
import UpdateIcon from "@material-ui/icons/Update"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"
import EditBedComponent from "./EditBedComponent"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const BedStateCard = ({
  subServiceId,
  areaId,
  unitId,
  floorId,
  serviceId,
  setAddBed,
  addBed,
  moveBed,
  checked,
  setChecked,
  tab,
  subservices,
  allSelected,
  editSingleService,
  editSingleSub,
  editSingleBed,
  setEditSingleBed,
  addSubService,
  onActivePopUp,
  setOpenPopUpDeleteBed,
  setOpenPopUpBedError,
  setOpenPopUpEditBed,
  setOpenPopUpBed,
}) => {
  const classes = useStyles()
  const [areaName, setAreaName] = useState("")
  const [floors, setFloors] = useState([])
  const [services, setServices] = useState([])
  const [singleBedCode, setSingleBedCode] = useState("")
  const [hover, setHover] = useState({ index: null, state: true })
  const [openDeleteBedModal, setOpenDeleteBedModal] = useState(false)
  const [bedInfo, setBedInfo] = useState({})
  const [beds, setBeds] = useState([])

  const { detailsUnit } = useContext(DashboardUnit)

  const onCloseDeleteBedModal = () => {
    setOpenDeleteBedModal(false)
  }

  const getBeds = async () => {
    if (subServiceId && areaId && unitId && floorId && serviceId) {
      const resp = await getAllBeds(
        Number(unitId),
        Number(areaId),
        Number(floorId),
        Number(serviceId),
        Number(subServiceId),
      )

      setBeds(resp)
    }
  }

  const handleChangeCheck = (
    event,
    code,
    subServiceId,
    areaId,
    floorId,
    roomCode,
    serviceId,
    unitId,
    areaName,
    floors,
    services,
    subservices,
  ) => {
    setChecked([
      ...checked,
      {
        code,
        checked: event.target.checked,
        subServiceId,
        areaId,
        floorId,
        roomCode,
        serviceId,
        unitId,
        areaName,
      },
    ])
  }

  const checkedFn = (arr, bedCode) => {
    const resp = arr.filter((x) => x.bedCode === bedCode)
    return resp[0]?.checked
  }

  const getSingleAreaName = async () => {
    try {
      if (areaId) {
        const resp = await getAreaName(areaId)
        setAreaName(resp.name)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getFloorsFromArea = async () => {
    try {
      if (areaId && unitId) {
        const resp = await getSingleArea(unitId, areaId)
        setFloors(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getServices = async () => {
    try {
      if (unitId && areaId && floorId) {
        const resp = await getAllServices(unitId, areaId, floorId)
        setServices(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleEditBed = async (...args) => {
    try {
      const bedCode = args && args[0]
      const bedValue = args && args[1]
      const isVirtual = args && args[2]
      const isOncology = args && args[3]
      if (bedCode) {
        if (bedValue) {
          const virtual = isVirtual === true ? 1 : 0
          const isOncology = isOncology === true ? 1 : 0
          const resp = await editBed(bedCode, { isVirtual: virtual, isOncology: isOncology })
          if (resp && resp.code) {
            onActivePopUp(setOpenPopUpEditBed, true)
            getBeds()
            setEditSingleBed({ index: null, state: false })
          }
        } else if (!bedValue) {
          const virtual = isVirtual === true ? 1 : 0
          const isOncologyChair = isOncology === true ? 1 : 0
          const resp = await editBed(bedCode, { isVirtual: virtual, isOncology: isOncologyChair })
          if (resp && resp.code) {
            onActivePopUp(setOpenPopUpEditBed, true)
            getBeds()
            setEditSingleBed({ index: null, state: false })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getSingleAreaName()
    getFloorsFromArea()
    getServices()
  }, [])

  useEffect(() => {
    setChecked([])
  }, [tab])

  useEffect(() => {
    getBeds()
  }, [serviceId, subServiceId])

  const handleIsVirtualOrOncolyBed = (bed) => {

    let bedName = ""

    if(bed && bed.isVirtual === 1){
      bedName = "Cama Virtual"
    }else if(bed && bed.isOncology === 1){
      bedName = "Cama Oncológico"
    }else{
      bedName = "Cama Virtual / Oncológico"
    }

    return bedName;
  }

  return (
    <>
      <div className={classes.roomCardContainer}>
        {addBed.state && addBed.index === subServiceId ? (
          <AddBedCard
            subServiceId={subServiceId}
            areaId={areaId}
            unitId={unitId}
            floorId={floorId}
            serviceId={serviceId}
            setAddBed={setAddBed}
            addBed={addBed}
            setBeds={setBeds}
            setOpenPopUpBed={setOpenPopUpBed}
            setOpenPopUpBedError={setOpenPopUpBedError}
            onActivePopUp={onActivePopUp}
          />
        ) : null}
        {beds?.map((x, i) => {
          return (
            <>
              {allSelected ? (
                <div key={i}>
                  <div className={classes.roomCard} key={x.code}>
                    <div
                      className={classes.roomCardContentCode}
                      onMouseEnter={() => !moveBed.state && setHover({ index: i, state: true })}
                      onMouseLeave={() => !moveBed.state && setHover({ index: i, state: false })}
                    >
                      {editSingleBed.index === i && editSingleBed.state ? (
                        <>
                          <EditBedInput
                            bedCode={x.code}
                            setSingleBedCode={setSingleBedCode}
                            setEditSingleBed={setEditSingleBed}
                          />
                        </>
                      ) : (
                        <>
                          <p className={classes.roomCardTextData}>{x.code}</p>
                          <div className={hover.state && hover.index === i ? classes.pointer : classes.none}>
                            <EditIcon onClick={() => setEditSingleBed({ index: i, state: true })} />
                            <DeleteIcon
                              onClick={() => {
                                setBedInfo(x)
                                setOpenDeleteBedModal(true)
                              }}
                            />
                          </div>
                        </>
                      )}

                      {moveBed.state && moveBed.index === subServiceId ? (
                        <div className={classes.checkBoxBed1}>
                          <Checkbox checked={true} color="default" />
                        </div>
                      ) : null}
                    </div>
                    <Divider className={classes.roomCardDivider} />

                    {x.stateId === -1 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          <>
                            <div className={classes.roomCardContentText}>
                              <p>ESPERANDO ACTUALIZACIÓN</p>
                              <UpdateIcon />
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}>{handleIsVirtualOrOncolyBed(x)}</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {x.stateId === 1 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          <>
                            <div className={classes.roomCardContentText}>
                              <p className={classes.bedCodeError}>ERROR EN EL CÓDIGO DE CAMA</p>
                              {svgError}
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}></p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {x.stateId === 0 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          <>
                            <div className={classes.roomCardContentText}>
                              <p className={classes.updatedBed}>CAMA ACTUALIZADA</p>
                              {svgCheck}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}>{handleIsVirtualOrOncolyBed(x)}</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div key={i}>
                  <div className={classes.roomCard} key={x.code}>
                    <div
                      className={classes.roomCardContentCode}
                      onMouseEnter={() =>
                        !moveBed.state &&
                        !editSingleService.state &&
                        !editSingleSub.state &&
                        !addSubService &&
                        setHover({ index: i, state: true })
                      }
                      onMouseLeave={() =>
                        !moveBed.state &&
                        !editSingleService.state &&
                        !editSingleSub.state &&
                        !addSubService &&
                        setHover({ index: i, state: false })
                      }
                    >
                      {editSingleBed.index === i && editSingleBed.state ? (
                        <>
                          <EditBedInput
                            bedCode={x.code}
                            setSingleBedCode={setSingleBedCode}
                            setEditSingleBed={setEditSingleBed}
                          />
                          {/* <CloseIcon onClick={() => setEditSingleBed({ index: null, state: false })} /> */}
                        </>
                      ) : (
                        <>
                          <p className={classes.roomCardTextData}>{x.code}</p>
                          <div className={hover.state && hover.index === i ? classes.pointer : classes.none}>
                            <EditIcon onClick={() => setEditSingleBed({ index: i, state: true })} />
                            <DeleteIcon
                              onClick={() => {
                                setBedInfo(x)
                                setOpenDeleteBedModal(true)
                              }}
                            />
                          </div>
                        </>
                      )}

                      {moveBed.state && moveBed.index === subServiceId ? (
                        <div className={classes.checkBoxBed}>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                              <GreenCheckbox
                                checked={checkedFn(checked, x.code)}
                                onChange={(e) =>
                                  handleChangeCheck(
                                    e,
                                    x.code,
                                    x.subServiceId,
                                    x.areaId,
                                    x.floorId,
                                    x.roomCode,
                                    x.serviceId,
                                    x.unitId,
                                    areaName,

                                    // floors,
                                    // services,
                                    // subservices,
                                  )
                                }
                                name={x.code}
                              />
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                    <Divider className={classes.roomCardDivider} />

                    {x.stateId === -1 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          <>
                            <div className={classes.roomCardContentText}>
                              <p>ESPERANDO ACTUALIZACIÓN</p>
                              <UpdateIcon />
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}>{handleIsVirtualOrOncolyBed(x)}</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {x.stateId === 1 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          <>
                            <div className={classes.roomCardContentText}>
                              <p className={classes.bedCodeError}>ERROR EN EL CÓDIGO DE CAMA</p>
                              {svgError}
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}>{handleIsVirtualOrOncolyBed(x)}</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {x.stateId === 0 ? (
                      <>
                        {editSingleBed.index === i && editSingleBed.state ? (
                          <EditBedComponent handleEditBed={handleEditBed} bed={x} singleBedCode={singleBedCode} />
                        ) : (
                          // <div
                          //   className={classes.saveIconContainer4}
                          //   onClick={() => handleEditBed(x.code, singleBedCode)}
                          // >
                          //   <FormGroup>
                          //     <FormControlLabel
                          //       control={<Checkbox />}
                          //       label={<span style={{ fontSize: "14px", display: "flex" }}>Cama virtual</span>}
                          //     />
                          //   </FormGroup>
                          //   <div
                          //     style={{
                          //       display: "flex",
                          //       justifyContent: "center",
                          //       marginTop: "1rem",
                          //     }}
                          //   >
                          //     <SaveIcon className={classes.editUnitIcon} />
                          //     <p className={classes.editUnitP}>Guardar Cambios</p>
                          //   </div>
                          // </div>
                          <>
                            <div className={classes.roomCardContentText}>
                              <p className={classes.updatedBed}>CAMA ACTUALIZADA</p>
                              {svgCheck}
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ fontSize: "14px" }}>{handleIsVirtualOrOncolyBed(x)}</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "27px" }}>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                  {x && (x.isVirtual === 1 || x.isOncology === 1) ? "SI" : "NO"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )
        })}
      </div>
      <DeleteBedModal
        open={openDeleteBedModal}
        onClose={onCloseDeleteBedModal}
        bedInfo={bedInfo}
        setBeds={setBeds}
        onActivePopUp={onActivePopUp}
        setOpenPopUpDeleteBed={setOpenPopUpDeleteBed}
      />
    </>
  )
}

export default BedStateCard
