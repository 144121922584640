import React, { useState, useEffect } from "react"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { editBedGrdFonasaByAdmissionCode, createBedGrdFonasa } from "../../services/sheets"

const useStyles = makeStyles({
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  input: {
    margin: "1rem",
    width: "100%",
  },
  save: {
    background: "#30AFC0",
    color: "#ffffff",
    fontWeight: "600",
    borderRadius: "2rem",
    minWidth: "7rem",
    marginTop: "5rem",
    "&:hover": {
      background: "#30AFC0",
      color: "#ffffff",
    },
  },
})

const FormGRDFonasa = ({ registerDate, onClose, reloadData, patientes }) => {
  const classes = useStyles()
  const [dataGrdFonasa, setDataGrdFonasa] = useState(
    !registerDate ? { admissionCode: "", ugccId: "", patientRut: "", bedCode: "" } : registerDate,
  )

  const { admissionCode } = registerDate || {}

  const editBedGrdFonasa = async () => {
    try {
      const resp = await editBedGrdFonasaByAdmissionCode(admissionCode, dataGrdFonasa)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const createNewBedGrdFonasa = async () => {
    try {
      const resp = await createBedGrdFonasa(dataGrdFonasa)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!admissionCode) {
      createNewBedGrdFonasa()
    } else {
      editBedGrdFonasa()
    }
  }

  const handleChangeUGCCId = (event) => {
    setDataGrdFonasa({ ...dataGrdFonasa, [event.target.name]: event.target.value })
  }

  const handleChangeData = (event, newValue) => {
    setDataGrdFonasa({
      ...dataGrdFonasa,
      admissionCode: !newValue?.admissionCode ? "" : newValue.admissionCode,
      patientRut: !newValue?.rut ? "" : newValue.rut,
      bedCode: !newValue?.code ? "" : newValue.code,
    })
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Autocomplete
        id="admissionCode-covid19"
        className={classes.input}
        options={patientes}
        getOptionLabel={(option) => option.bedCode}
        inputValue={dataGrdFonasa["bedCode"]}
        defaultValue={patientes.find((p) => p.bedCode === dataGrdFonasa["bedCode"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataGrdFonasa({
            ...dataGrdFonasa,
            bedCode: newInputValue,
          })
        }}
        getOptionSelected={(option, value) => option.bedCode === dataGrdFonasa["bedCode"]}
        renderInput={(params) => <TextField {...params} label="Código de Cama" variant="outlined" />}
      />

      <Autocomplete
        id="rut-covid19"
        className={classes.input}
        options={patientes}
        inputValue={dataGrdFonasa["patientRut"]}
        defaultValue={patientes.find((p) => p.rut === dataGrdFonasa["patientRut"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataGrdFonasa({
            ...dataGrdFonasa,
            patientRut: newInputValue,
          })
        }}
        getOptionLabel={(option) => option.rut}
        getOptionSelected={(option, value) => option.rut === dataGrdFonasa["patientRut"]}
        renderInput={(params) => <TextField {...params} label="RUT" variant="outlined" />}
      />
      <FormControl className={classes.input} variant="outlined">
        <InputLabel htmlFor="state-covid19">UGCC ID</InputLabel>
        <OutlinedInput
          id="state-covid19"
          type="text"
          value={dataGrdFonasa["ugccId"]}
          onChange={handleChangeUGCCId}
          labelWidth={60}
          name="ugccId"
        />
      </FormControl>
      <Button type="submit" className={classes.save}>
        {!registerDate ? `CREAR` : `GUARDAR`}
      </Button>
    </form>
  )
}

export default FormGRDFonasa
