import { useState, useEffect, useContext } from "react"
import { getCommercialView } from "../../services/beds"
import { makeStyles } from "@material-ui/core/styles"
import { Divider, Typography } from "@material-ui/core"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import TableFonasaCommercialView from "../../components/TableFonasaCommercialView"
import TableIsapresCommercialView from "../../components/TableIsapresCommercialView"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "2.3rem",
    color: "#566573",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      margin: "0 0 1rem",
      [theme.breakpoints.down("md")]: {
        margin: "2rem 0 1rem",
      },
    },
  },
  subtitle: {
    fontSize: "1.75rem",
    color: "#009999",
    marginTop: "1em",
  },
  urgency: {
    background: "#F1948A",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "2rem 0",
    padding: "1rem",
    borderRadius: "0.4rem",
    maxWidth: "20rem",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
  contentTable: {
    margin: "5rem 0",
    [theme.breakpoints.down("md")]: {
      margin: "1rem 0",
    },
  },
}))

export default function CommercialView() {
  const [loading, setLoading] = useState(false)
  const [gesCaecGrdFonasa, setGesCaecGrdFonasa] = useState([])
  const [isapres, setIsapres] = useState([])
  const [urgencyLaw, setUrgencyLaw] = useState([])
  const classes = useStyles()
  const { detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    getDataCommercialView(detailsUnit)
  }, [detailsUnit])

  const getDataCommercialView = async () => {
    setLoading(true)
    try {
      const data = await getCommercialView()
      setGesCaecGrdFonasa(data.gesCaecGrdFonasa)
      setIsapres(data.isapres)
      setUrgencyLaw(data.urgencyLaw)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formatDataForTable = (d) => {
    const dataTable =
      d &&
      d
        .map((dato) => {
          return (
            dato.beds &&
            dato.beds.map((bed) => ({
              hasInterconsultations: bed.hasInterconsultations,
              hasTreatments: bed.hasTreatments,
              admissionCode: bed.admissionCode,
              areaId: bed.areaId,
              bedLastChangedDate: bed.bedLastChangedDate,
              code: bed.code,
              complexityChangeCode: bed.complexityChangeCode,
              covid19: bed.covid19,
              floorId: bed.floorId,
              grd: bed.grd,
              interconsultations: bed.interconsultations,
              isapre: bed.isapre,
              isaprePlan: bed.isaprePlan,
              patientDischargeDate: bed.patientDischargeDate,
              patientName: bed.patientName,
              prestationCode: bed.prestationCode,
              professionalName: bed.professionalName,
              reasson: bed.reasson,
              roomCode: bed.roomCode,
              serviceId: bed.serviceId,
              sex: bed.sex,
              stateDescription: bed.stateDescription,
              stateId: bed.stateId,
              strAge: bed.strAge,
              strStayTime: bed.strStayTime,
              subServiceId: bed.subServiceId,
              treatments: bed.treatments,
              unitId: bed.unitId,
              urgencyLaw: bed.urgencyLaw,
            }))
          )
        })
        .flat(3)
    return dataTable
  }
  const formatDataForTable2 = (d) => {
    const dataTable =
      d &&
      d
        .map((isapre) => {
          return (
            isapre &&
            isapre.plans.map((plan) => {
              return (
                plan &&
                plan.subServices.map((subService) => {
                  return (
                    subService &&
                    subService.beds.map((bed) => ({
                      hasInterconsultations: bed.hasInterconsultations,
                      hasTreatments: bed.hasTreatments,
                      admissionCode: bed.admissionCode,
                      areaId: bed.areaId,
                      bedLastChangedDate: bed.bedLastChangedDate,
                      code: bed.code,
                      complexityChangeCode: bed.complexityChangeCode,
                      covid19: bed.covid19,
                      floorId: bed.floorId,
                      grd: bed.grd,
                      interconsultations: bed.interconsultations,
                      isapre: bed.isapre,
                      isaprePlan: bed.isaprePlan,
                      patientDischargeDate: bed.patientDischargeDate,
                      patientName: bed.patientName,
                      prestationCode: bed.prestationCode,
                      professionalName: bed.professionalName,
                      reasson: bed.reasson,
                      roomCode: bed.roomCode,
                      serviceId: bed.serviceId,
                      sex: bed.sex,
                      stateDescription: bed.stateDescription,
                      stateId: bed.stateId,
                      strAge: bed.strAge,
                      strStayTime: bed.strStayTime,
                      subServiceId: bed.subServiceId,
                      treatments: bed.treatments,
                      unitId: bed.unitId,
                      urgencyLaw: bed.urgencyLaw,
                    }))
                  )
                })
              )
            })
          )
        })
        .flat(3)
    return dataTable
  }

  return (
    <div>
      <Typography variant="h3" className={classes.title}>
        Vista Comercial
      </Typography>
      <Divider />
      {gesCaecGrdFonasa.length > 0 && (
        <div className={classes.contentTable}>
          <Typography variant="h4" className={classes.subtitle}>
            Fonasa
          </Typography>
          <TableFonasaCommercialView data={formatDataForTable(gesCaecGrdFonasa)} />
        </div>
      )}

      {urgencyLaw.length > 0 && (
        <div>
          <Link to="/urgencylaw" className={classes.urgency}>
            <Typography variant="h4" className={classes.text}>
              Ley de Urgencia
            </Typography>
            <Typography variant="h4" className={classes.text}>
              {formatDataForTable(urgencyLaw).length}
            </Typography>
          </Link>
        </div>
      )}

      {isapres.length > 0 && (
        <div className={classes.contentTable}>
          <Typography variant="h4" className={classes.subtitle}>
            Isapres
          </Typography>
          <TableIsapresCommercialView data={formatDataForTable2(isapres)} />
        </div>
      )}
    </div>
  )
}
