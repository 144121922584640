import React, { useState, useEffect } from "react"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { getBusyBeds, putBusyBeds, putBeds, getBeds } from "../../services/beds"
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import { format } from "date-fns"
import useStyles from "./styles"

export default function ReserveModal({ open, onClose, setCurrentReserve, code, admissionCode, stateId }) {
  const classes = useStyles()
  const [reserve, setReserve] = useState({ patientName: "", patientRut: "", datetime: null })
  const [isReserved, setIsReserved] = useState(false)
  // CREATION ERROR
  const [inputError, setInputError] = useState(false)
  const [inputNameError, setInputNameError] = useState(false)
  const [timeError, setTimeError] = useState(false)
  const [fistLog, setFirstLog] = useState(true)
  useEffect(() => {
    loadResrve()
  }, [])

  const handleFormatDate = (date) => {
    const dateFormat = "yyyy-MM-dd HH:mm:ss"
    const now = format(Date.now(), dateFormat)
    const formatDate = format(new Date(date), dateFormat)
    if (formatDate < now) return null
    setTimeError(false)
    return formatDate
  }

  const onChangeReserveName = (event) => {
    setReserve({ ...reserve, patientName: event.target.value })
  }

  const onChangeReserveRut = (event) => {
    setReserve({ ...reserve, patientRut: event.target.value })
  }
  const handleError = () => {
    setFirstLog(false)
    const inputValidate = []
    inputValidate.push(reserve.patientRut)
    const reg = new RegExp(/[0-9Kk]/)
    const flag = inputValidate.join("").split("-")[1]
    if (
      reserve.patientRut.length < 9 ||
      inputValidate.join("").split("").indexOf("-") > 8 ||
      inputValidate.join("").split("").indexOf("-") === -1 ||
      Number(inputValidate.join("").split("-")[inputValidate.length - 1]) > 35000000 ||
      !reg.test(flag)
    ) {
      setInputError(true)
    } else {
      setInputError(false)
    }
  }

  const handleErrorName = () => {
    setFirstLog(false)
    const inputValidate = []
    inputValidate.push(reserve.patientName)
    const reg = new RegExp(/^[A-Za-z ]{0,360}$/)
    if (reserve.patientName.length < 1 || reserve.patientName.length > 360 || reg.test(reg)) {
      setInputNameError(true)
    } else {
      setInputNameError(false)
    }
  }

  const onChangeReserveDateTime = (event) => {
    const val = handleFormatDate(event)
    if (val) {
      setReserve({ ...reserve, datetime: val });
    } else {
      setTimeError(true);
      setReserve({ ...reserve, datetime: null });
    }
  }

  const saveReserve = async (event) => {
    event.preventDefault()
    try {
      if (stateId != 3) {
        const resp = await putBeds(code, {
          reserveData: reserve,
        })
      } else {
        const resp = await putBusyBeds(code, admissionCode, {
          reserveData: reserve,
        })
      }
      setIsReserved(true)
      loadResrve()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }
  const dropReserve = async (event) => {
    event.preventDefault()
    try {
      if (stateId != 3) {
        const resp = await putBeds(code, {
          reserveData: null,
        })
      } else {
        const resp = await putBusyBeds(code, admissionCode, {
          reserveData: {},
        })
      }
      setReserve({ patientName: "", patientRut: "", datetime: null })
      setIsReserved(false)
      loadResrve()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const loadResrve = async () => {
    try {
      if (stateId != 3) {
        const resp = await getBeds(code)

        if (!resp.reserveData) {
          setReserve({ patientName: "", patientRut: "", datetime: null })
        } else {
          setReserve(resp.reserveData)
          setIsReserved(true)
        }
        setCurrentReserve(resp.reserveData)
      } else {
        const resp = await getBusyBeds(code, admissionCode)
        if (!resp.reserveData) {
          setReserve({ patientName: "", patientRut: "", datetime: null })
        } else {
          setReserve(resp.reserveData)
          setIsReserved(true)
        }
        setCurrentReserve(resp.reserveData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const restartData = () => {
    setReserve({ patientName: "", patientRut: "", datetime: null })
    setInputError(false)
    setInputNameError(false)
    setTimeError(false)
    onClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={restartData}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form className={classes.form}>
              <div className={classes.reserve}>
                <h4 className={classes.titleReserve}>Reserva de cama</h4>
                <TextField
                  required
                  className={classes.inputReserve}
                  onChange={(event) => onChangeReserveName(event)}
                  id="namePatiente"
                  label="Nombre"
                  variant="outlined"
                  error={inputNameError ? true : false}
                  value={reserve?.patientName}
                  onBlur={handleErrorName}
                  onFocus={() => setInputNameError(false)}
                />
                {inputNameError && (
                  <Typography variant="body1" display="block" className={classes.errorMsg} gutterBottom>
                    Por favor ingrese un nombre valido
                  </Typography>
                )}
                <TextField
                  required
                  className={classes.inputReserve}
                  onChange={(event) => onChangeReserveRut(event)}
                  id="rutPatiente"
                  label="RUT"
                  variant="outlined"
                  error={inputError ? true : false}
                  value={reserve?.patientRut}
                  onBlur={handleError}
                  onFocus={() => setInputError(false)}
                />
                {inputError && (
                  <Typography variant="body1" display="block" className={classes.errorMsg} gutterBottom>
                    Por favor ingrese un RUT valido
                  </Typography>
                )}
                <KeyboardDateTimePicker
                  required
                  className={classes.inputReserve}
                  variant="outlined"
                  inputVariant="outlined"
                  value={reserve?.datetime}
                  onChange={onChangeReserveDateTime}
                  label="Fecha de Reserva"
                  onError={console.log}
                  format="dd/MM/yyyy hh:mm a"
                  minDate={Date.now()}
                />
                {timeError && (
                  <Typography
                    variant="body1"
                    display="block"
                    style={{ marginBottom: "20px" }}
                    className={classes.errorMsg}
                    gutterBottom
                  >
                    La hora debe ser posterior a la actual
                  </Typography>
                )}
                <div className={classes.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonCancel}
                    onClick={() => restartData()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant={inputError ? "disabled" : "contained"}
                    color="primary"
                    className={classes.buttonAccept}
                    onClick={saveReserve}
                    disabled={
                      reserve.patientName === "" || reserve.patientRut === "" || reserve.datetime === null
                        ? true
                        : false
                    }
                  >
                    Aceptar
                  </Button>
                </div>
                {isReserved && (
                  <div className={classes.dropReserveContainer}>
                    <Button variant="contained" className={classes.dropReserve} onClick={dropReserve}>
                      Eliminar Reserva
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
