// import "./App.css"
import { useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import { Switch, Route } from "react-router-dom"
import MUIThemeProvider from "./contexts/MUIThemeProvider"
import { UserNotAvailable } from "./components"
import DashboardLayout from "./components/DashboardLayout"
import DashboardUnit from "./contexts/dashboardUnitContext"
import AuthContext from "./contexts/authContext"
import ComercialBedsType from "./views/ComercialBedsType"
import Dashboard from "./views/Dashboard"
import BedsLog from "./views/BedsLog"
import BedsScreen from "./views/BedsScreen"
import BedsServices from "./views/BedsServices"
import UrgencyLaw from "./views/UrgencyLaw"
import Contingency from "./views/Contingency"
import Login from "./views/Login"
import CommercialView from "./views/CommercialView"
import UsersAdministration from "./views/UsersAdministration"
import Sheets from "./views/Sheets"
import Architecture from "./views/Architecture"
import Services from "./components/Services"
import MoveBeds from "./views/MoveBeds"

import ComercialBeds from "./contexts/comercialBedsContext"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import esLocale from "date-fns/locale/es"
import NewUnit from "./components/NewUnit"
import MonitorMC from "./views/MonitorMC/MonitorMC"
import RedStatus from "./views/RedStatus/RedStatus"
import { HistoryView } from "./views/History/HistoryView"

function App() {
  const [detailsUnit, setDetailsUnit] = useState(null)
  const [lastDateUpdate, setDateUpdate] = useState("")
  const [comercialBeds, setComercialBeds] = useState([])
  const [auth, setAuth] = useState(null)
  const [unit, setUnit] = useState(localStorage.getItem("unit") || 0)
  const [addUnit, setAddUnit] = useState(false)
  const [areas, setAreas] = useState([])
  const [services, setServices] = useState([])
  const [moveSelectedBeds, setMoveSelectedBeds] = useState([])
  const [openPopUpDeleteUnit, setOpenPopUpDeleteUnit] = useState(false)
  const [openPopUpMoveBed, setOpenPopUpMoveBed] = useState(false)

  const [editGeneralInput, setEditGeneralInput] = useState({
    unit: { state: false },
    unitApi: { state: false },
    area: { name: null, state: false },
    floor: { name: null, state: false },
    service: { name: null, state: false },
  })
  const [refreshLabelUnit, setRefreshLabelUnit] = useState(false)
  const [refreshLabelUnitUrl, setRefreshLabelUnitUrl] = useState(false)
  const [contextSubServiceName, setContextSubServiceName] = useState(null)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      setAuth(user)
    }
  }, [])
  const onOpenPopUpDeleteUnit = () => {
    setOpenPopUpDeleteUnit(true)
  }
  const onClosePopUpDeleteUnit = () => {
    setOpenPopUpDeleteUnit(false)
  }
  const onOpenPopUpMoveBed = () => {
    setOpenPopUpMoveBed(true)
  }
  const onClosePopUpMoveBed = () => {
    setOpenPopUpMoveBed(false)
  }

  const userAvailable = auth && auth.roles && auth.roles.includes("ROLE_NONE")

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <BrowserRouter>
        <MUIThemeProvider>
          <AuthContext.Provider value={{ auth, setAuth }}>
            {!auth ? (
              <Switch>
                <Route path="/health">
                  <h3>The App is Healthy</h3>
                </Route>
                <Route exact path="/" component={Login} />
              </Switch>
            ) : userAvailable ? (
              <Switch>
                <Route exact path="/" component={UserNotAvailable} />
                <Route exact path="/login" component={Login} />
                <Route path="/health">
                  <h3>The App is Healthy</h3>
                </Route>
              </Switch>
            ) : (
              <DashboardUnit.Provider
                value={{
                  detailsUnit,
                  setDetailsUnit,
                  lastDateUpdate,
                  setDateUpdate,
                  unit,
                  setUnit,
                  addUnit,
                  setAddUnit,
                  areas,
                  setAreas,
                  services,
                  setServices,
                  moveSelectedBeds,
                  setMoveSelectedBeds,
                  setEditGeneralInput,
                  editGeneralInput,
                  setRefreshLabelUnit,
                  refreshLabelUnit,
                  refreshLabelUnitUrl,
                  setRefreshLabelUnitUrl,
                  setContextSubServiceName,
                  contextSubServiceName,
                  openPopUpDeleteUnit,
                  setOpenPopUpDeleteUnit,
                  onOpenPopUpDeleteUnit,
                  onClosePopUpDeleteUnit,
                  onClosePopUpMoveBed,
                  onOpenPopUpMoveBed,
                  openPopUpMoveBed,
                }}
              >
                <ComercialBeds.Provider value={{ comercialBeds, setComercialBeds }}>
                  <DashboardLayout>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                      <Route path="/urgencylaw" exact component={UrgencyLaw} />
                      <Route path="/contingency" exact component={Contingency} />
                      <Route path="/commercialview" exact component={CommercialView} />
                      <Route path="/commercialview/:type" exact component={ComercialBedsType} />
                      <Route path="/services/:id" exact component={BedsServices} />
                      <Route path="/beds/:state" exact component={BedsScreen} />
                      <Route path="/bedslog" exact component={BedsLog} />
                      <Route path="/usersAdministration" exact component={UsersAdministration} />
                      <Route path="/sheets" exact component={Sheets} />
                      <Route path="/architecture/addUnit" exact component={NewUnit} />
                      <Route path="/architecture/moveBeds" exact component={MoveBeds} />
                      <Route path="/architecture/:unit" exact component={Architecture} />
                      <Route path="/architecture/:unit/:areaId/:floorId" exact component={Services} />
                      <Route path="/reserves/:id" exact component={BedsServices} />
                      <Route path="/monitor" exact component={MonitorMC} />
                      <Route path="/redStatus" exact component={RedStatus} />
                      <Route path="/history" exact component={HistoryView} />
                      <Route path="/health">
                        <h3>The App is Healthy</h3>
                      </Route>
                    </Switch>
                  </DashboardLayout>
                </ComercialBeds.Provider>
              </DashboardUnit.Provider>
            )}
          </AuthContext.Provider>
        </MUIThemeProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  )
}

export default App
