import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    marginLeft: "10px",
    marginBottom: "10px",
    width: 145,
    maxHeight: 120,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 13,
    color: "green",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      padding: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "2px",
    },
  },
  count: {
    fontSize: 20,
    color: "black",
    display: "flex",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    textDecoration: "white",
  },
}))

export default useStyles
