import React from "react"

// INTERNAL COMPONENTS
import { svgCheck, svgError } from "../../utils/svgHelper"

// EXTERNAL COMPONENTS
import { makeStyles, Modal } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "358px",
    height: " 100px",
    backgroundColor: "#F5F5F5",
    boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.12), 0px 2px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
    marginBottom: "3.375rem",
    marginRight: "28px",
  },
  paperP: {
    marginLeft: "1.667rem",
    justifySelf: "center",
    marginRight: "27px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
  },
  error: {
    width: "400px",
    height: " 100px",
    backgroundColor: "#F5F5F5",
    boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.12), 0px 2px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
    marginBottom: "3.375rem",
    marginRight: "28px",
  },
}))

export default function ReserveModal({ open, onClose, title, errorPopUp }) {
  const classes = useStyles()

  return (
    <div className={classes.modalContainer}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <div className={errorPopUp ? classes.error : classes.paper}>
            {/* <div style={{ justifySelf: "center", marginLeft: "-20px" }}>{errorPopUp ? svgError : svgCheck}</div> */}
            <div style={{ marginLeft: "1rem" }}>{errorPopUp ? svgError : svgCheck}</div>
            <div className={classes.paperP}>
              <p id="transition-modal-description">{title}</p>
            </div>
            <IconButton onClick={() => onClose()} aria-label="close" style={{ alignSelf: "flex-start" }}>
              <CloseIcon />
            </IconButton>
          </div>
        </>
      </Modal>
    </div>
  )
}
