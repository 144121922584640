import React, { useState, useEffect } from "react"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { editBedGesCaecByAdmissionCode, createBedGesCaec } from "../../services/sheets"

const useStyles = makeStyles({
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  input: {
    margin: "1rem",
    width: "100%",
  },
  save: {
    background: "#30AFC0",
    color: "#ffffff",
    fontWeight: "600",
    borderRadius: "2rem",
    marginTop: "5rem",
    minWidth: "7rem",
    "&:hover": {
      background: "#30AFC0",
      color: "#ffffff",
    },
  },
})

const FormGESCAEC = ({ registerDate, onClose, reloadData, patientes }) => {
  const classes = useStyles()
  const [dataGesCaec, setDataGesCaec] = useState(
    !registerDate ? { admissionCode: "", diagnostic: "", patientRut: "", bedCode: "" } : registerDate,
  )

  const { admissionCode } = registerDate || {}

  const editBedGesCaec = async () => {
    try {
      const resp = await editBedGesCaecByAdmissionCode(admissionCode, dataGesCaec)
      reloadData()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const createNewBedGesCaec = async () => {
    try {
      const resp = await createBedGesCaec(dataGesCaec)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!admissionCode) {
      createNewBedGesCaec()
    } else {
      editBedGesCaec()
    }
  }

  const handleChangeDiagnostic = (event) => {
    setDataGesCaec({ ...dataGesCaec, [event.target.name]: event.target.value })
  }

  const handleChangeData = (event, newValue) => {
    setDataGesCaec({
      ...dataGesCaec,
      admissionCode: !newValue?.admissionCode ? "" : newValue.admissionCode,
      patientRut: !newValue?.rut ? "" : newValue.rut,
      bedCode: !newValue?.code ? "" : newValue.code,
    })
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Autocomplete
        id="admissionCode-covid19"
        className={classes.input}
        options={patientes}
        getOptionLabel={(option) => option.bedCode}
        inputValue={dataGesCaec["bedCode"]}
        defaultValue={patientes.find((p) => p.bedCode === dataGesCaec["bedCode"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataGesCaec({
            ...dataGesCaec,
            bedCode: newInputValue,
          })
        }}
        getOptionSelected={(option, value) => option.bedCode === dataGesCaec["bedCode"]}
        renderInput={(params) => <TextField {...params} label="Código de Cama" variant="outlined" />}
      />
      <Autocomplete
        id="rut-gescaec"
        className={classes.input}
        options={patientes}
        inputValue={dataGesCaec["patientRut"]}
        defaultValue={patientes.find((p) => p.rut === dataGesCaec["patientRut"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataGesCaec({
            ...dataGesCaec,
            patientRut: newInputValue,
          })
        }}
        getOptionLabel={(option) => option.rut}
        getOptionSelected={(option, value) => option.rut === dataGesCaec["patientRut"]}
        renderInput={(params) => <TextField {...params} label="RUT" variant="outlined" />}
      />
      <FormControl className={classes.input} variant="outlined">
        <InputLabel htmlFor="diagnostic-gescaec">Diagnostico</InputLabel>
        <OutlinedInput
          id="diagnostic-gescaec"
          type="text"
          value={dataGesCaec["diagnostic"]}
          onChange={handleChangeDiagnostic}
          labelWidth={80}
          name="diagnostic"
        />
      </FormControl>
      <Button type="submit" className={classes.save}>
        {!admissionCode ? `CREAR` : `GUARDAR`}
      </Button>
    </form>
  )
}

export default FormGESCAEC
