import React, { useContext } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import { NavLink, useHistory } from "react-router-dom"
import AuthContext from "../../contexts/authContext"

import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import TimelineIcon from "@material-ui/icons/Timeline"
import DashboardUnit from "../../contexts/dashboardUnitContext"

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#566573",
    fontSize: "1rem",
    textDecoration: "none",
    width: "100%",
    padding: "0.8rem",
  },
  menuItemImg: {
    textDecoration: "none",
    width: "100%",
    padding: "0",
  },
  img: {
    width: "14rem",
    [theme.breakpoints.down("md")]: {
      width: "12rem",
    },
  },
  isActived: {
    backgroundColor: "#009DB1",
    opacity: "0.9",
    color: "#fff",
    borderRadius: "0 2rem 2rem 0",
  },
  menuText: {
    marginLeft: "1rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0.6rem",
    },
  },
  dropdown: {
    boxShadow: "none",
    border: "none",
    color: "#566573",
    "&::before": {
      background: "none",
    },
  },
  textDropdown: {
    padding: "0.8rem",
    fontWeight: "bold",
    "& div": {
      alignItems: "center",
    },
    "& svg": {
      width: "1.3rem",
      height: "1.3rem",
    },
  },
  optionsDropdown: {
    display: "flex",
    flexDirection: "column",
  },
  linkHome: {
    marginTop: ".5rem",
    marginBottom: "0",
  },
  title: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#566573",
    marginLeft: "1rem",
    marginTop: "1rem",
  },
}))

const MenuItem = ({ icon, text, to, img, ...props }) => {
  const classes = useStyles()

  const history = useHistory()

  const handleReloadPage = () => {
    if (history.location.pathname === to) {
      window.location.reload()
    }
  }

  return (
    <div>
      {!img ? (
        <NavLink to={to} className={classes.menuItem} {...props}>
          {typeof icon === "string" ? <i className={icon}></i> : icon}
          <p className={classes.menuText}>{text}</p>
        </NavLink>
      ) : (
        <NavLink to={to} className={classes.menuItemImg} {...props} onClick={handleReloadPage}>
          <img src={img} alt={text} className={classes.img} />
        </NavLink>
      )}
    </div>
  )
}

const MenuDropdown = ({ text, options, handleDrawerClose }) => {
  const classes = useStyles()
  return (
    <Accordion className={classes.dropdown}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.textDropdown}
      >
        <i className="fas fa-building"></i>
        <p className={classes.menuText}>{text}</p>
      </AccordionSummary>
      <AccordionDetails className={classes.optionsDropdown}>
        {options &&
          options.map((op, i) => (
            <MenuItem onClick={handleDrawerClose} key={i} text={op.service} to={`/services/${op.idService}`} />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default function PersistentDrawerLeft() {
  const classes = useStyles()

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const { detailsUnit } = useContext(DashboardUnit)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const formatDataDetailsArea = (floors) => {
    const data = floors
      .map((el) => el.services.map((ser) => ({ service: `${el.name} - ${ser.name}`, idService: ser.id })))
      .flat()
    return data
  }

  const {
    auth: { roles },
  } = useContext(AuthContext)

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.linkHome} onClick={handleDrawerClose}>
            <MenuItem img="/logo-redsalud.png" text="Inicio" to="/" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <p className={classes.title}>ADMINISTRADOR</p>

        <List>
          {roles && roles.length && (roles[0] === "ROLE_SUPER_ADMIN" || roles[0] === "ROLE_ADMIN_FILIAL") ? (
            <MenuItem
              icon="fas fa-hospital-user"
              text="Unidades"
              to={`/architecture/${detailsUnit?.unit[0]?.id}`}
              activeClassName={classes.isActived}
              onClick={handleDrawerClose}
            />
          ) : null}
          <MenuItem
            icon="fas fa-user-shield"
            text="Privilegios"
            to="/usersAdministration"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
          <Divider />

          <MenuItem
            icon="fas fa-list-alt"
            text="Vista Comercial"
            to="/commercialview"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
          <MenuItem
            icon="fas fa-gavel"
            text="Ley de Urgencia"
            to="/urgencylaw"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
          <MenuItem
            icon="fas fa-user-injured"
            text="Pacientes de Contingencia"
            to="/contingency"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
          <MenuItem
            icon="fas fa-book-open"
            text="Planillas"
            to="/sheets"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
          {detailsUnit?.unit[0]?.name === "Providencia" ? (
            <MenuItem
              icon="fas fa-building"
              text="Pre-Operatorio"
              to="/services/operative"
              activeClassName={classes.isActived}
              onClick={handleDrawerClose}
            />
          ) : null}
          <MenuItem
            icon={<TimelineIcon style={{ marginRight: "0px !important" }} />}
            text="Historico de Camas"
            to="/history"
            activeClassName={classes.isActived}
            onClick={handleDrawerClose}
          />
        </List>
        <Divider />
        <p className={classes.title}>ÁREAS</p>
        {detailsUnit?.unit.map((u, i) => {
          return u.areas.map((area, index) => (
            <MenuDropdown
              handleDrawerClose={handleDrawerClose}
              key={index}
              text={area.name}
              options={formatDataDetailsArea(area.floors)}
            />
          ))
        })}
      </Drawer>
    </>
  )
}
