import API from "../../utils/api"

const getDetailsAllUsers = async () => {
  const path = `/users`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editDetailsUser = async (data) => {
  const path = `/users`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getRolesUser = async () => {
  const path = `/roles`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteUser = (id) => {
  const path = `/users/${id}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export { getDetailsAllUsers, editDetailsUser, getRolesUser, deleteUser }
