import React, { useEffect, useState } from "react"

//styles
import { architechtureStyles } from "../../utils/architechtureStyles"

//material ui
import { makeStyles, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core"

import SaveIcon from "@material-ui/icons/Save"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const EditBedComponent = ({ bed, handleEditBed, singleBedCode }) => {
  const classes = useStyles()

  const [isVirtual, setIsVirtual] = useState(false)
  const [isOncologyChair, setIsOncologyChair] = useState(false)

  useEffect(() => {
    if (bed && bed.isVirtual && bed.isVirtual === 1) setIsVirtual(true)
    else if (bed && bed.isVirtual && bed.isVirtual === 0) setIsVirtual(false)
  }, [])

  useEffect(() => {
    if (bed && bed.isOncology && bed.isOncology === 1) setIsOncologyChair(true)
    else if (bed && bed.isOncology && bed.isOncology === 0) setIsOncologyChair(false)
  }, [])

  const handleChange = ({ target }) => {
    const checked = target.checked
    setIsVirtual(checked)
  }

  const handleChangeOncologyChair  = ({ target }) => {
    const checked = target.checked
    setIsOncologyChair(checked)
  }

 console.log(singleBedCode,'singleBedCode')
  return (
    <div className={classes.saveIconContainer4}>
      <FormGroup style={{ display:'flex', flexDirection:'row' }}>
        <FormControlLabel
          control={<Checkbox value={isVirtual} checked={isVirtual} onChange={(e) => handleChange(e)} disabled={isOncologyChair} />}
          label={<span style={{ fontSize: "14px", display: "flex" }}>Cama virtual</span>}
        />
        <FormControlLabel
          control={<Checkbox value={isOncologyChair} checked={isOncologyChair} onChange={(e) => handleChangeOncologyChair(e)} disabled={isVirtual} />}
          label={<span style={{ fontSize: "14px", display: "flex" }}>Sillón Oncológico</span>}
        />
      </FormGroup>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
        onClick={() => handleEditBed(bed.code, singleBedCode, isVirtual, isOncologyChair)}
      >
        <SaveIcon className={classes.editUnitIcon} />
        <p className={classes.editUnitP}>Guardar Cambios</p>
      </div>
    </div>
  )
}

export default EditBedComponent