import React, { useState, useEffect } from "react"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { deleteSubservice, getSubServices } from "../../services/architechture"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteSubserviceModal = ({
  open,
  onClose,
  subserviceInfo,
  setSubservices,
  onActivePopUp,
  setOpenPopUpDeleteSubservice,
}) => {
  const classes = useStyles()
  const { name, subServiceId, unitId, areaId, floorId, serviceId } = subserviceInfo

  const handleDeleteSubservice = async () => {
    try {
      const resp = await deleteSubservice(subServiceId)
      if (resp) {
        const newSubservices = await getSubServices(unitId, areaId, floorId, serviceId)
        setSubservices(newSubservices)
      }
      onActivePopUp(setOpenPopUpDeleteSubservice, true)
      onClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.deleteModal}
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <div className={classes.paperChild}>
            <div>{warningSvg}</div>

            <p className={classes.deleteText}>
              ¿Desea borrar el subservicio <span className={classes.deleteTextSpan}>{name}</span>?
            </p>

            <small className={classes.small1}>Si borra el subservicio, se eliminará todo lo que contenga.</small>
            <div className={classes.deleteButtonContainer}>
              <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                NO
              </Button>

              <Button variant="contained" className={classes.deleteButton} onClick={() => handleDeleteSubservice()}>
                SI, BORRAR SUBSERVICIO
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteSubserviceModal
