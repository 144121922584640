export const handleTypeChart = (typeReport)=>{ 

    switch (typeReport) {
        case 1:
            return  'historical'
        case 2:
            return  'not-available'
        case 3:
            return  'average-not-available'
        case 4:
            return 'average-available'
        case 5:
            return 'professional'
        case 6:
            return 'floor'
    
        default:
            return ''
    }
}