import React, { useState, useEffect } from "react"

import { Modal, Backdrop, Button } from "@material-ui/core"
import { format } from "date-fns"
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import { putBusyBeds, getBusyBeds } from "../../services/beds"

import useStyles from "./styles"

export default function DischargeDateModal({ open, onClose, stateId, code, admissionCode, reload }) {
  const classes = useStyles()
  const [dischargeDate, setDischargeDate] = useState(null)

  const handleFormatDate = (date) => {
    const formatDate = format(new Date(date), "yyyy-MM-dd HH:mm:ss")
    return formatDate
  }

  const loadProcessBeds = async () => {
    try {
      const resp = await getBusyBeds(code, admissionCode)
      setDischargeDate(!resp.dischargeDate ? null : handleFormatDate(resp.dischargeDate))
    } catch (e) {
      console.log(e)
    }
  }

  const saveProcessBusy = async () => {
    try {
      const resp = await putBusyBeds(code, admissionCode, {
        dischargeDate: dischargeDate,
      })
      loadProcessBeds()
      reload()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stateId === 3) {
      loadProcessBeds()
    }
  }, [])

  const handleDropReserve = async () => {
    const resp = await putBusyBeds(code, admissionCode, {
      dischargeDate: null,
    })
    if (resp === "discharge info bed updated") {
      const resp = await getBusyBeds(code, admissionCode)
      setDischargeDate(!resp.dischargeDate ? null : handleFormatDate(resp.dischargeDate))
      reload()
      onClose()
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <form className={classes.form}>
            <div className={classes.reserve}>
              <h4 className={classes.titleReserve}>Fecha próxima de alta</h4>
              {stateId === 3 && (
                <div>
                  <div className={classes.contentPicker}>
                    <KeyboardDateTimePicker
                      inputVariant="outlined"
                      value={dischargeDate}
                      onChange={(date) => setDischargeDate(handleFormatDate(date))}
                      label="Fecha proxima de alta"
                      format="dd/MM/yyyy hh:mm a"
                    />
                  </div>
                </div>
              )}
              <div className={classes.buttons}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Button variant="outlined" color="primary" className={classes.buttonCancel} onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button color="primary" className={classes.buttonAccept} onClick={stateId === 3 && saveProcessBusy}>
                    Aceptar
                  </Button>
                </div>
                {dischargeDate && (
                  <div>
                    <Button color="primary" className={classes.dropReserve} onClick={handleDropReserve}>
                      Eliminar fecha de alta
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
