import { handleDifDays } from "./handleDifDays";
import { handleTypeChart } from "./handleTypeChart";
import { format } from "date-fns"

export const handleGraphparams = (filterHistory)=>{ 

    const formated = filterHistory.idUnits.length !== 0 ? filterHistory.idUnits.map(element => element.unitId).join(',') : ''
    let typeShow = handleDifDays(new Date(filterHistory.from), new Date(filterHistory.to))

    let paramsGraph = { 
    }

    let paramsExcel = { 
    }

    switch (filterHistory.typeReport) {
        case 1:
            paramsGraph = { 
                "show": typeShow,
                "destiny": "graphic",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
    
            paramsExcel = { 
                "show": "byDate",
                "destiny": "excel",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
            break;
        case 5:
            paramsGraph = { 
                "destiny": "graphic",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
    
            paramsExcel = { 
                "destiny": "excel",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
            break;
        case 6:
            paramsGraph = { 
                "destiny": "graphic",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
    
            paramsExcel = { 
                "destiny": "excel",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
            break;
    
        default:
            paramsGraph = { 
                "show": typeShow,
                "destiny": "excel",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
    
            paramsExcel = { 
                "show": "byDate",
                "destiny": "excel",
                "type": handleTypeChart(filterHistory.typeReport),
                "units": formated,
                "dateFrom": format(new Date(filterHistory.from), "dd-MM-yyyy"),
                "dateTo": format(new Date(filterHistory.to), "dd-MM-yyyy")
            }
            break;
    }

    return { paramsExcel, paramsGraph }

}