import React, { useEffect, useState } from 'react'
import { Axis, Chart, Coordinate, Interval } from 'bizcharts'
import { Typography } from '@material-ui/core'
import { handleTypeTime } from '../../../../utils/handleTypeTime'

export const HistoricalMedicPlanAttendant = ({ data }) => {

    const [ itemsParse, setItemParse] =  useState([])
    
    const handleParseData = ()=>{ 

        if( data.length !== 0 ){
            const arrParse = data.map( element => {
                let max10Doctors = element.max10Doctors.map(doctor => ({ 
                    nombre: doctor.name,
                    mediana: doctor.median
                }))

                let min10Doctors = element.min10Doctors.map(doctor => ({ 
                    nombre: doctor.name,
                    mediana: doctor.median
                }))
                
                return { 
                    max10Doctors: [...max10Doctors],
                    min10Doctors: [...min10Doctors],
                    unitName: element.unitName
                }
            })
            setItemParse(arrParse)
        }

    }

    useEffect(()=>{ 
        handleParseData()
    },[])
     
    const scale = {
        // "Porcentaje Ocupadas": { max: maxMinValues.max + 10},
        fecha: {
            type:"cat",
			range: [0, 0.98]
		}
    }

  return (
    <div style={{ width:'100%', border:'1px solid #CBD0D4' }}>
        <Typography style={{ fontSize: 20, color:'#535461', padding:'10px 0px 0px 40px', fontWeight:700 }}> Histórico de atenciones por médico </Typography>
        {
           itemsParse.length !== 0 && itemsParse.map( (element, index) => ( 
            <div>
                <Typography style={{ fontSize: 18, color:'#535461', padding:'10px 0px 0px 40px', fontWeight: 700 }}> {element.unitName} </Typography>
                <div style={{ width: '100%', display:'flex'}}>
                    <div style={{ width:'50%', display:'flex', flexDirection:'column' }}>
                        <Typography style={{ fontSize: 15, color:'#535461', padding:'10px 0px 0px 40px', fontWeight:500 }}> 10 Médicos con mayor número de días de estadía </Typography>
                        <Chart key={itemsParse} scale={scale} padding={[20, 90, 60, 200]} autoFit height={400} data={element.max10Doctors} interactions={['element-active']}>
                            <Coordinate transpose />
                            <Interval color={'#7DCEA0'} position="nombre*mediana" />
                            <Axis name="nombre" label={{ style:{ fontSize:10 }}}/>
                        </Chart>
                    </div>
                    <div style={{ width:'50%', display:'flex', flexDirection:'column' }}>
                        <Typography style={{ fontSize: 15, color:'#535461', padding:'10px 0px 0px 40px', fontWeight:500 }}> 10 Médicos con menor número de días de estadía </Typography>
                        <Chart key={itemsParse} scale={scale} padding={[20, 60, 60, 150]} autoFit height={400} data={element.min10Doctors} interactions={['element-active']}>
                            <Coordinate transpose />
                            <Interval color={'#7DCEA0'} position="nombre*mediana" />
                            <Axis name="nombre" label={{ style:{ fontSize:10 }}}/>
                        </Chart>
                    </div>

                </div>
            </div>

            ))
        }
    </div>
  )
}
