import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  nameService: {
    color: "#4CAF50",
    width: "100%",
    margin: "2rem 0 1rem 0",
    fontSize: "1.2rem",
  },
  contentBedsGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  divider: {
    marginBottom: "3rem",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: "2.3rem",
    color: "#566573",
    margin: "2rem 0",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      margin: "0 0 1rem",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  tableServices: {
    margin: "3rem 0",
  },
  messagge: {
    margin: "0.5rem 0 0 0.5rem",
    color: "#ff0000",
    fontSize: "1rem",
    fontWeight: "600",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    backgroundColor: "#009DB1",
    marginRight: "2rem",
    marginBottom: "0.5rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
    textTransform: "none",
  },
  csvDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export default useStyles
