
export const handleExcelName = ( typeReport)=>{
    let sheetName = ''
    let fileName = ''
    
    switch (typeReport) {
        case 1:
            sheetName = 'Análisis por estado de camas'
            fileName = 'Análisis por estado de camas'
            return  {sheetName, fileName}
        case 2:
            sheetName = 'Dias Cama ocupados totales'
            fileName = 'Dias Cama ocupados totales'
            return  {sheetName, fileName}
        case 3:
            sheetName = 'Ocupación días camas'
            fileName = 'Ocupación días camas'
            return  {sheetName, fileName}
        case 4:
            sheetName = 'camas disponibles-totales'
            fileName = 'Promedio de Camas disponibles totales'
            return  {sheetName, fileName}
        case 5:
            sheetName = 'historico media medico'
            fileName = 'Histórico de atenciones por médico'
            return  {sheetName, fileName}
        case 6:
            sheetName = 'historico media por piso'
            fileName = 'Histórico de días de estadía por plan de salud'
            return  {sheetName, fileName}
    
        default:
            return  {sheetName, fileName}
    }

    return
}