export const handleDifDays = (from, to)=>{ 

    let difMilliSeconds = to - from;

    let difDays = Math.floor(difMilliSeconds / (1000 * 60 * 60 * 24))
    
    switch (true) {
        case difDays < 30:
            return 'byDate'
        case difDays >= 30 && difDays < 365 :
            return 'byMonth'
        case difDays >= 365:
            return 'byYear'
    
        default:
            return 'byDate'
    }
}