import React, { useContext, useState, useEffect } from "react"

// INTERNAL COMPONENTS
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { a11yProps, TabPanel } from "../Sheets"
import { architechtureStyles } from "../../utils/architechtureStyles"
import Card from "../../components/CardArchitectureBeds"
import {
  getUnit,
  getAreas,
  getSingleArea,
  createSingleArea,
  createSingleFloor,
  editArea,
} from "../../services/architechture"
import NewArea from "../../components/NewArea"
import PopUp from "../../components/PopUp"
import { svg } from "../../utils/svgHelper"
import InfraestructureHeader from "../../components/InfraestructureHeader"
import EditAreaInput from "../../components/EditAreaInput"
import DeleteAreaModal from "../../components/DeleteAreaModal"

// EXTERNAL COMPONENTS
import {
  makeStyles,
  Button,
  Tabs,
  Tab,
  Divider,
  Input,
  Checkbox,
  FormControlLabel,
  withStyles,
} from "@material-ui/core"

import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

export const GreenCheckbox = withStyles({
  root: {
    color: "#566573",
    "&$checked": {
      color: "#566573",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const AddFloorCard = ({
  handleFloorChange,
  handleSubmitFloor,
  handleChangeCheck,
  newFloor,
  checked,
  setAddFloor,
  floor,
  floorError,
  setFloorError,
  editGeneralInput,
}) => {
  const [hover, setHover] = useState(false)

  const classes = useStyles()
  return (
    <>
      {!floor ? (
        <div className={classes.card} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <div
            className={hover ? classes.contentCode2 : classes.contentCode}
            onClick={() =>
              editGeneralInput.floor.state ||
              editGeneralInput.service.state ||
              editGeneralInput.unit.state ||
              editGeneralInput.area.state
                ? null
                : setAddFloor(true)
            }
          >
            <p className={classes.textData1}>
              + <span className={classes.textDataSpan}>AGREGAR PISO</span>
            </p>
          </div>
          <Divider />
          <div className={classes.addServiceContent}></div>
        </div>
      ) : (
        <div className={classes.card} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <div className={floorError ? classes.floorError : classes.contentCode3}>
            <p className={classes.textData2}>
              <Input
                value={newFloor}
                className={classes.newFloorInput}
                autoFocus
                disableUnderline
                placeholder="Nombre de Piso"
                onChange={(e) => handleFloorChange(e)}
              />
            </p>
            <CloseIcon
              className={classes.editUnitIcon}
              onClick={() => {
                setFloorError(false)
                setAddFloor(false)
              }}
            />
          </div>
          <Divider />
          <div className={classes.addServiceContent}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={<GreenCheckbox checked={checked} onChange={handleChangeCheck} name="checkedG" />}
              label="Piso de Urgencia"
            />
          </div>

          <div className={classes.saveIconContainer2} onClick={() => handleSubmitFloor()}>
            <SaveIcon className={classes.editUnitIcon} />
            <p className={classes.editUnitP}>Guardar Cambios</p>
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const Architecture = () => {
  const classes = useStyles()
  const { unit, addUnit, areas, setAreas, setEditGeneralInput, editGeneralInput } = useContext(DashboardUnit)
  const [singleUnit, setSingleUnit] = useState("")
  const [singleUnitUrl, setSingleUnitUrl] = useState("")
  const [unitApi, setUnitApi] = useState("")
  const [value, setValue] = useState(0)
  const [valueEditArea, setValueEditArea] = useState("")
  const [createNewArea, setCreateNewArea] = useState(false)
  const [okEdit, setOkEdit] = useState(false)
  const [addArea, setAddArea] = useState("")
  const [openPopUp, setOpenPopUp] = useState(false)
  const [openPopUpEditUnit, setOpenPopUpEditUnit] = useState(false)
  const [openPopUpEditArea, setOpenPopUpEditArea] = useState(false)
  const [openPopUpEditFloor, setOpenPopUpEditFloor] = useState(false)
  const [openPopUpEditService, setOpenPopUpEditService] = useState(false)
  const [openPopUpDeleteArea, setOpenPopUpDeleteArea] = useState(false)
  const [openPopUpDeleteFloor, setOpenPopUpDeleteFloor] = useState(false)
  const [openPopUpDeleteService, setOpenPopUpDeleteService] = useState(false)
  const [openPopUpError, setOpenPopUpError] = useState(false)
  const [openPopUpFloor, setOpenPopUpFloor] = useState(false)
  const [openPopUpFloorError, setOpenPopUpFloorError] = useState(false)
  const [floors, setFloors] = useState([])
  const [floor, setAddFloor] = useState(false)
  const [editFloorInput, setEditFloorInput] = useState(false)
  const [editServiceInput, setEditServiceInput] = useState(false)
  const [okFloor, setOkFloor] = useState(false)
  const [areaId, setAreaId] = useState(null)
  const [newFloor, setNewFloor] = useState("")
  const [checked, setChecked] = useState(false)
  const [hover, setHover] = useState(null)
  const [openAreaModal, setOpenAreaModal] = useState(false)
  const [selectedArea, setSelectedArea] = useState({})
  const [error, setError] = useState(false)

  // ERROR IN AREA CREATION
  const [areaError, setAreaError] = useState(false)

  // ERROR IN FLOOR CREATION
  const [floorError, setFloorError] = useState(false)

  const onOpenAreaModal = () => {
    setOpenAreaModal(true)
  }

  const onCloseAreaModal = () => {
    setOpenAreaModal(false)
  }

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked)
  }

  const handleFloorChange = (e) => {
    setFloorError(false)
    const value = e.target.value
    setNewFloor(value)
  }

  const getSingleUnit = async () => {
    try {
      const { name, infoUrl } = await getUnit(unit)
      setSingleUnit(name)
      setUnitApi(infoUrl)
    } catch (e) {
      console.log(e)
    }
  }

  const getIndividualArea = async () => {
    try {
      if (unit && areaId) {
        const resp = await getSingleArea(unit, areaId)
        setFloors(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAllAreas = async () => {
    try {
      const resp = await getAreas(unit)
      setAreas(resp)
      setAreaId(resp[0]?.areaId)
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeCreateArea = (e) => {
    setAreaError(false)
    const value = e.target.value
    setAddArea(value.toUpperCase())
  }

  const handleClickDischargeChanges = async () => {
    const resp = await getSingleArea(unit, areas[0].areaId)
    setFloors(resp)
    setCreateNewArea(false)
    setValue(0)
    setAddArea("")
    setAreaError(false)
  }

  useEffect(() => {
    getIndividualArea()
    setAddFloor(false)
    setOkFloor(false)
  }, [areaId, editFloorInput])

  useEffect(() => {
    getSingleUnit()
    getAllAreas()
  }, [unit])

  const handleClickTab = (areaId) => {
    setAreaId(areaId)
    setCreateNewArea(false)
    setError(false)
  }

  const onOpenPopUp = () => {
    setOpenPopUp(true)
    setTimeout(() => setOpenPopUp(false), 5000)
  }

  const onClosePopUp = () => {
    setOpenPopUp(false)
  }
  const onOpenPopUpError = () => {
    setOpenPopUpError(true)
    setTimeout(() => setOpenPopUpError(false), 5000)
  }
  const onClosePopUpError = () => {
    setOpenPopUpError(false)
  }

  const onClosePopUpFloor = () => {
    setOpenPopUpFloor(false)
  }
  const onOpenPopUpFloor = () => {
    setOpenPopUpFloor(true)
    setTimeout(() => setOpenPopUpFloor(false), 5000)
  }

  const onOpenPopUpFloorError = () => {
    setOpenPopUpFloorError(true)
    setTimeout(() => setOpenPopUpFloorError(false), 5000)
  }
  const onClosePopUpFloorError = () => {
    setOpenPopUpFloorError(false)
  }
  const onOpenPopUpEditUnit = () => {
    setOpenPopUpEditUnit(true)
    setTimeout(() => setOpenPopUpEditUnit(false), 5000)
  }
  const onClosePopUpEditUnit = () => {
    setOpenPopUpEditUnit(false)
  }
  const onOpenPopUpEditArea = () => {
    setOpenPopUpEditArea(true)
    setTimeout(() => setOpenPopUpEditArea(false), 5000)
  }
  const onClosePopUpEditArea = () => {
    setOpenPopUpEditArea(false)
  }
  const onOpenPopUpEditFloor = () => {
    setOpenPopUpEditFloor(true)
    setTimeout(() => setOpenPopUpEditFloor(false), 5000)
  }
  const onClosePopUpEditFloor = () => {
    setOpenPopUpEditFloor(false)
  }
  const onOpenPopUpEditService = () => {
    setOpenPopUpEditService(true)
    setTimeout(() => setOpenPopUpEditService(false), 5000)
  }
  const onClosePopUpEditService = () => {
    setOpenPopUpEditService(false)
  }
  const onOpenPopUpDeleteArea = () => {
    setOpenPopUpDeleteArea(true)
    setTimeout(() => setOpenPopUpDeleteArea(false), 5000)
  }
  const onClosePopUpDeleteArea = () => {
    setOpenPopUpDeleteArea(false)
  }
  const onOpenPopUpDeleteFloor = () => {
    setOpenPopUpDeleteFloor(true)
    setTimeout(() => setOpenPopUpDeleteFloor(false), 5000)
  }
  const onClosePopUpDeleteFloor = () => {
    setOpenPopUpDeleteFloor(false)
  }
  const onClosePopUpDeleteService = () => {
    setOpenPopUpDeleteService(false)
  }
  const onOpenPopUpDeleteService = () => {
    setOpenPopUpDeleteService(true)
    setTimeout(() => onClosePopUpDeleteService(), 5000)
  }
  const handleSubmitArea = async () => {
    if (!addArea.length) setAreaError(true)
    if (addArea) {
      setFloors([])
      const resp = await createSingleArea({ name: addArea, unitId: unit })
      if (resp.data) {
        onOpenPopUpError()
      } else {
        setAreaId(resp.areaId)
        setCreateNewArea(false)
        onOpenPopUp()
        setAreas([...areas, resp])
        setAddArea("")
      }
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleSubmitFloor = async (data) => {
    if (!data.name) setFloorError(true)
    if (data.name) {
      const resp = await createSingleFloor(data)
      setFloorError(false)
      if (resp.data) {
        onOpenPopUpFloorError()
      } else {
        const addedFloors = await getSingleArea(unit, areaId)
        setFloors(addedFloors)
        setChecked(false)
        setNewFloor("")
        setAddFloor(false)
        onOpenPopUpFloor()
      }
    }
  }

  const handleEditArea = async () => {
    try {
      const areaEditReps = await editArea(areaId, { name: valueEditArea })
      setEditGeneralInput({ ...editGeneralInput, area: { ...editGeneralInput.area, state: false } })
      onOpenPopUpEditArea()
      getAllAreas()
      setValue(0)
    } catch (e) {
      console.log(e)
    }
  }

  const editSingleAres = ({ areaId, name }, boolean) => {
    handleClickTab(areaId)
    setEditGeneralInput({ ...editGeneralInput, area: { name, state: boolean } })
  }

  return (
    <>
      <InfraestructureHeader
        unit={unit}
        addUnit={addUnit}
        singleUnit={singleUnit}
        flag={true}
        createNewArea={createNewArea}
        setSingleUnit={setSingleUnit}
        onOpenPopUpEditUnit={onOpenPopUpEditUnit}
        unitApi={unitApi}
        setUnitApi={setUnitApi}
        singleUnitUrl={singleUnitUrl}
        setSingleUnitUrl={setSingleUnitUrl}
      />

      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor={editGeneralInput.area.state ? "red" : "secondary"}
        style={{ display: !areas.length ? "none" : null }}
      >
        {areas?.map((x, i) => (
          <div
            onMouseEnter={() => setHover(i)}
            onMouseLeave={() => setHover(null)}
            style={{ height: "6rem" }}
            onClick={() =>
              createNewArea ||
              editGeneralInput.floor.state ||
              editGeneralInput.service.state ||
              editGeneralInput.unit.state
                ? null
                : setValue(i)
            }
          >
            {!createNewArea &&
            !editGeneralInput.area.state &&
            !editGeneralInput.floor.state &&
            !editGeneralInput.unit.state &&
            !editGeneralInput.service.state ? (
              <div className={classes.iconMainContainer2}>
                {hover === i && (
                  <div className={classes.iconContainer}>
                    <EditIcon onClick={() => editSingleAres(x, true)} />
                    <DeleteIcon
                      onClick={() => {
                        setError(false)
                        setSelectedArea(x)
                        handleClickTab(x.areaId)
                        onOpenAreaModal()
                        editSingleAres(x, false)
                      }}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {editGeneralInput.area.state && editGeneralInput.area.name === x.name ? (
              <EditAreaInput areaName={x.name} setValueEditArea={setValueEditArea} />
            ) : (
              <Tab
                className={classes.tab}
                disabled={
                  createNewArea ||
                  editGeneralInput.area.state ||
                  editGeneralInput.floor.state ||
                  editGeneralInput.service.state ||
                  editGeneralInput.unit.state
                }
                label={x.name}
                {...a11yProps(i)}
                onClick={() => handleClickTab(x.areaId)}
              />
            )}
          </div>
        ))}
        {areas.length && !editGeneralInput.area.state ? (
          <Tab
            label="+ AGREGAR ÁREA"
            {...a11yProps(2)}
            disabled={editGeneralInput.floor.state || editGeneralInput.service.state || editGeneralInput.unit.state}
            className={classes.tab}
            onClick={() => setCreateNewArea(true)}
            style={{ display: createNewArea && areas.length ? "none" : null }}
          />
        ) : (
          <div className={classes.editContainerV2}>
            <div
              className={classes.closeIconContainer}
              onClick={() =>
                setEditGeneralInput({ ...editGeneralInput, area: { ...editGeneralInput.area, state: false } })
              }
            >
              <CloseIcon className={classes.editUnitIcon} />
              <p className={classes.editUnitP}>Descartar Cambios</p>
            </div>
            <div className={classes.saveIconContainer6} onClick={() => handleEditArea()}>
              <SaveIcon className={classes.editUnitIcon} />
              <p className={classes.editUnitP}>Guardar Cambios</p>
            </div>
          </div>
        )}
        {createNewArea && areas.length ? (
          <>
            <div className={classes.areaAndEditContainer2}>
              <div className={areaError ? classes.areaContainerError : classes.areaContainer2}>
                <Input
                  className={classes.addAreaInput}
                  placeholder="NOMBRE DE ÁREA"
                  inputProps={{ "aria-label": "description" }}
                  disableUnderline={true}
                  onChange={(e) => handleChangeCreateArea(e)}
                  autoFocus
                  value={addArea}
                />
              </div>
              <div className={classes.editContainerV2}>
                <div className={classes.closeIconContainer} onClick={() => handleClickDischargeChanges()}>
                  <CloseIcon className={classes.editUnitIcon} />
                  <p className={classes.editUnitP}>Descartar Cambios</p>
                </div>

                <div className={classes.saveIconContainer} onClick={() => handleSubmitArea()}>
                  <SaveIcon className={classes.editUnitIcon} />
                  <p className={classes.editUnitP}>Guardar Cambios</p>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Tabs>

      {createNewArea && areas.length ? (
        <>
          <div className={classes.svgContainer}>
            <div>{svg}</div>
          </div>
          <div className={classes.textAndButtonContainer}>
            <p className={classes.textP}>Todavía no hay pisos en esta área</p>

            <div className={classes.addAreaButton}>
              <Button variant="contained" className={classes.addUnitIcon} startIcon={<AddIcon />} disabled>
                Agregar piso
              </Button>
            </div>
          </div>
        </>
      ) : null}

      {!areas.length ? <NewArea unit={unit} setAreaId={setAreaId} /> : null}

      {areas?.map((x, i) => {
        return (
          <TabPanel value={value} index={i}>
            <div className={classes.contentBedsGroup}>
              {!floors.length ? (
                <div className={!okFloor ? classes.svgMainContainer : classes.none}>
                  <div className={classes.svgContainer}>
                    <div>{svg}</div>
                  </div>
                  <div className={classes.textAndButtonContainer}>
                    <p className={classes.textP}>Todavía no hay pisos en esta área</p>
                    <div className={classes.addAreaButton}>
                      <Button
                        variant="contained"
                        className={classes.addUnitIcon}
                        startIcon={<AddIcon />}
                        onClick={() => setOkFloor(!okFloor)}
                      >
                        Agregar piso
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                floors?.map(({ floorId, areaId, name, unitId, urgencyFloor }, i) => (
                  <Card
                    urgencyFloor={urgencyFloor}
                    floorId={floorId}
                    areaId={areaId}
                    name={name}
                    unitId={unitId}
                    key={i}
                    floors={floors}
                    setFloors={setFloors}
                    setEditFloorInput={setEditFloorInput}
                    editFloorInput={editFloorInput}
                    editServiceInput={editServiceInput}
                    onOpenPopUpEditFloor={onOpenPopUpEditFloor}
                    onOpenPopUpEditService={onOpenPopUpEditService}
                    onOpenPopUpDeleteFloor={onOpenPopUpDeleteFloor}
                    onOpenPopUpDeleteService={onOpenPopUpDeleteService}
                    setOpenPopUpDeleteService={setOpenPopUpDeleteService}
                  />
                ))
              )}

              {floors.length || okFloor ? (
                <AddFloorCard
                  handleFloorChange={handleFloorChange}
                  handleSubmitFloor={() =>
                    handleSubmitFloor({ name: newFloor, unitId: Number(unit), areaId, urgencyFloor: checked ? 1 : 0 })
                  }
                  handleChangeCheck={handleChangeCheck}
                  newFloor={newFloor}
                  checked={checked}
                  floor={floor}
                  setAddFloor={setAddFloor}
                  setEditGeneralInput={setEditGeneralInput}
                  editGeneralInput={editGeneralInput}
                  setFloorError={setFloorError}
                  floorError={floorError}
                />
              ) : null}
            </div>
          </TabPanel>
        )
      })}
      <PopUp open={openPopUpFloor} onClose={onClosePopUpFloor} title="Piso agregado con éxito" />
      <PopUp open={openPopUp} onClose={onClosePopUp} title="Área agregada con éxito" />
      <PopUp open={openPopUpEditUnit} onClose={onClosePopUpEditUnit} title="Unidad editada con éxito" />
      <PopUp open={openPopUpEditArea} onClose={onClosePopUpEditArea} title="Área editada con éxito" />
      <PopUp open={openPopUpEditFloor} onClose={onClosePopUpEditFloor} title="Piso editado con éxito" />
      <PopUp open={openPopUpEditService} onClose={onClosePopUpEditService} title="Servicio editado con éxito" />
      <PopUp open={openPopUpDeleteArea} onClose={onClosePopUpDeleteArea} title="Área eliminada con éxito" />
      <PopUp open={openPopUpDeleteFloor} onClose={onClosePopUpDeleteFloor} title="Piso eliminado con éxito" />
      <PopUp open={openPopUpDeleteService} onClose={onClosePopUpDeleteService} title="Servicio eliminado con éxito" />
      <PopUp
        open={openPopUpFloorError}
        onClose={onClosePopUpFloorError}
        errorPopUp={true}
        title="Error al crear Piso, nombre repetido"
      />
      <PopUp
        open={openPopUpError}
        onClose={onClosePopUpError}
        errorPopUp={true}
        title="Error al crear área, nombre repetido"
      />
      <DeleteAreaModal
        open={openAreaModal}
        onClose={onCloseAreaModal}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        setAreaId={setAreaId}
        error={error}
        setError={setError}
        onOpenPopUpDeleteArea={onOpenPopUpDeleteArea}
      />
    </>
  )
}

export default Architecture
