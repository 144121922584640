import React from "react"
import { CustomContainer, Flexbox } from "../common"
import CardTable from "./CardTable"

export default function BasicTable({
  availableBedCount,
  blockedBedCount,
  busyBedCount,
  inProgressBedCount,
  totalBedCount,
  urgencyBedCount,
  reserveBedCount,
  virtualBedCount,
  inTransitBedCount,
  oncologyBedCount
}) {
  return (
    <CustomContainer lenght="0" marginTop="50px">
      <Flexbox flexDirection="row">
        <CardTable name="Totales" link="/bedslog" count={totalBedCount} />
        <CardTable name="Disponibles" link="/beds/1" count={availableBedCount} />
        <CardTable name="Bloqueadas" link="/beds/2" count={blockedBedCount} />
        <CardTable name="Ocupadas" link="/beds/3" count={busyBedCount} />
        <CardTable name="En recepción" link="/beds/4" count={inProgressBedCount} />
        <CardTable name="Reservadas" link="/reserves/-2" count={reserveBedCount} />
        <CardTable name="Virtuales" link="/beds/virtuals" count={virtualBedCount} />
        <CardTable name="En Tránsito" link="/beds/5" count={inTransitBedCount} />
        <CardTable name="Oncológico" link="/beds/oncology" count={oncologyBedCount} />
      </Flexbox>
    </CustomContainer>
  )
}
