import API from "../../utils/api"

const getHistoricalData = async (params) => {
  const path = `/historical/accumulated`
  return API.get(path, { params: { ...params }})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getHistoricalDataProfessional = async (params) => {
  const path = `/historical/professional`
  return API.get(path, { params: { ...params }})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getHistoricalDataFloor = async (params) => {
  const path = `/historical/floor`
  return API.get(path, { params: { ...params }})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export { getHistoricalData, getHistoricalDataProfessional, getHistoricalDataFloor }