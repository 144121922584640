import { useContext } from "react"
import CardBeds from "../../components/CardBeds"
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"

import ComercialBeds from "../../contexts/comercialBedsContext"

const useStyles = makeStyles({
  title: {
    marginBottom: "1.3rem",
    color: "#6E6E6E",
  },
  nameService: {
    color: "#4CAF50",
    width: "100%",
    margin: "2rem 0 1rem 0",
    fontSize: "1.6rem",
  },
  contentBedsGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  divider: {
    margin: "2rem 0 3rem 0",
  },
})

const ComercialBedsType = () => {
  const classes = useStyles()
  const { comercialBeds } = useContext(ComercialBeds)

  const { details, title } = comercialBeds
  return (
    <div>
      <div className={classes.nameService}>
        {title}
        <Divider className={classes.divider} />
      </div>
      <div className={classes.contentBedsGroup}>
        {details && details.map((bed, idx) => <CardBeds data={bed} key={idx} />)}
      </div>
    </div>
  )
}

export default ComercialBedsType
