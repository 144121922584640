import React, { useState } from 'react'
import { useCallback } from 'react'
import { getHistoricalData, getHistoricalDataFloor, getHistoricalDataProfessional } from '../../services/historical'

export const useGetExcelData = () => {

    const [excelData, setExcelData] = useState([])
    
    const handleGetExcelData = useCallback(async ( params )=>{ 

        if(params.type === 'professional'){

            const response = await getHistoricalDataProfessional(params)
            
            if(response){ 
                setExcelData(response)
            }

        }else if(params.type === 'floor'){

            const response = await getHistoricalDataFloor(params)
            
            if(response){ 
                setExcelData(response)
            }

        }else{

            const response = await getHistoricalData(params)
            
            if(response){ 
                setExcelData(response)
            }
        }
    },[])

    return { 
        excelData,
        setExcelData,
        handleGetExcelData
    }
}
