export const typeReportOption = [
    {
        id:1,
        text:'Análisis por estado de camas'
    },
    {
        id:2,
        text:'Dia Cama Ocupados Totales'
    },
    {
        id:3,
        text:'Ocupación días camas'
    },
    {
        id:4,
        text:'Promedio de Camas disponibles totales'
    },
    {
        id:5,
        text:'Histórico de atenciones por médico'
    },
    {
        id:6,
        text:'Histórico de días de estadía por plan de salud'
    }
]


export const clinicas = [ 
    {
        id: 1,
        text: 'VITACURA'
    },
    {
        id: 2,
        text: 'Providencia'
    },
    {
        id: 3,
        text: 'Santiago'
    },
    {
        id: 4,
        text: 'Elqui'
    },
]