import API from "../../utils/api"

const getBedsLog = async () => {
  const path = "/dashboard/beds/log"
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getBedsByState = (state) => {
  const path = `/dashboard/beds/states/${state}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getServiceById = (id) => {
  const path = `/dashboard/services/${id}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getUrgencyLaw = () => {
  const path = `/dashboard/urgencylaw`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getContingency = () => {
  const path = `/dashboard/contingency`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}
const getCommercialView = () => {
  const path = `/dashboard/commercial`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const getBusyBeds = (bed_code, admission_code) => {
  const path = `/dashboard/beds/${bed_code}/admissions/${admission_code}/discharge`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const getBeds = (bed_code) => {
  const path = `/dashboard/beds/${bed_code}/discharge`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const getReservesBeds = () => {
  const path = `/dashboard/beds/reserves`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const putBusyBeds = (bed_code, admission_code, data) => {
  const path = `/dashboard/beds/${bed_code}/admissions/${admission_code}/discharge`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const putBeds = (bed_code, data) => {
  const path = `/dashboard/beds/${bed_code}/discharge`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const getBblockedBedsReasson = (bed_code) => {
  const path = `/dashboard/beds/${bed_code}/reasson`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const editBblockedBedsReasson = (bed_code, data) => {
  const path = `/dashboard/beds/${bed_code}/reasson`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data))
}

const searchBedsForDashboard = (filter) => {
  const path = `/dashboard/beds/search`
  return API.post(path, filter)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getDataForCharts = (unitId) => {
  const path = `/dashboard/${unitId}/totals`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getVirtualBeds = () => {
  const path = "/dashboard/beds/virtuals"
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getOncologyBeds = () => {
  const path = "/dashboard/beds/oncology"
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getOcuppiedBeds = () => {
  const path = "/dashboard"
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export {
  getBedsLog,
  getBedsByState,
  getServiceById,
  getUrgencyLaw,
  getContingency,
  getCommercialView,
  getBusyBeds,
  searchBedsForDashboard,
  putBusyBeds,
  getDataForCharts,
  getReservesBeds,
  getBblockedBedsReasson,
  editBblockedBedsReasson,
  putBeds,
  getBeds,
  getVirtualBeds,
  getOcuppiedBeds,
  getOncologyBeds
}
