import React, { useState, useContext } from "react"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import DashboardUnit from "../../contexts/dashboardUnitContext"
// EXTERNAL COMPONENTS
import { makeStyles, Input } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"
import { editUnit, getUnit } from "../../services/architechture"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const EditUnitUrlInput = ({
  singleUnit,
  setEditGeneralInput,
  editGeneralInput,
  unit,
  setSingleUnitUrl,
  onOpenPopUpEditUnit,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(singleUnit)
  const { detailsUnit, setRefreshLabelUnitUrl, refreshLabelUnitUrl } = useContext(DashboardUnit)

  const handleChange = ({ target }) => {
    const { value } = target
    setValue(value)
    // setValue(value.toUpperCase())
  }

  const getSingleUnit = async () => {
    try {
      const { infoUrl } = await getUnit(unit)
      setSingleUnitUrl(infoUrl)
    } catch (e) {
      console.log(e)
    }
  }

  const handleEditUnit = async () => {
    try {
      const editResp = await editUnit(unit, { infoUrl: value })
      setEditGeneralInput({ ...editGeneralInput, unitApi: { state: false } })
      setSingleUnitUrl(editResp?.infoUrl)
      onOpenPopUpEditUnit()
      setRefreshLabelUnitUrl(!refreshLabelUnitUrl)
      getSingleUnit()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={classes.unitEditUrl}>
      <div className={classes.addUnitForm2}>
        <form noValidate autoComplete="off">
          <Input
            className={classes.addUnitInput}
            placeholder={!value ? "NOMBRE DE URL" : value}
            inputProps={{ "aria-label": "description" }}
            disableUnderline={true}
            onChange={(e) => handleChange(e)}
            autoFocus
            value={value}
          />
        </form>
      </div>
      <div className={classes.editContainer1}>
        <div
          className={classes.closeIconContainer1}
          onClick={() => setEditGeneralInput({ ...editGeneralInput, unitApi: { state: false } })}
        >
          <CloseIcon className={classes.editUnitIcon} />
          <p className={classes.editUnitP}>Descartar Cambios</p>
        </div>

        <div className={classes.saveIconContainer5} onClick={() => handleEditUnit()}>
          <SaveIcon className={classes.editUnitIcon} />
          <p className={classes.editUnitP}>Guardar Cambios</p>
        </div>
      </div>
    </div>
  )
}
export default EditUnitUrlInput
