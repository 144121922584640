import { useEffect, useState, useContext } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { getBedsLog } from "../../services/beds"
import { CSVLink } from "react-csv"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { expiredToken } from "../../utils/expiredToken"

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  { id: "areaName", numeric: false, disablePadding: true, label: "Area" },
  { id: "admissionId", numeric: false, disablePadding: false, label: "Admisión" },
  { id: "code", numeric: false, disablePadding: true, label: "Codigo" },
  { id: "bedLastChangedDate", numeric: false, disablePadding: false, label: "Ultimo Cambio" },
  { id: "complexityChangeCode", numeric: false, disablePadding: false, label: "Codigo Cambio" },
  { id: "floorName", numeric: false, disablePadding: false, label: "Nombre Piso" },
  { id: "patientDischargeDate", numeric: false, disablePadding: false, label: "Fecha Alta" },
  { id: "patientName", numeric: false, disablePadding: false, label: "Nombre Paciente" },
  { id: "prestationCode", numeric: false, disablePadding: false, label: "Prestacion" },
  { id: "professionalName", numeric: false, disablePadding: false, label: "Profesional" },
  { id: "roomCode", numeric: false, disablePadding: false, label: "Habitacion" },
  { id: "serviceName", numeric: false, disablePadding: false, label: "Servicio" },
  { id: "sex", numeric: false, disablePadding: true, label: "Sex" },
  { id: "strAge", numeric: false, disablePadding: false, label: "Edad" },
  { id: "subServiceName", numeric: false, disablePadding: false, label: "Sub servicio" },
  { id: "strStayTime", numeric: false, disablePadding: false, label: "Tiempo Estancia" },
  { id: "unitName", numeric: false, disablePadding: false, label: "Nombre Unidad" },
]

const EnhancedTableHead = (props) => {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80rem",
    margin: "auto",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusService: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2rem",
  },
  red: {
    background: "#F1948A",
  },
  green: {
    background: "#7DCEA0",
  },
  yellow: {
    background: "#F9E79F",
  },
  buttonCSV: {
    width: "100%",
    maxWidth: "12rem",
    marginTop: "5rem",
  },
  contentCSV: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "2rem",
  },
  linkCsv: {
    textDecoration: "none",
  },
}))

const BedsLog = () => {
  const classes = useStyles()
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("calories")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [detailsBeds, setDetailsBeds] = useState([])
  const [loading, setLoading] = useState(false)

  const { detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    getDetailsBedsLog()
  }, [detailsUnit])

  const getDetailsBedsLog = async () => {
    setLoading(true)
    try {
      const resp = await getBedsLog()
      const dataFormated = formatDataForTable(resp)
      setDetailsBeds(dataFormated)
    } catch (e) {
      console.log(e)
      if (e?.status === 401) {
        expiredToken()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, detailsBeds.length - page * rowsPerPage)

  const formatDataForTable = (d) => {
    const data = d.units
      .map((unit) =>
        unit.beds.map((bed) => ({
          admissionId: !bed.admissionId ? "-" : bed.admissionId,
          areaName: !bed.areaName ? "-" : bed.areaName,
          bedLastChangedDate: !bed.bedLastChangedDate ? "-" : bed.bedLastChangedDate,
          code: !bed.code ? "-" : bed.code,
          complexityChangeCode: !bed.complexityChangeCode ? "-" : bed.complexityChangeCode,
          floorName: !bed.floorName ? "-" : bed.floorName,
          patientDischargeDate: !bed.patientDischargeDate ? "-" : bed.patientDischargeDate,
          patientName: !bed.patientName ? "-" : bed.patientName,
          prestationCode: !bed.prestationCode ? "-" : bed.prestationCode,
          professionalName: !bed.professionalName ? "-" : bed.professionalName,
          roomCode: !bed.roomCode ? "-" : bed.roomCode,
          serviceName: !bed.serviceName ? "-" : bed.serviceName,
          sex: !bed.sex ? "-" : bed.sex,
          stateDescription: !bed.stateDescription ? "-" : bed.stateDescription,
          strAge: !bed.strAge ? "-" : bed.strAge,
          strStayTime: !bed.strStayTime ? "-" : bed.strStayTime,
          subServiceName: !bed.subServiceName ? "-" : bed.subServiceName,
          unitName: !bed.unitName ? "-" : bed.unitName,
        })),
      )
      .flat()
    return data
  }

  const headersCsv = [
    { key: "areaName", label: "Area" },
    { key: "admissionId", label: "Admisión" },
    { key: "code", label: "Codigo" },
    { key: "bedLastChangedDate", label: "Ultimo Cambio" },
    { key: "complexityChangeCode", label: "Codigo Cambio" },
    { key: "floorName", label: "Nombre Piso" },
    { key: "patientDischargeDate", label: "Fecha Alta" },
    { key: "patientName", label: "Nombre Paciente" },
    { key: "prestationCode", label: "Prestacion" },
    { key: "professionalName", label: "Profesional" },
    { key: "roomCode", label: "Habitacion" },
    { key: "serviceName", label: "Servicio" },
    { key: "sex", label: "Sex" },
    { key: "strAge", label: "Edad" },
    { key: "subServiceName", label: "Sub servicio" },
    { key: "strStayTime", label: "Tiempo Estancia" },
    { key: "unitName", label: "Nombre Unidad" },
  ]

  return (
    <div className={classes.root}>
      <div className={classes.contentCSV}>
        <CSVLink data={detailsBeds} headers={headersCsv} filename="ReporteLogCamas.csv" className={classes.linkCsv}>
          <Button type="submit" variant="contained" color="primary" className={classes.buttonCSV}>
            Descargar
          </Button>
        </CSVLink>
      </div>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={detailsBeds.length}
          />
          <TableBody>
            {stableSort(detailsBeds, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.areaName}
                    </TableCell>
                    <TableCell align="left">{row.admissionId}</TableCell>
                    <TableCell align="left">{row.bedLastChangedDate}</TableCell>
                    <TableCell align="left">{row.code}</TableCell>
                    <TableCell align="left">{row.complexityChangeCode}</TableCell>
                    <TableCell align="left">{row.floorName}</TableCell>
                    <TableCell align="left">{row.patientDischargeDate}</TableCell>
                    <TableCell align="left">{row.patientName}</TableCell>
                    <TableCell align="left">{row.prestationCode}</TableCell>
                    <TableCell align="left">{row.professionalName}</TableCell>
                    <TableCell align="left">{row.roomCode}</TableCell>
                    <TableCell align="left">{row.serviceName}</TableCell>
                    <TableCell align="left">{row.sex}</TableCell>
                    <TableCell align="left">{row.strAge}</TableCell>
                    <TableCell align="left">{row.subServiceName}</TableCell>
                    <TableCell align="left">{row.strStayTime}</TableCell>
                    <TableCell align="left">{row.unitName}</TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={detailsBeds.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default BedsLog
