import { useState, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
// EXTERNAL COMPONENTS
import {
  makeStyles,
  Button,
  Input,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core"
import clsx from "clsx"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"
import AddIcon from "@material-ui/icons/Add"
import SearchIcon from "@material-ui/icons/Search"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { svg } from "../../utils/svgHelper"
import PopUp from "../PopUp"
import { createUnit } from "../../services/architechture"
import DashboardUnit from "../../contexts/dashboardUnitContext"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const NewUnit = () => {
  const history = useHistory()
  const classes = useStyles()
  const { setUnit, unit } = useContext(DashboardUnit)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [unitName, setUnitName] = useState("")
  const [unitApi, setUnitApi] = useState("")
  const [openPopUpUnitError, setOpenPopUpUnitError] = useState(false)

  // CREATION ERROR
  const [unitError, setUnitError] = useState(false)

  const location = history.location.pathname === "/architecture/addUnit"

  const handleChange = (e) => {
    setUnitError(false)
    const value = e.target.value
    setUnitName(value.toUpperCase())
  }

  const handleChangeApi = ({ target }) => {
    setUnitError(false)
    const { value } = target
    setUnitApi(value)
  }

  const onOpenPopUp = () => {
    setOpenPopUp(true)
  }
  const onClosePopUp = () => {
    setOpenPopUp(false)
  }
  const onClosePopUpUnitError = () => {
    setOpenPopUpUnitError(false)
  }
  const onOpenPopUpUnitError = () => {
    setOpenPopUpUnitError(true)
  }

  const handleClickSaveUnit = async () => {
    if (!unitName.length) setUnitError(true)
    if (!unitApi.length) setUnitError(true)
    if (unitName && unitApi) {
      const resp = await createUnit({ name: unitName, infoUrl: unitApi })
      if (resp.data) {
        onOpenPopUpUnitError()
      } else {
        setUnit(resp.id)
        onOpenPopUp()
        if (resp.id) {
          setTimeout(() => {
            history.push(`/architecture/${resp.id}`)
          }, 2001)
        }
      }
    }
  }

  return (
    <>
      <div className={classes.header}>
        <div>
          <Typography variant="h4" className={classes.title}>
            Administrador de Unidades
          </Typography>
        </div>

        <div className={classes.container}>
          <div>
            <FormControl variant="outlined" className={classes.formControl} size="small" style={{ display: "none" }}>
              <InputLabel id="selected-filter-label">Filtrar por</InputLabel>
              <Select labelId="selected-filter-label" id="selected-filter" value="" onChange="" label="Filtrar por">
                <MenuItem value="primerItem">Primer Item</MenuItem>
                <MenuItem value="segundoItem">Segundo Item</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              size="small"
              style={{ display: "none" }}
            >
              <InputLabel htmlFor="search-input-label">Buscar</InputLabel>
              <OutlinedInput
                id="search-input-label"
                type="text"
                value=""
                onChange=""
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                labelWidth={45}
                disabled=""
              />
            </FormControl>
          </div>
          <div className={classes.contentAdd}>
            {location ? (
              <Button variant="contained" className={classes.iconAdd} startIcon={<AddIcon />} disabled={location}>
                Agregar unidad
              </Button>
            ) : (
              <Link to="/architecture/addUnit" className={classes.addUnitLink}>
                <Button variant="contained" className={classes.iconAdd} startIcon={<AddIcon />} disabled={location}>
                  Agregar unidad
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className={unitError ? classes.unitContainerError : classes.unitContainer3}>
        <div className={classes.addUnitForm}>
          <form autoComplete="off">
            <Input
              className={classes.addUnitInput}
              placeholder="NOMBRE DE UNIDAD"
              inputProps={{ "aria-label": "description" }}
              disableUnderline={true}
              onChange={(e) => handleChange(e)}
              autoFocus
              value={unitName}
            />
          </form>
        </div>
        <div className={classes.editContainer4}>
          <Link to={`/architecture/${unit}`} className={classes.addUnitLink1}>
            <div className={classes.closeIconContainer1} onClick={() => setUnitError(false)}>
              <CloseIcon className={classes.editUnitIcon} />
              <p className={classes.editUnitP}>Descartar Cambios</p>
            </div>
          </Link>
        </div>
      </div>
      <div className={unitError ? classes.unitContainerError : classes.unitContainer4}>
        <div className={classes.addUnitForm}>
          <form autoComplete="off">
            <Input
              className={classes.addUnitInput}
              placeholder="URL DE LA API"
              inputProps={{ "aria-label": "description" }}
              disableUnderline={true}
              onChange={(e) => handleChangeApi(e)}
              autoFocus
              value={unitApi}
            />
          </form>
        </div>
        <div className={classes.editContainer4}>
          <div className={classes.saveIconContainer} onClick={() => handleClickSaveUnit()}>
            <SaveIcon className={classes.editUnitIcon} />
            <p className={classes.editUnitP}>Guardar Cambios</p>
          </div>
        </div>
      </div>
      <div className={classes.svgContainer}>
        <div>{svg}</div>
      </div>

      <div className={classes.textAndButtonContainer}>
        <p className={classes.textP}>Todavía no hay áreas en esta unidad</p>

        <div className={classes.addAreaButton}>
          <Button variant="contained" className={classes.addUnitIcon} startIcon={<AddIcon />} disabled>
            Agregar área
          </Button>
        </div>
      </div>
      <PopUp
        open={openPopUpUnitError}
        onClose={onClosePopUpUnitError}
        errorPopUp={true}
        title="Error al crear Unidad, nombre repetido"
      />
      <PopUp open={openPopUp} onClose={onClosePopUp} title="Unidad agregada con éxito" />
    </>
  )
}

export default NewUnit
