import { useEffect, useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { getDetailsAllUsers, editDetailsUser, getRolesUser, deleteUser } from "../../services/users"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import AuthContext from "../../contexts/authContext"
import { expiredToken } from "../../utils/expiredToken"
import { Typography } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"

const headCells = [
  { id: "email", numeric: false, disablePadding: true, label: "Email" },
  { id: "erol", numeric: false, disablePadding: false, label: "Rol" },
  { id: "unitName", numeric: false, disablePadding: false, label: "Unidad" },
  { id: "lasLoginDuration", numeric: false, disablePadding: true, label: "Duracion Login" },
  { id: "lastLoginDate", numeric: false, disablePadding: false, label: "Ultimo Login" },
  { id: "loginType", numeric: false, disablePadding: false, label: "Tipo de Login" },
  { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
  { id: "select", numeric: false, disablePadding: false, label: "Cambiar Rol" },
  { id: "units", numeric: false, disablePadding: false, label: "Unidades" },
  { id: "delete", numeric: false, disablePadding: false, label: "Eliminar Usuario" },
]

const EnhancedTableHead = () => {
  const classes = useStyles()
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal" className={classes.cellHead}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80rem",
    margin: "auto",
  },
  table: {
    minWidth: 750,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  title: {
    fontSize: "2.3rem",
    color: "#566573",
    margin: "2rem 0 3rem 0",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      margin: "0 0 1rem",
    },
  },
  cellHead: {
    fontSize: "1rem",
    fontWeight: "600",
  },
}))

const UsersAdministration = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [detailsUsers, setDetailsUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [rolSelected, setRolSelected] = useState({})
  const [unitSelected, setUnitSelected] = useState({})

  const [editData, setEditData] = useState({
    id: null,
    rol: null,
    unitId: null,
  })

  const [roles, setRoles] = useState([])
  const { setDateUpdate, detailsUnit } = useContext(DashboardUnit)
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    getRoles()
    getDetailsUsers()
  }, [detailsUnit])

  const getDetailsUsers = async () => {
    setLoading(true)
    try {
      const resp = await getDetailsAllUsers()

      setDetailsUsers(resp.users)
      setRolSelected(handleInitialize(resp.users))
      setUnitSelected(handleInitialize(resp.users))
    } catch (e) {
      console.log(e)
      if (e?.status === 401) {
        expiredToken()
      }
    } finally {
      setLoading(false)
    }
  }

  const getRoles = async () => {
    setLoading(true)
    try {
      const resp = await getRolesUser()

      setRoles(resp)
    } catch (e) {
      console.log(e)
      if (e?.status === 401) {
        expiredToken()
      }
    } finally {
      setLoading(false)
    }
  }

  const editRolUser = async (data) => {
    setLoading(true)
    try {
      const resp = await editDetailsUser(data)

      getDetailsUsers()
    } catch (e) {
      console.log(e)
      if (e?.status === 401) {
        expiredToken()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleInitialize = (data) => {
    const result = data.reduce((a, obj) => {
      let rObj = { ...a }
      rObj[obj.id] = null
      return rObj
    }, {})
    return result
  }

  useEffect(() => {
    const rolesWithUnits = ["2", "3", "4"]
    const withUnit = rolesWithUnits.includes(`${editData.rol}`)
    if (withUnit) {
      if (!!editData.id && !!editData.rol && !!editData.unitId) {
        editRolUser(editData)
      }
    } else {
      if (editData.rol === 0) editRolUser(editData)
      if (!!editData.id && !!editData.rol) {
        editRolUser(editData)
      }
    }
  }, [editData])

  const handleChangeRol = (event) => {
    setRolSelected({ ...rolSelected, [event.target.name]: event.target.value })
    setEditData({ ...editData, id: event.target.name, rol: event.target.value })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, detailsUsers.length - page * rowsPerPage)

  const rolesFormat = {
    ROLE_NONE: "Sin Rol",
    ROLE_SUPER_ADMIN: "Superadmin",
    ROLE_ADMIN_FILIAL: "Admin Filial",
    ROLE_SUPERVISOR_FILIAL: "Supervisor Filial",
    ROLE_ADMINISTRATIVO_FILIAL: "Administrativo Filial",
    ROLE_VISUALIZA_RED_SALUD: "Visualiza Red Salud",
  }

  const handleChangeUnit = (event) => {
    setUnitSelected({ ...unitSelected, [event.target.name]: event.target.value })
    setEditData({ ...editData, id: event.target.name, unitId: event.target.value })
  }

  const RolesItems = () => {
    return roles.map((rol) => (
      <MenuItem value={rol.id} key={rol.id}>
        {rolesFormat[rol.name]}
      </MenuItem>
    ))
  }

  const handleDeleteUser = async (id) => {
    await deleteUser(id)
    const resp = await getDetailsAllUsers()
    setDetailsUsers(resp.users)
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4" className={classes.title}>
          Administrador de privilegios
        </Typography>
      </div>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead classes={classes} rowCount={detailsUsers.length} />
          <TableBody>
            {detailsUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                  <TableCell component="th" id={labelId} scope="row" padding="none">
                    {row.email}
                  </TableCell>
                  <TableCell align="left">{rolesFormat[row.erol]}</TableCell>
                  <TableCell align="left">{!row.unitName ? "--" : row.unitName}</TableCell>
                  <TableCell align="left">{row.lasLoginDuration}</TableCell>
                  <TableCell align="left">{row.lastLoginDate}</TableCell>
                  <TableCell align="left">{row.loginType}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="center">
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <InputLabel id="selected-rol-label">Roles</InputLabel>
                      <Select
                        labelId="selected-rol-label"
                        id={row.id}
                        value={rolSelected[row.id]}
                        onChange={handleChangeRol}
                        label="Roles"
                        name={row.id}
                        defaultValue="hola"
                      >
                        {RolesItems()}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <InputLabel id="selected-unit-label">Unidades</InputLabel>
                      <Select
                        labelId="selected-unit-label"
                        id="selected-unit"
                        value={unitSelected[row.id]}
                        onChange={handleChangeUnit}
                        label="Unidades"
                        name={row.id}
                      >
                        {detailsUnit?.units.map((unit) => (
                          <MenuItem value={unit.unitId} key={unit.unitId}>
                            {unit.unitName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => handleDeleteUser(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={detailsUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default UsersAdministration
