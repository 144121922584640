import React, { useState } from "react"
// EXTERNAL COMPONENTS
import { makeStyles, Input } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const EditServiceInput = ({ serviceName, setValueEditService }) => {
  const classes = useStyles()
  const [value, setValue] = useState(serviceName)

  const handleChangeEditService = ({ target }) => {
    const { value } = target
    setValueEditService(value.toUpperCase())
    setValue(value.toUpperCase())
  }

  return (
    <div>
      <>
        <div className={classes.areaAndEditContainer2}>
          <div className={classes.areaContainer2}>
            <Input
              className={classes.addAreaInput}
              placeholder={!value ? "NOMBRE DE SERVICIO" : value}
              inputProps={{ "aria-label": "description" }}
              disableUnderline={true}
              onChange={(e) => handleChangeEditService(e)}
              autoFocus
              value={value}
            />
          </div>
        </div>
      </>
    </div>
  )
}

export default EditServiceInput
