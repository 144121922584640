import useMediaQuery from "@material-ui/core/useMediaQuery"

export default function Flexbox({
  flexDirection = "row",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  children,
}) {
  const xs = useMediaQuery("(max-width:600px)")
  const flex = {
    display: "flex",
    flexDirection: flexDirection,
    justifyContent: justifyContent,
    alignItems: xs ? null : alignItems,
    width: "100%",
    height: "100%",
  }
  return <div style={flex}>{children}</div>
}
