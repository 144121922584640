import API from "../../utils/api"

const getOrganizationalUnitDetails = async (unit_id) => {
  const path = `/dashboard/${unit_id}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export { getOrganizationalUnitDetails }
