import axios from "axios"

const getIdToken = async () => {
  try {
    const session = await JSON.parse(localStorage.getItem("user"))
    const token = session.accessToken
    return token
  } catch (error) {
    return null
  }
}

const checkToken = async (config) => {
  let request = {}
  const idToken = await getIdToken()

  if (idToken) {
    request = {
      ...config,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  } else {
    request = {
      ...config,
    }
  }

  return request
}

const API = axios.create({
  baseURL: window.processEnv.REACT_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    common: {
      "Content-Type": "application/json",
    },
    post: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
})

API.interceptors.request.use((config) => {
  const request = checkToken(config)

  return request
})

export default API
