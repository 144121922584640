import React, { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { deleteUnit, getAllUnits, getAreas } from "../../services/architechture"
import DashboardUnit from "../../contexts/dashboardUnitContext"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteUnitModal = ({ open, onClose, unitId, unitName, error, setError }) => {
  const classes = useStyles()

  const [allAreas, setAllAreas] = useState([])
  const { setUnit, areas, setAreas, onOpenPopUpDeleteUnit } = useContext(DashboardUnit)

  const handleClickDeleteUnit = async () => {
    onOpenPopUpDeleteUnit()
    try {
      const deleteResp = await deleteUnit(unitId)
      if (deleteResp) {
        const resp = await getAllUnits()
        setUnit(resp[0]?.id)
      }
      onOpenPopUpDeleteUnit()
      onClose()
    } catch (e) {
      setError(true)
      console.log(e)
    }
  }

  const getAllAreas = async () => {
    try {
      const resp = await getAreas(unitId)
      setAllAreas(resp)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAllAreas()
  }, [unitId, areas])

  const handleClickSeeUnit = () => {
    setError(false)
    onClose()
  }

  return (
    <div>
      {!error ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.deleteModal}
          open={open}
          onClose={onClose}
          closeAfterTransition
        >
          <div className={classes.paper}>
            <div className={classes.paperChild}>
              <div>{warningSvg}</div>

              <p className={classes.deleteModalTitleP}>Borrar Unidad</p>
              <p className={classes.deleteText}>
                ¿Desea borrar la unidad <span className={classes.deleteTextSpan}>{unitName}</span>?
              </p>
              <div className={classes.deleteButtonContainer}>
                <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                  NO
                </Button>

                {!allAreas.length ? (
                  <Link to="/" className={classes.addUnitLink1}>
                    <Button
                      variant="contained"
                      className={classes.deleteButton}
                      onClick={() => handleClickDeleteUnit()}
                    >
                      SI, BORRAR UNIDAD
                    </Button>
                  </Link>
                ) : (
                  <Button variant="contained" className={classes.deleteButton} onClick={() => handleClickDeleteUnit()}>
                    SI, BORRAR UNIDAD
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.deleteModal}
          open={open}
          onClose={onClose}
          closeAfterTransition
        >
          <div className={classes.paper}>
            <div className={classes.paperChild}>
              <div>{warningSvg}</div>

              <p className={classes.deleteText1}>
                No es posible borrar <span className={classes.deleteTextSpan1}>{unitName}</span> porque tiene áreas,
                pisos, servicios, subservicios y camas.
              </p>

              <div className={classes.deleteButtonContainer1}>
                <Button variant="contained" className={classes.deleteButton} onClick={() => handleClickSeeUnit()}>
                  VER UNIDAD
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default DeleteUnitModal
