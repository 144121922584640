import { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import { useHistory } from "react-router-dom"
import ComercialBeds from "../../contexts/comercialBedsContext"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80rem",
    margin: "1.5rem auto",
  },
  table: {
    minWidth: 750,
  },
  title: {
    fontSize: "2.3rem",
    color: "#566573",
    margin: "2rem 0 3rem 0",
  },
  cellHead: {
    fontSize: "1.2rem",
    fontWeight: "600",
  },
}))

export default function TableIsapresCommercialView({ data }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isapreData, setIsapreData] = useState([])
  const history = useHistory()
  const { setComercialBeds } = useContext(ComercialBeds)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getData = (data) => {
    const obj = {}
    data.forEach((el) => {
      if (!obj[`${el.isaprePlan}-${el.isapre}`]) {
        obj[`${el.isaprePlan}-${el.isapre}`] = {
          isapre: el.isapre,
          namePlan: el.isaprePlan,
          cant: 1,
          beds: [el],
        }
      } else {
        obj[`${el.isaprePlan}-${el.isapre}`] = {
          ...obj[`${el.isaprePlan}-${el.isapre}`],
          cant: obj[`${el.isaprePlan}-${el.isapre}`]["cant"] + 1,
          beds: [...obj[`${el.isaprePlan}-${el.isapre}`]["beds"], el],
        }
      }
    })
    const result = Object.values(obj)
    return result
  }

  useEffect(() => {
    setIsapreData(getData(data))
  }, [])

  const handleGoToComercialBeds = (path, details, title) => {
    setComercialBeds({ details, title })
    history.push(path)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, isapreData.length - page * rowsPerPage)

  const headCells = [
    { id: "Tipo", numeric: false, disablePadding: true, label: "Area" },
    { id: "Plan", numeric: false, disablePadding: true, label: "Plan" },
    { id: "Cantidad", numeric: false, disablePadding: false, label: "Admisión" },
  ]

  const EnhancedTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, idx) => (
            <TableCell key={idx} align="left" padding="normal" className={classes.cellHead}>
              {headCell.id}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead classes={classes} rowCount={isapreData.length} />
          <TableBody>
            {isapreData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    align="left"
                    onClick={() =>
                      handleGoToComercialBeds(
                        `/commercialview/${row.isapre}-${row.namePlan}`,
                        row.beds,
                        `${row.isapre} ${row.namePlan}`,
                      )
                    }
                  >
                    {!row.isapre ? "-" : row.isapre}
                  </TableCell>
                  <TableCell align="left">{!row.namePlan ? "-" : row.namePlan}</TableCell>
                  <TableCell align="left">{!row.cant ? "-" : row.cant}</TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={isapreData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
