import React, { useState, useContext, useEffect } from "react"
import { makeStyles, useMediaQuery } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"

import { getOrganizationalUnitDetails } from "../../services/organitation"
import { getCurrentUser, logoutUser } from "../../services/auth"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import AuthContext from "../../contexts/authContext"
import { refreshApi } from "../../utils/refreshApi"
import { useHistory } from "react-router-dom"
import { expiredToken } from "../../utils/expiredToken"
import PersistentDrawerLeft from "../Drawer"

import { GoogleLogout } from "react-google-login"

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0.5rem 1rem",
    marginBottom: "1rem",
    background: "#F5F5F5",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0rem",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  contentAvatar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "25%",
      justifyContent: "flex-start",
    },
  },
  user: {
    paddingLeft: "0.7rem",
    color: "#535461",
    fontSize: "1.1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".9rem",
    },
  },
  divider: {
    backgroundColor: "#535461",
    width: "0.1rem",
    margin: "0.4rem 0.7rem",
    [theme.breakpoints.down("md")]: {
      margin: "0 1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "15rem",
  },
  link: {
    textDecoration: "none",
    color: "#566573",
    fontSize: "1.1rem",
  },
  buttonAvatar: {
    width: "max-content",
    minWidth: "max-content",
  },
  avatar: {
    [theme.breakpoints.down("xs")]: {
      width: "2rem",
      height: "2rem",
    },
  },
  drawer: {
    [theme.breakpoints.down("xs")]: {
      width: "0",
    },
  },
}))

const clientId = window.processEnv.REACT_APP_CLIENTE_ID

const Header = () => {
  const classes = useStyles()
  // const [unit, setUnit] = useState(localStorage.getItem("unit") || 0)
  const [isLoading, setIsLoading] = useState(false)
  const { unit } = useContext(DashboardUnit)
  const { setUnit } = useContext(DashboardUnit)
  const { refreshLabelUnit } = useContext(DashboardUnit)
  const { refreshLabelUnitUrl } = useContext(DashboardUnit)
  const { setDetailsUnit } = useContext(DashboardUnit)
  const { setDateUpdate } = useContext(DashboardUnit)
  const { detailsUnit } = useContext(DashboardUnit)
  const [refresh, setRefresh] = useState(null)
  const [open, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { auth, setAuth } = useContext(AuthContext)

  const history = useHistory()

  const organizationalUnitDetails = async () => {
    setIsLoading(true)
    try {
      const resp = await getOrganizationalUnitDetails(unit)

      setDateUpdate(resp.lastUpdateData)

      setDetailsUnit(resp)
      if (!unit) {
        localStorage.setItem("unit", resp.unit[0].id)
        setUnit(resp.unit[0].id)
      }
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const addIdUnitToToken = () => {
    const user = getCurrentUser()
    const parseToken = user?.accessToken?.split(" ")
    const getToken = parseToken && parseToken[parseToken.length - 1]
    const updateUser = {
      ...user,
      accessToken: unit >= 10 ? `${unit}00000000 ${getToken}` : `0${unit}00000000 ${getToken}`,
    }
    localStorage.removeItem("user")
    localStorage.setItem("user", JSON.stringify(updateUser))
  }

  useEffect(() => {
    organizationalUnitDetails()
    addIdUnitToToken()
  }, [unit, refresh, refreshLabelUnit, refreshLabelUnitUrl])

  const handleChangeUnit = (event) => {
    localStorage.setItem("unit", event.target.value)
    setUnit(event.target.value)
    history.push(`/`)
  }

  useEffect(() => {
    refreshApi(setRefresh, 300)
  }, [])

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setOpenMenu(false)
  }

  const handleLogout = () => {
    logoutUser()
    setAuth(null)
    history.push("/")
  }

  const matches = useMediaQuery("(min-width:600px)")

  return (
    <div className={classes.header}>
      {matches ? null : (
        <div className={classes.drawer}>
          <PersistentDrawerLeft />
        </div>
      )}
      <div className={classes.contentAvatar}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          className={classes.buttonAvatar}
        >
          <Avatar className={classes.avatar} />
          <p className={classes.user}>{auth.username} </p>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <GoogleLogout
            icon={false}
            clientId={clientId}
            onLogoutSuccess={handleLogout}
            render={(renderProps) => (
              <MenuItem onClick={renderProps.onClick} disabled={renderProps.disabled}>
                Logout
              </MenuItem>
            )}
          ></GoogleLogout>
        </Menu>
      </div>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <FormControl variant="outlined" className={classes.formControl} size="small">
        <InputLabel id="unit-selected" margin="dense">
          Unidad
        </InputLabel>
        <Select labelId="unit-selected" id="select-outlined" value={unit} onChange={handleChangeUnit} label="Unidad">
          {detailsUnit?.units.map((u, i) => (
            <MenuItem key={i} value={u.unitId}>
              {u.unitName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default Header
