import React, { useState, useEffect } from "react"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { deleteBed, getAllBeds } from "../../services/architechture"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteBedModal = ({ open, onClose, bedInfo, setBeds, onActivePopUp, setOpenPopUpDeleteBed }) => {
  const classes = useStyles()

  const { unitId, areaId, floorId, serviceId, subServiceId, code } = bedInfo

  const handleDeleteBed = async () => {
    try {
      const resp = await deleteBed(code)

      if (open) {
        const newBeds = await getAllBeds(unitId, areaId, floorId, serviceId, subServiceId)
        setBeds(newBeds)
      }
      onActivePopUp(setOpenPopUpDeleteBed, true)
      onClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.deleteModal}
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <div className={classes.paperChild1}>
            <div>{warningSvg}</div>

            <p className={classes.deleteText}>
              ¿Desea borrar la cama <span className={classes.deleteTextSpan}>{code}</span>?
            </p>

            <div className={classes.deleteButtonContainer}>
              <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                NO
              </Button>

              <Button variant="contained" className={classes.deleteButton} onClick={() => handleDeleteBed()}>
                SI, BORRAR CAMA
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteBedModal
