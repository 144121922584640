import React, { useEffect, useState } from 'react'
import { Axis, Chart, Geom, Legend, Line, Point, Tooltip } from 'bizcharts'
import { Typography } from '@material-ui/core'
import { handleTypeTime } from '../../../../utils/handleTypeTime'

export const BedBusyPercentage = ({ data }) => {

    const [maxMinValues, setMaxMinValues] = useState({ min:0, max:0 })
    const [ itemsParse, setItemParse] =  useState([])

    const getMaxValue = ()=>{ 
        if(data.length !== 0){ 

            const arrNumbers = data.map( element => Number(element.averageBusy))

            setMaxMinValues({ max: Math.max(...arrNumbers), min: Math.min(...arrNumbers) })
        }
    }
    
    const handleParseData = ()=>{ 

        if( data.length !== 0 ){
            const arrParse = data.map( element => ({ 
                "fecha": handleTypeTime(element),
                "Porcentaje Ocupadas": element.averageBusy,
                "unidad": element.unitName
            }))
            setItemParse(arrParse)
        }

    }

    useEffect(()=>{ 
        getMaxValue()
        handleParseData()
    },[])
     
    const scale = {
        "Porcentaje Ocupadas": { max: maxMinValues.max + 10},
        fecha: {
            type:"cat",
			range: [0, 0.98]
		}
        }

  return (
    <div style={{ width:'100%', border:'1px solid #CBD0D4' }}>
        <Typography style={{ fontSize: 20, color:'#535461', padding:'10px 0px 0px 10px', fontWeight:700 }}> Ocupación días camas </Typography>
        <Chart key={itemsParse} scale={scale} padding={[30, 50, 60, 50]} autoFit height={400} data={itemsParse} interactions={['element-active']}>
            <Point position="fecha*Porcentaje Ocupadas" color="unidad" shape='circle' />
            <Axis 
                name='Porcentaje Ocupadas'
                label={{ 
                    formatter: (val) => `${val}%`
                }}    
            />
            <Line 
                shape="smooth" 
                position="fecha*Porcentaje Ocupadas" 
                color="unidad"
                tooltip={[ 
                    "unidad*Porcentaje Ocupadas",
                    ( name, value ) => { 
                        return { 
                            value: `${value}%`,
                            name
                        }
                    }
                ]}
            />
            <Tooltip crosshairs={{ type: 'y'}}  showCrosshairs/>
            <Legend background={{
                padding:[5,100,5,36],
                style: {
                    width:'100%',
                    fill: '#eaeaea',
                    stroke: '#fff'
                }
            }} />
        </Chart>
    </div>
  )
}
