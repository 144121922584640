import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    subtitle: {
      fontSize: "1.75rem",
      color: "#566573",
      marginTop: "1em",
    },
  }))

export const HistorySubHeader = () => {

    const classes = useStyles()

    return <Typography variant="h3" className={classes.subtitle}>
                Generar reporte
            </Typography>
}
