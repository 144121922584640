import React, { useContext, useMemo } from "react"

//contexts
import DashboardUnit from "../../contexts/dashboardUnitContext"

//components
import { TableMaterial } from "../../components"

//bizcharts
import { PieChart } from "bizcharts"

const MonitorMC = () => {
  const { detailsUnit, openPopUpDeleteUnit, onClosePopUpDeleteUnit, unit } = useContext(DashboardUnit)

  const {
    areas,
    availableBedCount,
    blockedBedCount,
    busyBedCount,
    inProgressBedCount,
    totalBedCount,
    urgencyBedCount,
    reserveBedCount,
    virtualBedCount,
    inTransitBedCount,
  } = detailsUnit?.unit[0] || []

  const chart_data = useMemo(() => {
    const data = [
      { totals: availableBedCount, id: 1, description: "Disponibles" },
      { totals: blockedBedCount, id: 1, description: "Bloqueadas" },
      { totals: busyBedCount, id: 1, description: "Ocupadas" },
      { totals: inProgressBedCount, id: 1, description: "En recepción" },
      { totals: reserveBedCount, id: 1, description: "Reservadas" },
      { totals: virtualBedCount, id: 1, description: "Virtuales" },
      { totals: inTransitBedCount, id: 1, description: "En Tránsito" },
    ].filter((data) => data.totals !== 0)

    return data.map((item) => {
      const percentage = ((item.totals / totalBedCount) * 100).toFixed(2)
      return { ...item, percentage }
    })
  }, [
    availableBedCount,
    blockedBedCount,
    busyBedCount,
    inProgressBedCount,
    reserveBedCount,
    virtualBedCount,
    inTransitBedCount,
    totalBedCount,
  ])
  const handleBgcolor = (description) => {
    const colorMap = {
      Disponibles: "green",
      Bloqueadas: "grey",
      Ocupadas: "red",
      "En recepción": "orange",
      Reservadas: "yellow",
      Virtuales: "lightblue",
      "En Tránsito": "purple",
    }

    return colorMap[description] || ""
  }

  return (
    <>
      <div>
        <TableMaterial
          availableBedCount={availableBedCount}
          blockedBedCount={blockedBedCount}
          busyBedCount={busyBedCount}
          inProgressBedCount={inProgressBedCount}
          totalBedCount={totalBedCount}
          urgencyBedCount={urgencyBedCount}
          reserveBedCount={reserveBedCount}
          virtualBedCount={virtualBedCount}
          inTransitBedCount={inTransitBedCount}
        />
      </div>

      <PieChart
        height={600}
        data={chart_data}
        title={{
          visible: true,
          text: "Gráfico de camas por estado",
        }}
        description={{
          visible: true,
          text: `Total de camas: ${totalBedCount}`,
        }}
        radius={0.8}
        angleField="totals"
        colorField="description"
        color={(item) => handleBgcolor(item.description)}
        label={{
          visible: false,
        }}
        tooltip={{
          visible: true,
          formatter: ({ description, totals }) => {
            const item = chart_data.find((item) => item.description === description)
            const percentage = item ? item.percentage : 0
            return {
              name: description,
              value: `${totals} (${percentage}%)`,
            }
          },
        }}
      />
    </>
  )
}

export default MonitorMC
