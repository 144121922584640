import React from "react"
import { PieChart } from "bizcharts"

const ChartGraphic = ({ chartData, totalBeds }) => {
  return (
    <PieChart
      data={chartData}
      title={{
        visible: true,
        text: "Grafico de camas bloqueadas por motivo",
      }}
      description={{
        visible: true,
        text: `Total de camas: ${totalBeds}`,
      }}
      radius={0.8}
      angleField="totals"
      colorField="description"
      label={{
        visible: true,
        type: "outer",
        offset: 20,
      }}
    />
  )
}

export default ChartGraphic
