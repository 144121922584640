import API from "../../utils/api"

const createUnit = async (data) => {
  const path = `/infraestructures/units`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const getAllUnits = async () => {
  const path = `/infraestructures/units`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createSingleArea = async (data) => {
  const path = `/infraestructures/areas`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const getAreaName = async (areaId) => {
  const path = `/infraestructures/areas/${areaId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getUnit = async (id) => {
  const path = `/infraestructures/units/${id}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAreas = async (unitId) => {
  const path = `/infraestructures/areas?unitId=${unitId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getSingleArea = async (unitId, areaId) => {
  const path = `/infraestructures/floors?unitId=${unitId}&areaId=${areaId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllServices = async (unitId, areaId, floorId) => {
  const path = `/infraestructures/services?unitId=${unitId}&areaId=${areaId}&floorId=${floorId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getFloorName = async (floorId) => {
  const path = `/infraestructures/floors/${floorId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createSingleFloor = async (data) => {
  const path = `/infraestructures/floors`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const createSingleService = async (data) => {
  const path = `/infraestructures/services`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const getSubServices = async (unitId, areaId, floorId, serviceId) => {
  const path = `/infraestructures/subservices?unitId=${unitId}&areaId=${areaId}&floorId=${floorId}&serviceId=${serviceId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createSingleSubService = async (data) => {
  const path = `/infraestructures/subservices`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const getAllRooms = async (unitId, areaId, floorId, serviceId, subServiceId) => {
  const path = `/infraestructures/rooms?unitId=${unitId}&areaId=${areaId}&floorId=${floorId}&serviceId=${serviceId}&subServiceId=${subServiceId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const getAllBeds = async (unitId, areaId, floorId, serviceId, subServiceId) => {
  const path = `/beds?unitId=${unitId}&areaId=${areaId}&floorId=${floorId}&serviceId=${serviceId}&subServiceId=${subServiceId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const createRoom = async (data) => {
  const path = `/infraestructures/rooms`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const createBed = async (data) => {
  const path = `/beds`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => error.response)
}

const getRoomCode = async (unitId, areaId, floorId, serviceId, subServiceId) => {
  const path = `/infraestructures/rooms?unitId=${unitId}&areaId=${areaId}&floorId=${floorId}&serviceId=${serviceId}&subServiceId=${subServiceId}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const roomCodeExists = async (code) => {
  const path = `/infraestructures/rooms/${code}`
  return API.get(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteUnit = (unitId) => {
  const path = `/infraestructures/units/${unitId}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteArea = (areaId) => {
  const path = `/infraestructures/areas/${areaId}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteFloor = (floorId) => {
  const path = `/infraestructures/floors/${floorId}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteService = (serviceId) => {
  const path = `/infraestructures/services/${serviceId}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteSubservice = (subServiceId) => {
  const path = `/infraestructures/subservices/${subServiceId}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const deleteBed = (code) => {
  const path = `/beds/${code}`
  return API.delete(path)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const editUnit = (unitId, data) => {
  const path = `/infraestructures/units/${unitId}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}

const editArea = (areaId, data) => {
  const path = `/infraestructures/areas/${areaId}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}
const editFloor = (floorId, data) => {
  const path = `/infraestructures/floors/${floorId}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}
const editService = (serviceId, data) => {
  const path = `/infraestructures/services/${serviceId}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}
const editSubService = (subServiceId, data) => {
  const path = `/infraestructures/subservices/${subServiceId}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}
const editBed = (bedCode, data) => {
  const path = `/beds/${bedCode}`
  return API.put(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => console.log(error.data))
}

const moveBeds = async (data) => {
  const path = `/beds/move`
  return API.post(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

const bulkDeleteBeds = (data) => {
  const path = `/beds`
  return API.delete(path, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response))
}

export {
  createUnit,
  getUnit,
  getAreas,
  getSingleArea,
  createSingleArea,
  getAllServices,
  createSingleFloor,
  createSingleService,
  getAreaName,
  getFloorName,
  getSubServices,
  createSingleSubService,
  getAllRooms,
  getAllBeds,
  createBed,
  getRoomCode,
  roomCodeExists,
  createRoom,
  deleteUnit,
  getAllUnits,
  deleteArea,
  editUnit,
  editArea,
  editFloor,
  deleteFloor,
  deleteService,
  editService,
  deleteSubservice,
  deleteBed,
  editBed,
  editSubService,
  moveBeds,
  bulkDeleteBeds,
}
