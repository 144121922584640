export const handleTypeTime = (data)=>{ 
    
    
    if(data.day){ 
        return data.day
    }else if(data.month){
        let date = new Date(data.month + 'T00:00:00');
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();

        return month
    }else if(data.year){
        let date = new Date(data.year + 'T00:00:00');
        let year = date.getFullYear();
        
        return year
    }

}