import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: "1.3rem",
    maxWidth: "23rem",
    minWidth: "23rem",
    margin: "1rem",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  contentText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem 0",
    cursor: "pointer",
  },
  contentVerMenos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0 0 0",
  },
  contentVerMas: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentCode: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textData: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  stateBusy: {
    background: "#FEEBEE",
    color: "#F56E69",
    padding: "0.5rem",
    borderRadius: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
  },
  divider: {
    margin: "0.7rem 0",
  },
  footer: {
    background: "rgba(68, 138, 114, 0.2)",
    padding: "0.7rem",
    color: "#448A72",
    borderRadius: "0.4rem",
  },
  stateBlocked: {
    background: "#DADADA",
    color: "#6E6E6E",
    padding: "0.5rem",
    borderRadius: "1rem",
    fontWeight: "bold",
  },
  urgencyLaw: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "12px",
  },
  divUrgencyLawGreen: {
    backgroundColor: "#7DCEA0",
    width: "9rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "2rem",
    justifyContent: "center",
    marginLeft: "-88px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-50px",
    },
  },
  divUrgencyLawRed: {
    backgroundColor: "#f1948a",
    width: "11rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "2rem",
    justifyContent: "center",
    marginLeft: "-56px",
  },
  statePending: {
    background: "#FFF3E0",
    color: "#FF9800",
    padding: "0.5rem",
    borderRadius: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stateAvailable: {
    background: "#E8F5E9",
    color: "#4CAF50",
    padding: "0.5rem",
    borderRadius: "1rem",
    fontWeight: "bold",
  },
  stateInTransit: {
    background: "#E4A5F5",
    color: "black",
    padding: "0.5rem",
    borderRadius: "1rem",
    fontWeight: "bold",
  },
  contentReasons: {
    display: "flex",
    flexDirection: "column",
  },
  reserve: {
    cursor: "pointer",
  },
  icons: {
    cursor: "pointer",
  },
}))

export default useStyles
