import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core'
import React from 'react'
import { typeReportOption } from '../constants/constants'
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DownloadIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DashboardUnit from '../../../contexts/dashboardUnitContext';
import { useContext } from 'react';
import { format } from "date-fns"
import { useCreateExcelFile } from '../../../hooks/useCreateExcelFile';
import { handleExcelName } from '../../../utils/excelName';
import { handleTypeChart } from '../../../utils/handleTypeChart';
import { handleDifDays } from '../../../utils/handleDifDays';
import { handleGraphparams } from '../../../utils/handleGraphParams';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
    form: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2rem",
    },
    input: {
      margin: "1rem",
      width: "100%",
    },
    save: {
      background: "#30AFC0",
      color: "#ffffff",
      fontWeight: "600",
      borderRadius: "2rem",
      minWidth: "7rem",
      marginTop: "5rem",
      "&:hover": {
        background: "#30AFC0",
        color: "#ffffff",
      },
    },
    buttonForm: { 
        backgroundColor:'#009DB1',
        color:'white !important',
        "&:hover":{
            backgroundColor:'#037f8f !important'
        }
    },
    reloadFilterButton: { 
        color:'#566573',
        marginLeft:10,
        textTransform:'capitalize',
        "&:disabled":{ 
           opacity: 0.5
        }
    }

  })

export const FilterHistory = ({ filterHistory, setFilterHistory, handleGetGraphData, setgraphData, handleGetExcelData, setExcelData, excelData }) => {

    const classes = useStyles()
    const { detailsUnit } = useContext(DashboardUnit)
    const excelName = handleExcelName(filterHistory.typeReport)
    
    const { exportToExcel } = useCreateExcelFile({ data: excelData, fileName: excelName.fileName, type: excelName.sheetName })

    const handleSubmit = (e) => {
        e.preventDefault()

        if(filterHistory.typeReport){

            let { paramsExcel, paramsGraph } = handleGraphparams(filterHistory)
    
            handleGetGraphData(paramsGraph)
            handleGetExcelData(paramsExcel)

        }

    }

    const handleChangeState = (event) => {
    setFilterHistory({ ...filterHistory, [event.target.name]: event.target.value })
    setExcelData([])
    setgraphData([])
    }

    const isDisabled = ()=>{ 
        if(filterHistory.typeReport && filterHistory.from && filterHistory.to && filterHistory.idUnits.length !== 0){
            return false
        }else{ 
            return true
        }
    }

  return (
    <form onSubmit={handleSubmit} style={{ width:'100%', display:'flex', flexDirection:'column' }}>
        <div style={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <FormControl className={classes.input} variant="outlined" style={{ maxWidth:510, width:'100%' }} >
                <InputLabel htmlFor="selected-state-label">Tipo de reporte</InputLabel>
                <Select
                    labelId="selected-state-label"
                    id="typeReport"
                    value={filterHistory["typeReport"]}
                    onChange={handleChangeState}
                    name="typeReport"
                    labelWidth={105}
                    style={{ maxWidth:510 }}
                >
                    {
                        typeReportOption.map( element => <MenuItem key={element.id} value={element.id}>{ element.text }</MenuItem> )
                    }
                </Select>
            </FormControl>
            <KeyboardDatePicker
                inputVariant="outlined"
                value={filterHistory["from"]}
                onChange={(date) => {
                    setFilterHistory({ ...filterHistory, ['from']: date })
                    setExcelData([])
                    setgraphData([])
                }}
                label="Desde"
                format="dd/MM/yyyy"
            />
            <KeyboardDatePicker
                inputVariant="outlined"
                value={filterHistory["to"]}
                onChange={(date) => {
                    setFilterHistory({ ...filterHistory, ['to']: date })
                    setExcelData([])
                    setgraphData([])
                }}
                label="Hasta"
                format="dd/MM/yyyy"
            />
             <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={detailsUnit.units}
                disableCloseOnSelect
                getOptionLabel={(option) => option.unitName}
                value={filterHistory["idUnits"]}
                name="idUnits"
                onChange={(event, newValue)=>{ 
                    setFilterHistory({ ...filterHistory, ['idUnits']: newValue })
                    setExcelData([])
                    setgraphData([])
                }}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.unitName}
                    </React.Fragment>
                )}
                style={{ maxWidth: 300, width:'100%' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Clínica/s" placeholder="Clínica/s" />
                )}
            />
        </div>
        <div style={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <div style={{ width: '100%', display:'flex', padding: '1rem'}}>
                <Button className={ classes.buttonForm } disabled={isDisabled()} variant='contained' type='submit' style={{  width:154, height:36 }}> Aplicar </Button>
                <Button 
                    disabled={isDisabled()}
                    variant='text' 
                    className={classes.reloadFilterButton}
                    startIcon={<ReplayIcon style={{ color:'#566573', opacity: isDisabled() ? 0.5 : 1 }} />} 
                    onClick={()=> {
                        setFilterHistory( { typeReport: null, from: null, to: null, idUnits: [] } )
                        setgraphData([])
                        setExcelData([])
                    }
                    }
                >
                    Limpiar filtros
                </Button>
            </div>
            <div>
                <Button 
                    className={ classes.buttonForm } 
                    variant='contained' 
                    disabled={excelData.length === 0} 
                    style={{  width:200, height:36 }} 
                    startIcon={<DownloadIcon style={{ color:'white' }} />} 
                    onClick={()=>{ 
                        exportToExcel()
                    }}
                > Exportar reporte </Button>
            </div>
        </div>
    </form>
  )
}
