import React, { useEffect, useState } from "react"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { roomCodeExists, createBed, getAllBeds, createRoom } from "../../services/architechture"

// EXTERNAL COMPONENTS
import { Divider, makeStyles, Input, Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import SaveIcon from "@material-ui/icons/Save"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const AddBedCard = ({
  setAddBed,
  subServiceId,
  areaId,
  unitId,
  floorId,
  serviceId,
  addBed,
  setBeds,
  onActivePopUp,
  setOpenPopUpBedError,
  setOpenPopUpBed,
}) => {
  const classes = useStyles()

  const [roomCode, setRoomCode] = useState(null)
  const [bedCode, setBedCode] = useState("")

  // ERROR IN BED CREATION
  const [bedError, setBedError] = useState(false)

  const [isVirtual, setIsVirtual] = useState(false)

  const [isOncologyChair, setIsOncologyChair] = useState(false)
  
  const handleChangeBed = ({ target }) => {
    setBedError(false)
    const { value } = target
    setBedCode(value)
  }

  const roomCodeFn = async () => {
    try {
      const code = `SUB${Number(subServiceId)}`
      const resp = await roomCodeExists(code)
      setRoomCode(resp.code)
    } catch (e) {
      const room = await createRoom({
        code: `SUB${subServiceId}`,
        unitId: Number(unitId),
        areaId: Number(areaId),
        floorId: Number(floorId),
        serviceId: Number(serviceId),
        subServiceId: Number(subServiceId),
      })

      setRoomCode(room.code)
    }
  }

  const handleSubmitBed = async () => {
    try {
      if (!bedCode) setBedError(true)
      if (bedCode) {
        const resp = await createBed({
          code: bedCode,
          unitId: Number(unitId),
          areaId: Number(areaId),
          floorId: Number(floorId),
          serviceId,
          subServiceId,
          roomCode: roomCode,
          stateId: -1,
          isVirtual: isVirtual ? 1 : 0,
          isOncology: isOncologyChair ? 1 : 0
        })
        setBedError(false)

        if (resp.data) {
          onActivePopUp(setOpenPopUpBedError, true)
        } else {
          const allBeds = await getAllBeds(
            Number(unitId),
            Number(areaId),
            Number(floorId),
            Number(serviceId),
            Number(subServiceId),
          )
          setBeds(allBeds)
          setAddBed(false)
          onActivePopUp(setOpenPopUpBed, true)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    roomCodeFn()
  }, [addBed])

  const label = { inputProps: { "aria-label": "Checkbox demo" } }

  const handleChangeCheckbox = ({ target }) => {
    const checked = target.checked
    setIsVirtual(checked)
  }

  const handleChangeOncologyChair  = ({ target }) => {
    const checked = target.checked
    setIsOncologyChair(checked)
  }

  return (
    <div className={classes.roomCard}>
      <div className={classes.roomCardContentCode}>
        <Input
          className={bedError ? classes.bedError : classes.addBedInput}
          placeholder="Código de cama"
          inputProps={{ "aria-label": "description" }}
          disableUnderline={true}
          autoFocus
          onChange={(e) => handleChangeBed(e)}
          value={bedCode}
        />
        <CloseIcon
          onClick={() => {
            setBedError(false)
            setAddBed({ index: null, state: false })
          }}
        />
      </div>
      <Divider className={classes.roomCardDivider} />

      <FormGroup style={{ display:'flex', flexDirection:'row' }}>
        <FormControlLabel
          control={<Checkbox onChange={(e) => handleChangeCheckbox(e)} disabled={isOncologyChair} />}
          label={<span style={{ fontSize: "14px", display: "flex" }}>Cama virtual</span>}
        />
        <FormControlLabel
          control={<Checkbox onChange={(e) => handleChangeOncologyChair(e)} disabled={isVirtual} />}
          label={<span style={{ fontSize: "14px", display: "flex" }}>Sillón Oncológico</span>}
        />
      </FormGroup>

      <div className={classes.saveIconContainer4} onClick={() => handleSubmitBed()}>
        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
          <SaveIcon className={classes.editUnitIcon} />
          <p className={classes.editUnitP}>Guardar Cambios</p>
        </div>
      </div>
    </div>
  )
}

export default AddBedCard
