import React, { useState } from 'react'
import { useCallback } from 'react'
import { getHistoricalData, getHistoricalDataFloor, getHistoricalDataProfessional } from '../../services/historical'

export const useGetGraph = () => {

    const [graphData, setgraphData] = useState([])


    const  handleGetGraphData = useCallback(async ( params )=>{ 

        if(params.type === 'professional'){

            const response = await getHistoricalDataProfessional(params)
            
            if(response){ 
                setgraphData(response)
            }

        }else if(params.type === 'floor'){

            const response = await getHistoricalDataFloor(params)
            
            if(response){ 
                setgraphData(response)
            }

        }else{

            const response = await getHistoricalData(params)
            
            if(response){ 
                setgraphData(response)
            }
        }

    },[])

    return { 
        graphData,
        setgraphData,
        handleGetGraphData
    }
}
