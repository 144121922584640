import React, { useCallback, useEffect, useState } from 'react'
import { Chart, Legend, Line, Point, Tooltip } from 'bizcharts'
import { Typography } from '@material-ui/core'
import { handleTypeTime } from '../../../../utils/handleTypeTime'

export const CurrentAnalysisHistorical = ({ filterHistory, data }) => {

    const [maxValue, setMaxValue] = useState(0)
    const [ itemsParse, setItemParse] =  useState([])
    const types = ["available", "busy", "atReception", "blocked", "inTransit", "reserved"]
    
    const handleType = (type)=>{ 
        switch (type) {
            case "available":
                return 'Disponibles'
            case "busy":
                return 'Ocupadas'
            case "atReception":
                return 'En recepción'
            case "blocked":
                return 'Bloqueadas'
            case "inTransit":
                return 'En transito'
            case "reserved":
                return 'Reservadas'
        
            default:
                return ''
        }
    }

    const handleChartData = useCallback( () =>{
        let localArr = []
        Object.keys(data).forEach( key => {
            for (let index = 0; index < 6; index++) {
                const element = data[key][types[index]];
                const fecha = handleTypeTime(data[key])
                localArr.push({ 
                    fecha: fecha,
                    type: handleType(types[index]),
                    total: element
                })
            }
            
        })

        setItemParse(localArr)
    
        if(localArr.length !== 0){ 
            let total = 0
            localArr.forEach( element =>{ 
                if(element.total > total){
                    total = element.total
                }
            })
    
            setMaxValue(total)
        }

     }, [])

     useEffect(() => {
        handleChartData()
     }, [])
     
    const scale = {
        fecha: {
            type:"cat",
			range: [0, 0.98]
		}
        }

    const handleColor = (type)=>{ 
        switch (type) {
            case 'Disponibles':
                return '#008000';
            case 'Bloqueadas':
                return '#6E6E6E';
            case 'Ocupadas':
                return '#D22B25';
            case 'En recepción':
                return '#CA851D';
            case 'En transito':
                return '#AE35CC';
            case 'Reservadas':
                return '#0067B1';
        
            default:
                return '#6E6E6E';
        }
    }

  return (
    <div style={{ width:'100%', border:'1px solid #CBD0D4' }}>
        <Typography style={{ fontSize: 20, color:'#535461', padding:'10px 0px 0px 10px', fontWeight:700 }}> { filterHistory.idUnits.length !== 0 && filterHistory.idUnits.map( element => element.unitName).join(', ')} </Typography>
        <Chart key={itemsParse} scale={scale} padding={[30, 50, 60, 50]} autoFit height={320} data={itemsParse} interactions={['element-active']}>
            <Point position="fecha*total" color={["type", (type) => handleColor(type)]} shape='circle' />
            <Line 
                position="fecha*total" 
                color={["type", (type) => handleColor(type)]}
                label="temperature"
            />
            <Tooltip shared showCrosshairs region={null} g2-tooltip-list-item={{display:'flex'}}/>
            <Legend background={{
                padding:[5,100,5,36],
                style: {
                    width:'100%',
                    fill: '#eaeaea',
                    stroke: '#fff'
                }
            }} />
        </Chart>
    </div>
  )
}
