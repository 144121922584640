import React, { useState } from "react"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { GreenCheckbox } from "../../views/Architecture"

// EXTERNAL COMPONENTS
import { makeStyles, Input } from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const NewServiceInput = ({
  handleChangeService,
  serviceName,
  handleSubmitService,
  hover,
  setService,
  handleClickCloseIcon,
  name,
  state,
  setEditGeneralInput,
  editGeneralInput,
  handleServiceEdit,
  edit,
  serviceId,
  serviceError,
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.serviceContainer}>
        <div>
          <li className={classes.addServiceContainer3}>
            <span className={hover ? classes.serviceSpan2 : classes.serviceSpan}>
              <Input
                className={serviceError ? classes.serviceError : classes.addServiceInput}
                placeholder={state ? name : "Nombre de Servicio"}
                inputProps={{ "aria-label": "description" }}
                disableUnderline={true}
                autoFocus
                onChange={(e) => handleChangeService(e)}
                value={serviceName}
              />
            </span>
          </li>
        </div>
        <div className={classes.closeIconContainer2}>
          <CloseIcon
            className={classes.editUnitIcon}
            onClick={() =>
              state
                ? setEditGeneralInput({ ...editGeneralInput, service: { name, state: false } })
                : handleClickCloseIcon(setService)
            }
          />
        </div>
      </div>
      <div
        className={classes.saveIconContainer3}
        onClick={() => (edit ? handleServiceEdit(serviceId) : handleSubmitService())}
      >
        <SaveIcon className={classes.editUnitIcon} />
        <p className={classes.editUnitP}>Guardar Cambios</p>
      </div>
    </>
  )
}

export default NewServiceInput
