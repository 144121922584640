import { createContext } from "react"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import "./MUIThemeProvider.css"

export const MUIThemeContext = createContext()

const MUIThemeProvider = (props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#019DB1",
      },
      secondary: {
        main: "#99cc00",
      },
      text: {
        secondary: "#566573",
      },
    },
    typography: {
      fontFamily: "Open Sans",
      h1: {
        fontFamily: "Nunito",
      },
      h2: {
        fontFamily: "Nunito",
      },
      h3: {
        fontFamily: "Nunito",
      },
      h4: {
        fontFamily: "Nunito",
      },
      h5: {
        fontFamily: "Nunito",
      },
      h6: {
        fontFamily: "Nunito",
      },
    },
  })
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

export default MUIThemeProvider
