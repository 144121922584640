export const svg = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="200px"
    height="200px"
    viewBox="0 0 524 454"
    enableBackground="new 0 0 524 454"
  >
    <image
      id="image0"
      width="524"
      height="454"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgwAAAHGCAMAAAAvwyNyAAAMY2lDQ1BpY2MAAEiJlVcHWFPJFp5b
kpCQ0AIRkBJ6E6RXKSG0GASkCjZCEkgoMSYEFRuWRQXXLqJY0VURRdcCyKIi9rIo9r5YUFHWxYKi
qLwJCei6r3xvvu/c+e+ZM/8pd+beOwBo9/Ck0lxUB4A8Sb4sPjKUOSY1jUlqBySgCwDwBs48vlzK
iouLhndgoP97e38DIMr+qrOS65/j/7XpCYRyPgDIOIgzBHJ+HsRNAODr+VJZPgBEpd5qSr5UiYsg
1pfBACFepcRZKrxTiTNUuLHfJjGeDfFlADSoPJ4sCwCte1DPLOBnQR6tzxC7SgRiCQDawyAO4ot4
AoiVsQ/Ly5ukxOUQ20N7KcQwHuCb8R1n1t/4Mwb5ebysQazKq79phInl0lzetP+zNP+75eUqBnzY
QqGKZFHxyvxhDW/lTOIqMRXiTklGTKyy1hD3iAWqugOAUkSKqCSVPWrCl7Nh/QADYlcBL4wLsQnE
EZLcmGi1PiNTHMGBGK4WdKo4n5MIsSHEC4Xy8AS1zWbZpHi1L7Q2U8ZmqfVnebJ+v0pfDxQ5SSw1
/xuRkKPmx7QKRYkpEFMgti4QJ8dArAWxizwngau2GVEoYscM2MgU8cr4rSGOF0oiQ1X8WEGmLCJe
bV+SJx/IF9ssEnNi1Hh/vigxSlUf7CSf1x8/zAW7LJSwkgZ4hPIx0QO5CIRh4arcsedCSVKCmqdH
mh8ar5qLU6S5cWp73FKYG6nUW0LsKS9IUM/Fk/Ph4lTx45nS/LhEVZx4YTZvZJwqHnwZiAZsEAaY
QAElA0wC2UDc0lnXCe9UIxGAB2QgCwiBs1ozMCOlf0QCrwmgEPwJkRDIB+eF9o8KQQHUfxnUqq7O
ILN/tKB/Rg54CnEe4IJceK/onyUZ9JYMnkCN+B/eeVD4MN5cKMrxf68f0H7TsKAmWq1RDHhkag9Y
EsOJYcQoYgTRATfGg/AAPBpeQ6C4476430Ae3+wJTwmthEeE64Q2wu2J4rmyH6IcBdogf4S6Fhnf
1wK3hZxeeCgeCNkhM87AjYEz7gn9sPBg6NkLatnquJVVYf7A/bcMvnsaajuyKxklDyGHkO1/nKnl
qOU1yKKs9ff1UcWaMVhv9uDIj/7Z31VfAHvuj5bYQuwAdgY7jp3DGrE6wMSOYfXYReyIEg+urif9
q2vAW3x/PDmQR/wPfzy1T2Ul5a7Vrh2un1Vj+cKp+cqNx54knSYTZ4nymSz4dRAyORK+yzCmu6u7
GwDKb43q9fWW0f8NQRjnv+nmwT0eKOnr62v8puN+AuCgBdz+bd90dlfgawK+p88u5ytkBSodrrwQ
4FtCG+40I2AGrIA9zMcdftECQAgIByNBLEgEqWACrLIIrnMZmAJmgDmgGJSCZWA1WAc2ga1gJ9gD
9oM60AiOg9PgArgMroO7cPW0g5egC7wHvQiCkBAaQkeMEHPEBnFC3BFfJAgJR6KReCQVSUeyEAmi
QGYg85BSZAWyDtmCVCG/IoeR48g5pBW5jTxEOpA3yCcUQ6moPmqK2qLDUV+UhXLRRHQ8moVORgvR
+egStBytRHejtehx9AJ6HW1DX6LdGMA0MQZmgTljvhgbi8XSsExMhs3CSrAyrBKrwRrgc76KtWGd
2EeciNNxJu4MV3AUnoTz8cn4LHwxvg7fidfiJ/Gr+EO8C/9KoBFMCE4EfwKHMIaQRZhCKCaUEbYT
DhFOwb3UTnhPJBIZRDuiD9yLqcRs4nTiYuIG4l5iE7GV+JjYTSKRjEhOpEBSLIlHyicVk9aSdpOO
ka6Q2kk9Gpoa5hruGhEaaRoSjbkaZRq7NI5qXNF4ptFL1iHbkP3JsWQBeRp5KXkbuYF8idxO7qXo
UuwogZRESjZlDqWcUkM5RblHeaupqWmp6ac5WlOsWaRZrrlP86zmQ82PVD2qI5VNHUdVUJdQd1Cb
qLepb2k0mi0thJZGy6ctoVXRTtAe0Hq06FouWhwtgdZsrQqtWq0rWq+0ydo22iztCdqF2mXaB7Qv
aXfqkHVsddg6PJ1ZOhU6h3Vu6nTr0nXddGN183QX6+7SPaf7XI+kZ6sXrifQm6+3Ve+E3mM6Rrei
s+l8+jz6Nvopers+Ud9On6OfrV+qv0e/Rb/LQM/A0yDZYKpBhcERgzYGxrBlcBi5jKWM/YwbjE9D
TIewhgiHLBpSM+TKkA+GQw1DDIWGJYZ7Da8bfjJiGoUb5RgtN6ozum+MGzsajzaeYrzR+JRx51D9
oQFD+UNLhu4fescENXE0iTeZbrLV5KJJt6mZaaSp1HSt6QnTTjOGWYhZttkqs6NmHeZ08yBzsfkq
82PmL5gGTBYzl1nOPMnssjCxiLJQWGyxaLHotbSzTLKca7nX8r4VxcrXKtNqlVWzVZe1ufUo6xnW
1dZ3bMg2vjYimzU2Z2w+2NrZptgusK2zfW5naMexK7SrtrtnT7MPtp9sX2l/zYHo4OuQ47DB4bIj
6ujlKHKscLzkhDp5O4mdNji1DiMM8xsmGVY57KYz1ZnlXOBc7fzQheES7TLXpc7l1XDr4WnDlw8/
M/yrq5drrus217tuem4j3ea6Nbi9cXd057tXuF/zoHlEeMz2qPd47enkKfTc6HnLi+41ymuBV7PX
F28fb5l3jXeHj7VPus96n5u++r5xvot9z/oR/EL9Zvs1+n309/bP99/v/1eAc0BOwK6A5yPsRghH
bBvxONAykBe4JbAtiBmUHrQ5qC3YIpgXXBn8KMQqRBCyPeQZy4GVzdrNehXqGioLPRT6ge3Pnslu
CsPCIsNKwlrC9cKTwteFP4iwjMiKqI7oivSKnB7ZFEWI4kYtj7rJMeXwOVWcrpE+I2eOPMmlchO4
67iPoh2jZdENo9BRI0etHHUvxiZGElMXC2I5sStj78fZxU2O+200cXTc6IrRT+Pd4mfEn0mgJ0xM
2JXwPjE0cWni3ST7JEVSc7J28rjkquQPKWEpK1LaxgwfM3PMhVTjVHFqfRopLTlte1r32PCxq8e2
j/MaVzzuxni78VPHn5tgPCF3wpGJ2hN5Ew+kE9JT0nelf+bF8ip53RmcjPUZXXw2fw3/pSBEsErQ
IQwUrhA+ywzMXJH5PCswa2VWhyhYVCbqFLPF68Svs6OyN2V/yInN2ZHTl5uSuzdPIy8977BET5Ij
OTnJbNLUSa1SJ2mxtG2y/+TVk7tkXNl2OSIfL6/P14c/9RcV9oqfFA8LggoqCnqmJE85MFV3qmTq
xWmO0xZNe1YYUfjLdHw6f3rzDIsZc2Y8nMmauWUWMitjVvNsq9nzZ7cXRRbtnEOZkzPn97muc1fM
fTcvZV7DfNP5RfMf/xT5U3WxVrGs+OaCgAWbFuILxQtbFnksWrvoa4mg5Hypa2lZ6efF/MXnf3b7
ufznviWZS1qWei/duIy4TLLsxvLg5TtX6K4oXPF45aiVtauYq0pWvVs9cfW5Ms+yTWsoaxRr2sqj
y+vXWq9dtvbzOtG66xWhFXvXm6xftP7DBsGGKxtDNtZsMt1UuunTZvHmW1sit9RW2laWbSVuLdj6
dFvytjO/+P5Std14e+n2LzskO9p2xu88WeVTVbXLZNfSarRaUd2xe9zuy3vC9tTXONds2cvYW7oP
7FPse/Fr+q839nP3Nx/wPVBz0Obg+kP0QyW1SO202q46UV1bfWp96+GRh5sbAhoO/eby245Gi8aK
IwZHlh6lHJ1/tO9Y4bHuJmlT5/Gs44+bJzbfPTHmxLWTo0+2nOKeOns64vSJM6wzx84Gnm0853/u
8Hnf83UXvC/UXvS6eOh3r98PtXi31F7yuVR/2e9yQ+uI1qNXgq8cvxp29fQ1zrUL12Out95IunHr
5ribbbcEt57fzr39+k7Bnd67RfcI90ru69wve2DyoPIPhz/2tnm3HXkY9vDio4RHdx/zH798In/y
uX3+U9rTsmfmz6qeuz9v7IjouPxi7Iv2l9KXvZ3Ff+r+uf6V/auDf4X8dbFrTFf7a9nrvjeL3xq9
3fHO811zd1z3g/d573s/lPQY9ez86PvxzKeUT896p3wmfS7/4vCl4Sv3672+vL4+KU/G6/8VwKCg
mZkAvNkBAC0VADo8t1HGqs6C/Q1RnV/7EfhPWHVe7G/eANTATvkbz24CYB8U2yLIDUX5C58YAlAP
j0FRN3mmh7uKiwpPQoSevr63pgCQGgD4Iuvr693Q1/dlGwz2NgBNk1VnUGUjwjPD5jAlur2SWwR+
aKrz6Xc5/tgDZQSe4Mf+X2p7kCl8AKkGAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAA
ADqYAAAXcJy6UTwAAAHmUExURf////39/fv7+/b29vHx8ezs7Ovr6+jo6OPj497e3tra2uHh4eXl
5fT09Pj4+Nzc3O7u7t/f3/r6+vf39+rq6tHR0qiuspGYn4mRmLK1ucnLzZifpmBseVllcomRmbi7
v2h0f6itsamussjKzGBseNna2nmCi3mCjLq9v3F7hnqCi9LS02lzf2l0f5mgptLT1HJ8hZmgpWhz
f9HT1Obm5tnZ2qCnrNHS04GKkqitssDDxnF7hcHExoGJkqGnrPPz8+fn59HR08jKzZmfpcHDxsDC
xbm8v5mfpsjLzYmQmLC1uenq7bK2um14g6Gor6uyuPP09sDFyf7+/2Nve/7//3iBjPP19vT19mFs
eWpzf8nMzZaepmFtecHEx7a8wd/h5JKZn9XY3IKLlWt1gejp63eCjOrs7ba7wMvP05efpt/i5Orr
7sDFypefp9/j5Y2Vnurs7urr7enr7WNvesHFyfP19/P0997h46yyuNTX26Cnrv7+/qqxt9nZ2bzA
w5ifpaGmrG54g6CmrJCYn2RweqCmq+/v74iQmWRwe62zuFpmcqKpsIOMlm55hMvO0o6WnnmCje3t
7a2zucHFyrG2umRwfKius4OLld/i5cDEyLW7wL/EyJafpcrO0sDEyZigp7e9wba8wCBEKasAAAAB
YktHRACIBR1IAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5QkIBA8rbtQnXAAAFHxJREFU
eNrtnfl7G8UZx73WaVu2ZBGjxKAkVXAsCCY9CITLFExDaaM4J2kpocQtaQKlKUnK0ZAelKapm9CG
XrT04j+t5EPa1c7sjqTZ952Z/X5+y/NE8mrm88z7zjvHjo0BAAAAAAAAAAAAgAHwxjPZbC5fKBSL
E20mu7T/MVUslgr5XHY6M+NxPydIkPHpbK5QnChPqjJVLOSymXHu5wY68Way+dKEsgNhKUr56QwG
CusZn66MokFQiRyMsBUvo82DHrOlCoywDG86P6XbA98YkZ+GEHaQrAgQwh4yuWLyImxTrMxw/14g
wcvk1aeNmpgtTHP/bBDCyxbJTdiilEXAMIi2CUwibFGED2bgZdjGhMD4gHjBDkOeIGO2kOFujTTj
5QgmkQP5kMVKBg8Z5kRBDIYHeryKYYNCDwwPtFTNyRRElAvQgQoz40MQRAsSbFChw1SWu6WcJ6t9
VTo5ZqFDgng2qQAdEsU2FaBDYtioAnRIhIyxZQXoQIwtMwgZU/dwt6AzVEvcnTk6KENpwcsZXW1U
JoctDyOTdUOFSaQOIzNjebIQZAqxYni8Hdzdp5sdiBVDkrG0shAFYsVQVJ2KED0wrxicijOJYz8Y
HAbE1WFhkxIGhwFwd1jYBIODMp7Tw8ImyBzUyDg+LGyCwUEB92oLMnZwN7XxVB2sLciYRaiIxPXM
MUi5wt3eBpOeELENytMy0hQitkGoEOPOYvUglDGrEJDj7hYuctwtbxxpKDTJQHU6SBrThR5IHPyk
o+gop4w7gLpUuDuDHyQOW6Q2dfQDGzp4Be5+MIMS6k9jVYvPzekFe6fTPY0IkvZJBVzwk24bZlI+
pexnNsXX1MOFfsqptSHL3fQmktJ1K7ggJJU2wAUJKbQBLkhJnQ1wIYKU2QAXIkmVDXAhhhTZMMPd
1uaTmnoDak3xpKX6hPUIFcqpWKeAC2qkYdUKLqjivg0e9rIoM+X63icHbv6lo8DdW8mCva8D4fQu
WbgwIBXuHkuOae62tQ9nX4JXRbFpYFwtN2BSOQyzbk4pMJEYiiJ3vyUBkschcXBKgVXroXFuPRvJ
4/C4lkR6SB5HwLEkMnV3+unFqctkcRvHiFS4e1AfSBhGxaG0AQnDyDiTNiBh0IAjaQMqDFpwYskK
SxJ6KLsQKHB/lyYcWKRAkNBGhbsvRwVBQh/Wzy+xbq0RywMFgoRWKtz9OQoIEnqxOlBgJqGZEneP
Dk/SQWLu3tpOo6jtmk/4J1tbekp4E8PcfffXzWN3sjpYu0aR7JrEHhNVaLN3V6I/29I1imqijfIl
7k6X00j0h9t5i0ei5633GToudHhgPslfbmWxIdHscWE/d49HsZjkT7dxs3Sy2eMe7v6Oppnkb7dw
+TLZMzO7ubs7mlqiP966UzXJZo+TD3J3dzR7E/3x1tUhk609LnD3dgwPJPrrbatDJnztp+ky1JP9
+ZbVIRO+xivtMlg1vUx6USLtMlg1NCS9cp16Gaa4e1idxLe0pF4GiypPiW9pgQyz3H2sSvJ73SCD
NUND8nvdIIMtQwPBJljIYMvQQLAJFjJYMjRQ7I6HDJN2DA0Uu+Mhw6QVQwPJsRnI0MH8MiTJy0Ug
QwfjVygyJM0AGTYwfWigOUMFGTYwfGhIeIMTZAhi9pYnosOVA8jwUPNA6OMHGg9Hf2ipEf7QZHOA
jZdEMhh9oobqwkd1GR4Rf8HByE2UXz4g/tRXTJPB6I3SVNcxKMuwU/YNByMO4Xx1XvYp5dMaRO1g
9IUNVNcxKMsgP8BQG8KgyaZpMhi8yYVmXjmIDPKvaMg/JD8r+TXTZDB4dkl2NweXDBr+rmaMnV0S
zSshgx9TU0i627wgQxdTz9rR3eYFGboYmkKSpY+QwY+ZKSTh1W6QoYeZVUjCOx8hQw8jq5CUbz2H
DD5MjBOPEv5+yODDwFKDR/n7IYMPA+ME6ZXhkMGPeduki5Q/HzL4MS5OkEYJyBDEtDhB+2IJyBDA
tDhBOZeADH2YFidofz1kCGDYfIKy4qSnU4aS4ZCZMhhWdyJ+5YyyDAekX/HYMDI8bqgMZq1PEL+L
SlkGeb8eln9Ifg/4E4bKUObufz8J3wEaQlmGvbKh4cmoT8m20c4tGSqDUXGiQvzb1c9NPDUv/Pye
yG7d+7Twr849pfxnidvDqP1OpOXHyYFOVO3d3Qjx2GLcp55ZDn3o3toA7zuhlsGgySVt+XEwGXig
bhCDipDEE0vIEOZZbge6JPuaOsiggDmTS5LbWiBDFMZskqY7PAMZpJiSNJCnDJAhjClJA3nKABnC
mJI0kKcMkCGMIUkDeZUBMogwI2kgPFYHGeSYsTxBvTABGYRUuD3YgHbHG2SQYMbLLon3MkAGMUbc
K05fcsI9kEJMyCAZ8kfcAynChLJTjuF34x5IARVuE8ZY8kfcAynChAySvv6IeyCFGJBBMtQfcYhG
DH8GyZE/QgYh93C7wLB+radTHJSB//wt/fq1nk5xUAb+VWyOyQRkEMI/neCYTEAGIfzTCZafDRmE
cLvAsTIBGSRwv7+MZWYJGcRwr07Q3uWksVNclIF7bsmxTAUZJHCfxf46y6+GDEIKzDJQH8bX1iku
ysB9MJ+lzAAZxHAXGnh+NWQQwny1E8sCNmSQwbuITX2zl75OceoeyG14q048NSfcAymB91QVy24G
3AMpg7fqxFOAxD2QEnhl4ClA4h5ICbwlSJZ9TrgHUgbvXieeajTOWkrgrUezbHqDDGbKwLM0ARkk
8C5OQAbI0IVnnQoySOBdqWK4qAMyQAbIABkggz0yMP1oyCABMhgIZIAMkAEyQAbIABlskyE990B2
gAxRWHoP5MJzzzcbjZWVnT1WVhq7mi88vgAZhpXBunsgD+3btVx75uGIp3tqsbbcfEHiBGSIwKZ7
IA99497aouoGmvsXaw2BEZBhqE4x6x7I546oe+A3YqUZFIJVBtPL0ZplSOYQzcEXY7fhRbC4/GT3
m7A2Yb0MyuONjL21JmRwRIbJpVFt6PjwTcjghAwvjS5Dm8UG8yvsTN/2ZocMy1pkaA8P3/o2ZLBd
hqOaZKjXW8cYdTD93IQdMsxrk6Gtw+pxyGCzDAuDlxiidDhxkkcG04/X2SFD1JHwoXTgiRWmH7y1
RIaa6veqssoxOJh+JN8SGRqq36sMx+AAGbTIoDOD3OLUCXIZTL/GxxIZ9GaQW6xSy8BzqbxzMqhv
lBiEY6dpZcDVf3pk0J5BbnDmZVobypBBhwz6M0gOG3hWqpy7B/LpZGSonzlLKYPhF4nbcg9kaEvD
d757eOdKo9FsvrJJs3m0sVI7vH/QTPN7lDIY/ooBa+6B7O3WfnV/rXHuFel/PNA8WhtEiWOEMhj+
8hFr7oF8acuDo68o/fdzjd0PKz7Ka3QyGP5aImvugVx+9fDyucE+cq6hNCE99X0yGXjuC3bvHsgD
A3+iw7yKDy2yJNLwVxlyQdcU8/GH/ujSBsjAK0Nbh9fjhqzzVDKwFBogQ1CHmNFhjUoGs1+Mng4Z
2jpEzy2oVjBZ9jpBhhCRJ8RPEeWQLHNLyBDmXNTgQLSczfKWKsggYP4H8uc5RSMDyyI2ZBDxwzfk
D0Q0oeCYTkAGAVNjZ1vSByKaUHBMJyCDgNLY2AW5DTQpZAUymCFD5wVV56VPRLNexbE6ARkEbLzU
8keyJ6KJExyrE5BBwOa7j9dkj0QTJxgySMgQZuuiDmmguEgiA0MGCRnClLa645LkkWjWLhkySMgQ
prLVHW9KHokmaWA4VQUZwnRfii7JGmiKkAw1SMgQxtvujxOSZ3qLxAb6V9hBhhC9u73OnhI/E00G
Sb+KDRlC+F6DLYkTPyaRgb7sBBlCPNvrj9fEz0SzjE2fNECGEF6vPy6Kn4loXyx50gAZ+vFfB3pB
/Exv08hAnjRAhn52+PuDVQbypAEy9POsvz/E0wkiGciTBsjQj+fvjxanDOQH8/XI8FD4DF1jWfU8
q1kyFAPdwSsD9VlsLTJI3mWm/lYyg2SoGCQD9fKEDhkekX356xbKcE+gO8Q5A9mRS+KrnXTIMC/7
8oMa7uEjdqHvpSPiZyK7CpB4cqlBBvn1LDru4SOWoRDoDEmdgezWDuI4oUEG+fsKdbwYhliGwMRS
ttmJ7Cy2RxsnIEOQYGdI1iZ+QiUD8eQSMgQoBftCsvGN7hZA2vO3kCFANtgX4lN2RMctO9DGCcgQ
IFB+HDsrfiSyGzvGiOMEZPDTFyV4V7A3II0TkMFPX5SQpAw0u942IY0TkMFPMEpIitGEk4kx2jgB
GXz0RQlJlYEwfxyjrTtBBh/BipMsSlwmlYEyTkCGHv0vQ2+Jn4jwCukOhOsTkKFHcF1CeoSGNGUg
jROQoUcm2Ast8QO1aF2g3CQNGbr0RQnZwHCJWga6/U6QoUtfkaEleSCyJctt6PbFQoYu40oDA3mU
ICw1QIZtgunjT1uS5yGPEoQpJGTYJpg+ym5tqb9DLwNZCgkZtgimj7IgQbpi2YXqRh/IsEUgfbxy
VfY41zhkoKpCQoZNyv708UpL9jQM6WMHoiokZNjEnz7+TOoCz8BAdkMoZNjENzC8e0b6MEwDA9Xs
EjJs4BsY5DGCbWCgml1Chg1688r3Ilw4w+UC0dAAGTr0bmt5LepZOGoMlEMDZOiwPa9891LUo7zP
5wLNveKQYbJXcDrfinoStuxxA4pt0pBhcntgePdY9JMwBgmioQEybA0Mpz9oRT8I8W43jqEBMmwO
DMd/HvMclCdnuIYGyNAZGK7HDQucs0q6oQEytAeGK2txT9FiThg2SHxogAyzxz+8GvcQRriQ/NBw
I0kZblggw8IvWrHPYIYLSW1yOVLbuc3o3bV3p5QlDaptU5tPoiX2LMY/gikuJFKGXPjl6H3EwYva
W2LfboU/a4wLiaxQrHD36rD8Sm87zN2ncjXhGXNcSGBoOLTE3anD8sABchXqx+gucFKgpFuGI9x9
OjxNbY1w44kllT94lbvu2EdV927IBneXDk9DUxMs7HlK6e+ZFCI20X3WDjKoTCE6mBUiNtC9UTrt
MsypTCHa/Jr8VKUKmitP6ZbhxkeKt5u/b96wsIHe6WWqZVBMFuprb3J3uowZyKBHhrnfqP2djw2b
RATIQwYdMuxZUvszBiaOPjydq5dpleHQb9X+yJqRiaMPnXXI5k5rGaXotE9tPml0hNhCex0ybSiG
CFPnEAG01yHTxYJaiFgzruIo5iZ3e9rMIaVZhPHJQg/q1586xNzvFFS4xXaqdggQKIZlTqHQdOv3
NiQLPRAohkPBhfU/2KXCGALFcNyIdWH9tiV5ox/itxy6QWzuuH7bnrzRD/WLsl0gbvPvnU+4e3VY
tK5RpIIn3Mob/Whdo0gD+5YiI8QfLUwWemB+ORCHItcj7tiZLPTA/HIQPnJrOhkCaYM6UUHiT8Zu
ZRoApA3q7I4YFrj7UQ9IG1TZ52620IX09cgWs7Bf5sJt+7OFLne5m9kO9shCxKfcHagVLFKocFhS
Z/ozd/fpBUmkApKM4ZYLs4gASCLjEU8l7lhdcxSDJas4xAPDLQddQCUyFuEK1bq1S5TRYEoRycKD
Ihncmkf4KHC3t9EIo8Qd7j5LDI/u7ekWUhMFCScThk2qmGBKEUYJ1peHwAY2RFHCxVml3waUGyQs
C2Sw6ZDMMMzABjGCNSp3s8euDdytbiaPpy1j2ATr2SIE9566PJWADVEIJpaXuDsKNjAhSBkucvcT
bODhRhrTR9gg5khaowRsCPNYaqMEbAgR3vC2zt1DlKD65GNhKSTDbe4OorUB6xRdng9HCQsud9QJ
Vq26/CUsgzOHZmDDgIRLTn/l7hx6G7DbZZOn/3b546AMf+fuG3o87ITboOCNjX127dIb610Z+F9x
zwB2yba5u90aZ8+vrn2ctioDbPBzM9giG0OEQwdtB2E65QWHckbQKCl1Ie2Titlx7vY3i2qKX01R
9Lhb3zhSmzjcHb3t3CObysShnOVudzNJY+KAdEGGl7obAvNIF+TcTFWoKN8cvcVcJk2hAiEiltSE
CoQIBbKpGByERUcQopqCdUwUmpRxfXBA5jgI1Ue5+ytJisgcB8PdwQHDwuC4mjmUMCwMg4uDwywm
EUPiXnkatYURcGvvdHGGuz0tx51YEbVYffp6m9PcbW0+VTd2vZTvSiPE8X+8feZqvX71zOXV89e5
m9t0XJhXyOcQxz+86j85c/mTk9ztbTi2pw5F6Rzi+j+v9p+qa53gbm7TsTl1mJJPJ69/KLjur772
Fndzm46tOsxG5I0nhS60B4fPuFvbeGzUYTZyv+vndQmnYEMstukQrcLYlZZMhnoLkSIem3SYjdsF
/6+6nDXulraCrCUzi2LsKsSVehRpu7ZlSDIW1B3iVYgeGDA0KFMtGL2lvpxXWaaOHhjqdWQNqlTN
TR6mKmpLk5/HyJCyu95Gw8xooRIfNji5FiPDZe4Gtgvjhoepu+r7FeKiRL3F3bzWkSkY40M5P9Au
pn/HyZDWa3xGYtqEez7KxcyAm5guxcmADHIovGyR2YTswNvZTr8BGZLCy3KND8OY0OZ6rAuQYRSm
6fOHdp4w5BbX92JdQM4wIjMVwoBRvDvCvvcP4mXgbkwH8KbzBKsXU/np0Xa9/ydWhkXulnSEaqJC
jCxCh9VYGVB00oeXqZS05xATpcqwSUIf8TKscrega3iZXEnTGNH2QMOA0CVeBqxhJ4GXmc6PosRE
KZ+d0X0u7gQmE5yMZ7K5QlFdivJEsZDLTid0aPpinAzpelsZF95MZjqbyxdKxeLUxIQvrWj/Y6JY
LBTyuWw2M57wEdnYdapr3O0EyDjZiokS73A/IaAjJoP8L/fzAUKuYGAAXY5hYADbvByRNdzBwJAy
PjslDRKYSqQOmQ3rn3I/GaDnQgsugG0uCHZCrv+P+6kAE/06rN/GAlWKufDFWvf917egAjh/bfXY
26tfnMdKJQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANjL/wFhUjQx
WITDiwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wOFQwMToxNTo0MyswMzowMOvCdigAAAAl
dEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDhUMDE6MTU6NDMrMDM6MDCan86UAAAAKHRFWHRpY2M6
Y29weXJpZ2h0AENvcHlyaWdodCBBcHBsZSBJbmMuLCAyMDIxfb3uJgAAABd0RVh0aWNjOmRlc2Ny
aXB0aW9uAERpc3BsYXkXG5W4AAAAAElFTkSuQmCC"
    />
  </svg>
)

export const svgCheck = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9998 0.666656C6.63984 0.666656 0.666504 6.63999 0.666504 14C0.666504 21.36 6.63984 27.3333 13.9998 27.3333C21.3598 27.3333 27.3332 21.36 27.3332 14C27.3332 6.63999 21.3598 0.666656 13.9998 0.666656ZM11.3332 20.6667L4.6665 14L6.5465 12.12L11.3332 16.8933L21.4532 6.77332L23.3332 8.66666L11.3332 20.6667Z"
      fill="#008000"
    />
  </svg>
)
export const svgError = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
      fill="#F56E69"
    />
  </svg>
)

export const warningSvg = (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM27.5 37.5H22.5V32.5H27.5V37.5ZM27.5 27.5H22.5V12.5H27.5V27.5Z"
      fill="black"
    />
  </svg>
)
