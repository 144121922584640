import React, { useState, useEffect } from "react"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { deleteService, getAllServices } from "../../services/architechture"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteServiceModal = ({
  open,
  onClose,
  deleteServiceId,
  deleteServiceName,
  setSingleService,
  unitId,
  floorId,
  areaId,
  setValue,
  getAllSubServices,
  getDetails,
  setServiceName,
  onActivePopUp,
  setOpenPopUpDeleteService,
}) => {
  const classes = useStyles()

  const handleDeleteService = async () => {
    try {
      if (deleteServiceId) {
        const resp = await deleteService(deleteServiceId)
        if (resp) {
          const newServices = await getAllServices(unitId, areaId, floorId)
          setSingleService(newServices)
          if (getDetails) getDetails()
          if (getAllSubServices) getAllSubServices()
          if (setValue) setValue(0)
          if (setServiceName) setServiceName("")
        }
        onActivePopUp && onActivePopUp(setOpenPopUpDeleteService, true)
        onClose()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.deleteModal}
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <div className={classes.paperChild}>
            <div>{warningSvg}</div>

            <p className={classes.deleteText}>
              ¿Desea borrar el servicio <span className={classes.deleteTextSpan}>{deleteServiceName}</span>?
            </p>

            <small className={classes.small1}>Si borra el servicio, se eliminará todo lo que contenga.</small>
            <div className={classes.deleteButtonContainer}>
              <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                NO
              </Button>

              <Button variant="contained" className={classes.deleteButton} onClick={() => handleDeleteService()}>
                SI, BORRAR SERVICIO
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteServiceModal
