import { useState } from "react"
import clsx from "clsx"
import { makeStyles, useMediaQuery } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  { id: "floor", numeric: false, disablePadding: true, label: "Piso" },
  { id: "service", numeric: false, disablePadding: false, label: "Servicio" },
  { id: "inUse", numeric: true, disablePadding: false, label: "En Uso" },
  { id: "available", numeric: true, disablePadding: false, label: "Disponibles" },
  { id: "blocked", numeric: true, disablePadding: false, label: "Bloqueadas" },
  { id: "virtual", numeric: true, disablePadding: false, label: "Virtuales" },
  { id: "inTransit", numeric: true, disablePadding: false, label: "En Tránsito" },
  { id: "oncology", numeric: true, disablePadding: false, label: "Oncológico" },
  { id: "occupation", numeric: true, disablePadding: false, label: "Ocupación" },
]

const EnhancedTableHead = (props) => {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const matches = useMediaQuery("(min-width:600px)")

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            size={matches ? "medium" : "small"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusService: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2rem",
  },
  red: {
    background: "#F1948A",
  },
  green: {
    background: "#7DCEA0",
  },
  yellow: {
    background: "#F9E79F",
  },
}))

const TableServices = ({ detailsServices = [] }) => {
  const classes = useStyles()
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("calories")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, detailsServices.length - page * rowsPerPage)

  const formatStatusService = (num, classes) => {
    const per = Number(num)
    switch (true) {
      case per === 0:
        return <div className={clsx(classes.statusService, classes.green)}>{`${per}%`}</div>
      case per > 0 && per <= 50:
        return <div className={clsx(classes.statusService, classes.yellow)}>{`${per}%`}</div>
      case per > 50 && per <= 100:
        return <div className={clsx(classes.statusService, classes.red)}>{`${per}%`}</div>
      default:
        return <div className={classes.statusService}>{`--`}</div>
    }
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={detailsServices.length}
          />
          <TableBody>
            {stableSort(detailsServices, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`
                if (
                  row.floor === "INDEFINIDO - INDEFINIDO" ||
                  row.floor === "NO ASIGNADA - No asignada" ||
                  row.floor === "NO ASIGNADAS - No asignadas"
                ) {
                  return null
                } else {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={labelId}>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.floor}
                      </TableCell>
                      <TableCell align="left">{row.service}</TableCell>
                      <TableCell align="left">{row.inUse}</TableCell>
                      <TableCell align="left">{row.available}</TableCell>
                      <TableCell align="left">{row.blocked}</TableCell>
                      <TableCell align="left">{row.virtual}</TableCell>
                      <TableCell align="left">{row.inTransit}</TableCell>
                      <TableCell align="left">{row.oncology}</TableCell>
                      <TableCell align="left">{formatStatusService(row.occupation, classes)}</TableCell>
                    </TableRow>
                  )
                }
              })}
            {stableSort(detailsServices, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`
                if (row.floor === "NO ASIGNADA - No asignada" || row.floor === "NO ASIGNADAS - No asignadas") {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={labelId}>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.floor}
                      </TableCell>
                      <TableCell align="left">{row.service}</TableCell>
                      <TableCell align="left">{row.inUse}</TableCell>
                      <TableCell align="left">{row.available}</TableCell>
                      <TableCell align="left">{row.blocked}</TableCell>
                      <TableCell align="left">{row.virtual}</TableCell>
                      <TableCell align="left">{row.inTransit}</TableCell>
                      <TableCell align="left">{row.oncology}</TableCell>
                      <TableCell align="left">{formatStatusService(row.occupation, classes)}</TableCell>
                    </TableRow>
                  )
                }
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={detailsServices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default TableServices
