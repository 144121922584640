import { makeStyles } from "@material-ui/core/styles"
import { Button, Paper } from "@material-ui/core"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
  peper: {
    maxHeight: "17rem",
    height: "100%",
    maxWidth: "30rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "1rem",
  },
  text: {
    fontSize: "1.5rem",
    width: "100%",
    textAlign: "center",
    marginTop: "0.9rem",
  },
  button: {
    width: "100%",
    maxWidth: "12rem",
    marginTop: "2rem",
  },
  link: {
    textDecoration: "none",
    maxWidth: "12rem",
    width: "100%",
  },
}))

const UserNotAvailable = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.peper}>
        <p className={classes.text}>El usuario se creo correctamente.</p>
        <p className={classes.text}> Ahora un administrador debe de asignarle un rol.</p>
        <Link to="/login" className={classes.link}>
          <Button variant="contained" color="primary" className={classes.button}>
            Volver al Login
          </Button>
        </Link>
      </Paper>
    </div>
  )
}

export default UserNotAvailable
