import React, { useEffect, useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

import FormCovid19 from "./FormCovid19"
import FormGESCAEC from "./FormGES-CAEC"
import FormConsalud from "./FormGRDConsalud"
import FormGRDFonasa from "./FormGRDFonasa"

import { getAllPatientes } from "../../services/sheets"
import DashboardUnit from "../../contexts/dashboardUnitContext"

const useStyles = makeStyles({
  drawer: {
    width: "22rem",
    padding: "1.5rem",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  input: {
    margin: "1rem",
    width: "100%",
  },
  save: {
    background: "#30AFC0",
    color: "#ffffff",
    fontWeight: "600",
    borderRadius: "2rem",
    marginTop: "5rem",
    "&:hover": {
      background: "#30AFC0",
      color: "#ffffff",
    },
  },
  contentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  h6: {
    color: "#535461",
    letterSpacing: "0.15px",
    fontSize: "1.3rem",
  },
})

export default function DrawerSheets({ open, registerDate, mode, onClose, reloadData }) {
  const classes = useStyles()
  const [patientes, setPatientes] = useState([])
  const { detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    getDataForSelects()
  }, [])

  useEffect(() => {
    getDataForSelects()
  }, [detailsUnit])

  const getDataForSelects = async () => {
    try {
      const resp = await getAllPatientes()
      setPatientes(resp)
    } catch (error) {
      console.log(error)
    }
  }

  const typeFromRender = () => {
    switch (true) {
      case mode === "covid19":
        return (
          <FormCovid19
            registerDate={registerDate}
            onClose={onClose}
            reloadData={reloadData}
            patientes={patientes}
            setPatientes={setPatientes}
          />
        )
      case mode === "gescaec":
        return (
          <FormGESCAEC registerDate={registerDate} onClose={onClose} reloadData={reloadData} patientes={patientes} />
        )
      case mode === "grdconsalud":
        return (
          <FormConsalud registerDate={registerDate} onClose={onClose} reloadData={reloadData} patientes={patientes} />
        )
      case mode === "grdfonasa":
        return (
          <FormGRDFonasa registerDate={registerDate} onClose={onClose} reloadData={reloadData} patientes={patientes} />
        )
      default:
        break
    }
  }

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={classes.drawer}>
          <div>
            <div className={classes.contentTitle}>
              <h6 className={classes.h6}>{!registerDate ? "Agregar Registro" : "Editar Registro"}</h6>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
          </div>
          <div>{typeFromRender()}</div>
        </div>
      </Drawer>
    </div>
  )
}
