import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  reserve: {
    display: "flex",
    flexDirection: "column",
    padding: "3rem",
  },
  titleReserve: {
    color: "#6E6E6E",
    marginBottom: "2rem",
    fontSize: "1.7rem",
    textAlign: "center",
  },
  inputReserve: {
    marginBottom: "2rem",
  },
  form: {
    width: "24rem",
  },
  errorMsg: {
    color: "red",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dropReserveContainer: {
    display: "flex",
    justifyContent: "center",
  },
  dropReserve: {
    width: "85%",
    backgroundColor: "#DA5151",
    marginTop: "2rem",
    color: "#FFFFFF",
  },
}))

export default useStyles
