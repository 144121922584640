import { useEffect, useState, useContext } from "react"
import { getContingency } from "../../services/beds"
import CardBeds from "../../components/CardBeds"
import { refreshApi } from "../../utils/refreshApi"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { expiredToken } from "../../utils/expiredToken"

export default function Contingency() {
  const [subServices, setSubServices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(null)
  const { setDateUpdate, detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    getService()
  }, [detailsUnit])

  const getService = async () => {
    setIsLoading(true)
    try {
      const ser = await getContingency()
      setSubServices(ser.allCasesSubServices)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    refreshApi(setRefresh, 300)
  }, [])

  const style = {
    color: "#4CAF50",
    width: "100%",
    padding: "10px",
    fontSize: "20px",
  }
  const flex = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  }
  return (
    <>
      <div style={flex}>
        {subServices &&
          subServices.map((subService, idx) => (
            <>
              <div style={style} id={idx}>
                {subService.name}
              </div>
              {subService.beds && subService.beds.map((bed, index) => <CardBeds data={bed} key={index} />)}
            </>
          ))}
      </div>
    </>
  )
}
