import { Typography, Button } from "@material-ui/core"
import { useEffect, useState, useContext } from "react"
import { CustomContainer, Flexbox } from "../../components/common"
import { useParams } from "react-router-dom"
import { ChartGraphic, CardBeds } from "../../components"
import { getBedsByState, getDataForCharts, searchBedsForDashboard, getVirtualBeds, getOncologyBeds } from "../../services/beds"
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import { refreshApi } from "../../utils/refreshApi"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { expiredToken } from "../../utils/expiredToken"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import clsx from "clsx"
import { CSVLink } from "react-csv"
import GetAppIcon from "@material-ui/icons/GetApp"
import LoadingComponent from "../../components/LoadingComponent"

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "1.3rem",
    color: "#6E6E6E",
  },
  nameService: {
    color: "#4CAF50",
    width: "100%",
    margin: "2rem 0 1rem 0",
    fontSize: "1.2rem",
  },
  contentBedsGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  divider: {
    marginBottom: "3rem",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
  messagge: {
    margin: "0.5rem 0 0 0.5rem",
    color: "#ff0000",
    fontSize: "1rem",
    fontWeight: "600",
  },
  contentAdd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  link: {
    textDecoration: "none",
  },
  iconAdd: {
    backgroundColor: "#009DB1",
    marginRight: "2rem",
    marginBottom: "0.5rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
  },
}))

const GroupBeds = ({ servicename, reloadChart }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.nameService}>{servicename.name}</div>
      <Divider className={classes.divider} />
      <div className={classes.contentBedsGroup}>
        {servicename &&
          servicename.beds.map((service, index) => (
            <CardBeds data={service} key={service.code} reloadChart={reloadChart} />
          ))}
      </div>
    </>
  )
}

const BedsScreen = () => {
  const classes = useStyles()
  const params = useParams()
  const { state } = useParams()
  const [subServices, setSubServices] = useState([])
  const [styleCard, setStyleCard] = useState([])
  const { detailsUnit } = useContext(DashboardUnit)
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [serchAvailable, setSerchAvailable] = useState(true)
  const [thereResult, setThereResult] = useState(true)
  const [respSearch, setRespSearch] = useState([])
  const [searchRequest, setSearchRequest] = useState({
    stateId: state,
    patientRut: null,
    patientName: null,
    professionalName: null,
    admissionCode: null,
  })
  const [busyBeds, setBusyBeds] = useState([])

  const [chartData, setChartData] = useState()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getBeds()
    setConfigCards()
    if (state === "2") {
      getDataCharts()
    }
  }, [state, detailsUnit])

  useEffect(() => {
    if (!!filter && !!search) {
      getSearchBeds()
    }
  }, [searchRequest])

  const getSearchBeds = async () => {
    try {
      const resp = await searchBedsForDashboard(searchRequest)
      setRespSearch(resp.subServices)
      setThereResult(resp.subServices.length)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
      setThereResult(false)
    }
  }

  const myFunc = () => {
    const arr = []
    let arrToPush = []
    subServices?.map((x, i) => {
      return x?.beds.map((y, i) => arr.push(y))
    })
    arr.forEach((bed) => {
      bed.unit = detailsUnit?.unit.filter(({ id }) => id === bed.unitId)[0]?.name
      bed.area = detailsUnit?.unit[0].areas.filter(({ id }) => id === bed.areaId)[0]?.name
      bed.floor = detailsUnit?.unit[0].areas
        .filter(({ id }) => id === bed.areaId)[0]
        ?.floors.filter(({ id }) => id === bed.floorId)[0]?.name
      bed.service = detailsUnit?.unit[0].areas
        .filter(({ id }) => id === bed.areaId)[0]
        ?.floors.filter(({ id }) => id === bed.floorId)[0]
        ?.services.filter(({ id }) => id === bed.serviceId)[0]?.name
      bed.subServices = detailsUnit?.unit[0].areas
        .filter(({ id }) => id === bed.areaId)[0]
        ?.floors.filter(({ id }) => id === bed.floorId)[0]
        ?.services.filter(({ id }) => id === bed.serviceId)[0]
        ?.subServices.filter(({ id }) => id === bed.subServiceId)[0]?.name
    })
    arrToPush = arr.map((bed) => {
      const objCsv = {}
      objCsv.patientRut = bed.patientRut
      objCsv.code = bed.code
      objCsv.stateId = bed.stateId
      objCsv.unit = bed.unit
      objCsv.area = bed.area
      objCsv.floor = bed.floor
      objCsv.service = bed.service
      objCsv.subServices = bed.subServices
      objCsv.roomCode = bed.roomCode
      objCsv.admissionCode = bed.admissionCode
      objCsv.reasson = bed.reasson
      objCsv.patientName = bed.patientName
      objCsv.strAge = bed.strAge
      objCsv.stateDescription = bed.stateDescription
      objCsv.sex = bed.sex
      objCsv.prestationCode = bed.prestationCode
      objCsv.complexityChangeCode = bed.complexityChangeCode
      objCsv.strStayTime = bed.strStayTime
      objCsv.professionalName = bed.professionalName
      objCsv.bedLastChangedDate = bed.bedLastChangedDate
      objCsv.patientDischargeDate = bed.patientDischargeDate
      objCsv.grd = bed.grd
      objCsv.urgencyLaw = bed.urgencyLaw
      objCsv.stable = bed.stable
      objCsv.isapre = bed.isapre
      objCsv.isaprePlan = bed.isaprePlan
      objCsv.treatments = bed.treatments
      objCsv.interconsultations = bed.interconsultations
      objCsv.covid19 = bed.covid19
      objCsv.hasTreatments = bed.hasTreatments
      objCsv.hasInterconsultations = bed.hasInterconsultations
      return objCsv
    })
    setBusyBeds(arrToPush)
  }

  useEffect(() => {
    if (params.state === "3") {
      myFunc()
    }
  }, [subServices])

  const handleChangeSearch = (event) => {
    setSearchRequest({ ...searchRequest, [`${filter}`]: event.target.value })
    setSearch(event.target.value)
  }

  const getDataCharts = async () => {
    try {
      const resp = await getDataForCharts(detailsUnit.unit[0].id)
      setChartData(resp[0])
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    }
  }

  const getBeds = async () => {
    try {
      setIsLoading(true)
      if(state === "oncology"){
        const resp = await getOncologyBeds()
        setSubServices(resp.subServices)
      }
      else if (state !== "virtuals") {
        const resp = await getBedsByState(state)
        if (
          (state === "1" || state === "2" || state === "3" || state === "4" || state === "5") &&
          resp.subServices.length > 0 &&
          detailsUnit?.unit[0]?.name === "Providencia"
        ) {
          const filterUndefined = resp.subServices.filter((el) => el.name === "INDEFINIDO")
          const filterPOQ = filterUndefined.map((el) => el.beds.filter((bed) => bed.code.includes("PO"))).flat()
          const filterNotPOQ = filterUndefined.map((el) => el.beds.filter((bed) => !bed.code.includes("PO"))).flat()
          const filterNotUndefined = resp.subServices.filter((el) => el.name !== "INDEFINIDO")

          if (filterNotPOQ.length > 0) {
            filterNotUndefined.push({ name: "INDEFINIDO", beds: filterNotPOQ })
          }
          if (filterPOQ.length > 0) {
            filterNotUndefined.push({ name: "Pre Operatorio", beds: filterPOQ })
          }

          setSubServices(filterNotUndefined)
        } else if (resp.subServices.length > 0) {
          setSubServices(resp.subServices)
        }
      }
      else {
        const resp = await getVirtualBeds()
        setSubServices(resp.subServices)
      }
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const setConfigCards = () => {
    switch (state) {
      case "1":
        setStyleCard(["#4CAF50", "#E8F5E9", "136px", "Disponibles", "rgba(255,255,255,1)"])
        break
      case "2":
        setStyleCard(["#E8F5E9", "#6E6E6E", "136px", "Bloqueadas", "rgba(0,0,0,1)"])
        break
      case "3":
        setStyleCard(["#feebee", "#f2453d", "376px", "Ocupadas", "rgba(0,0,0,1)"])
        break
      case "4":
        setStyleCard(["#FFF3E0", "#FF9800", "376px", "Pendientes de recepción", "rgba(255,255,255,1)"])
        break
      case "5":
        setStyleCard(["#4CAF50", "#E8F5E9", "136px", "En Tránsito", "rgba(255,255,255,1)"])
    }
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
    setSearchRequest({
      stateId: state,
      patientRut: null,
      patientName: null,
      professionalName: null,
      admissionCode: null,
    })
    setSearch("")
    setSerchAvailable(false)
  }
  return (
    <Flexbox justifyContent="space-between">
      <CustomContainer>
        <Flexbox justifyContent="space-between" alignItems="center">
          <Typography className={classes.title} variant="h4">
            {styleCard[3]}
          </Typography>
        </Flexbox>
        {chartData && state === "2" && (
          <ChartGraphic chartData={chartData.reassons} totalBeds={chartData.reassonTotals} />
        )}
        <div>
          <FormControl variant="outlined" className={classes.formControl} size="small">
            <InputLabel id="selected-filter-label">Filtrar por</InputLabel>
            <Select
              labelId="selected-filter-label"
              id="selected-filter"
              value={filter}
              onChange={handleChangeFilter}
              label="Filtrar por"
            >
              <MenuItem value="patientRut">RUT</MenuItem>
              <MenuItem value="patientName">Nombre Paciente</MenuItem>
              <MenuItem value="professionalName">Nombre Doctor</MenuItem>
              <MenuItem value="admissionCode">Admision Codigo</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small">
            <InputLabel htmlFor="search-input-label">Buscar</InputLabel>
            <OutlinedInput
              id="search-input-label"
              type="text"
              value={search}
              onChange={handleChangeSearch}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={45}
              disabled={serchAvailable}
            />
          </FormControl>

          {!thereResult && <p className={classes.messagge}>No se encontraron camas.</p>}
        </div>
        {respSearch.length > 0 ? (
          <CustomContainer lenght="0px" marginTop="20px" width="98%">
            {respSearch &&
              respSearch.map((s, index) => {
                return <GroupBeds servicename={s} key={index} />
              })}
          </CustomContainer>
        ) : isLoading ? (
          <div style={{ display: "flex", alignItems: " center", marginTop: "1rem", flexDirection: "column" }}>
            <p style={{ marginBottom: "2rem", fontSize: "16px" }}>La consulta está siendo procesada...</p>
            <LoadingComponent />
          </div>
        ) : (
          <CustomContainer lenght="0px" marginTop="20px" width="98%">
            {params?.state === "3" ? (
              <div className={classes.contentAdd}>
                <CSVLink data={busyBeds} filename={"busyBedDetails.csv"} headers="" className={classes.link}>
                  <Button variant="contained" className={classes.iconAdd} startIcon={<GetAppIcon />}>
                    Exportar a CSV
                  </Button>
                </CSVLink>
              </div>
            ) : null}
            {subServices &&
              subServices.map((service, idx) => {
                if (service.name !== "INDEFINIDO")
                  return <GroupBeds servicename={service} key={idx} reloadChart={getDataCharts} />
              })}
            {subServices &&
              subServices.map((service, idx) => {
                if (service.name === "INDEFINIDO")
                  return <GroupBeds servicename={service} key={idx} reloadChart={getDataCharts} />
              })}
          </CustomContainer>
        )}
      </CustomContainer>
    </Flexbox>
  )
}

export default BedsScreen
