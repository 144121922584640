import { CardBeds } from "../../components"
import Divider from "@material-ui/core/Divider"
import useStyles from "./styles"

const GroupBeds = ({ servicename }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.nameService}>{servicename.name}</div>
      <Divider className={classes.divider} />
      <div className={classes.contentBedsGroup}>
        {servicename && servicename.beds.map((service, index) => <CardBeds data={service} key={index} />)}
      </div>
    </>
  )
}

export default GroupBeds
