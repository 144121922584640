import React from "react"
import { HistoryHeader } from "./components/HistoryHeader"
import { HistorySubHeader } from "./components/HistorySubHeader"
import { useState } from "react"
import { FilterHistory } from "./filter/FilterHistory"
import { CurrentAnalysisHistorical } from "./components/Charts/CurrentAnalysisHistorical"
import { TotalBedsOcuppied } from "./components/Charts/TotalBedsOcuppied"
import { useEffect } from "react"
import { useCallback } from "react"
import { useRef } from "react"
import { useGetGraph } from "../../hooks/graphs/useGetGraph"
import { useGetExcelData } from "../../hooks/graphs/useGetExcelData"
import { BedBusyPercentage } from "./components/Charts/BedBusyPercentage"
import { AvailableBedsPercentage } from "./components/Charts/AvailableBedsPercentage"
import { HistoricalMedicPlanAttendant } from "./components/Charts/HistoricalMedicPlanAttendant"
import { HistoricalServiceFloor } from "./components/Charts/HistoricalServiceFloor"

export const HistoryView = () => {

    const [dataHistoryFilter, setdataHistoryFilter] = useState(
        { typeReport: null, from: null, to: null, idUnits: [] },
      )
    const { graphData, setgraphData, handleGetGraphData } = useGetGraph()
    const { excelData, handleGetExcelData, setExcelData } = useGetExcelData()


  return (
    <>
        <HistoryHeader />
        <HistorySubHeader />
        <div style={{ marginTop: 20}}>
            <FilterHistory 
                filterHistory={dataHistoryFilter} 
                handleGetGraphData={handleGetGraphData} 
                setFilterHistory={setdataHistoryFilter} 
                setgraphData={setgraphData} 
                handleGetExcelData={handleGetExcelData}
                setExcelData={setExcelData}
                excelData={excelData}
            />
        </div>
        <div style={{ marginTop: 20}}>
          {
            (dataHistoryFilter.typeReport === 1 && graphData.length !== 0) &&
            <CurrentAnalysisHistorical key={graphData} filterHistory={dataHistoryFilter} data={graphData} />
          }
          {
            (dataHistoryFilter.typeReport === 2 && graphData.length !== 0) &&
            <TotalBedsOcuppied key={graphData} data={graphData} />
          }
          {
            (dataHistoryFilter.typeReport === 3 && graphData.length !== 0) &&
            <BedBusyPercentage key={graphData} data={graphData} />
          }
          {
            (dataHistoryFilter.typeReport === 4 && graphData.length !== 0) &&
            <AvailableBedsPercentage key={graphData} data={graphData} />
          }
          {
            (dataHistoryFilter.typeReport === 5 && graphData.length !== 0) &&
            <HistoricalMedicPlanAttendant key={graphData} data={graphData} />
          }
          {
            (dataHistoryFilter.typeReport === 6 && graphData.length !== 0) &&
            <HistoricalServiceFloor key={graphData} data={graphData} />
          }
        </div>
    </>
  )
}
