import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { expiredToken } from "../../utils/expiredToken"
import { TableCovid19, TableGesCaec, TableGrdConsalud, TableGrdFonasa } from "../../components"
import { getAllBedsCovid19, getAllBedsGesCaec, getAllBedsGrdCosalud, getAllBedsGrdFonasa } from "../../services/sheets"

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
  },
  tab: {
    color: "#000000",
    letterSpacing: "0.75px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
}))

export default function Sheets() {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [detailsCovid19, setDetailsCovid19] = useState([])
  const [detailsGesCaec, setDetailsGesCaec] = useState([])
  const [detailsGrdCosalud, setDetailsGrdCosalud] = useState([])
  const [detailsGrdFonasa, setDetailsGrdFonasa] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    getDetailsAllBedsCovid19()
    getDetailsAllBedsGesCaec()
    getDetailsAllBedsGrdCosalud()
    getDetailsAllBedsGrdFonasa()
  }, [])

  const getDetailsAllBedsCovid19 = async () => {
    setIsLoading(true)
    try {
      const resp = await getAllBedsCovid19()
      setDetailsCovid19(resp)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      } else if (error?.status === 404) {
        setDetailsCovid19([])
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getDetailsAllBedsGesCaec = async () => {
    setIsLoading(true)
    try {
      const resp = await getAllBedsGesCaec()
      setDetailsGesCaec(resp)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      } else if (error?.status === 404) {
        setDetailsGesCaec([])
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getDetailsAllBedsGrdCosalud = async () => {
    setIsLoading(true)
    try {
      const resp = await getAllBedsGrdCosalud()
      setDetailsGrdCosalud(resp)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      } else if (error?.status === 404) {
        setDetailsGrdCosalud([])
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getDetailsAllBedsGrdFonasa = async () => {
    setIsLoading(true)
    try {
      const resp = await getAllBedsGrdFonasa()
      setDetailsGrdFonasa(resp)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      } else if (error?.status === 404) {
        setDetailsGrdFonasa([])
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          <Tab label="Covid 19" {...a11yProps(0)} className={classes.tab} />
          <Tab label="Ges-caec" {...a11yProps(1)} className={classes.tab} />
          <Tab label="Ley de Urgencia" {...a11yProps(2)} className={classes.tab} />
          <Tab label="Grd Fonasa" {...a11yProps(3)} className={classes.tab} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableCovid19
          detailsCovid19={detailsCovid19}
          setDetailsCovid19={setDetailsCovid19}
          reloadData={getDetailsAllBedsCovid19}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableGesCaec
          detailsGesCaec={detailsGesCaec}
          reloadData={getDetailsAllBedsGesCaec}
          setDetailsGesCaec={setDetailsGesCaec}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableGrdConsalud
          detailsGrdCosalud={detailsGrdCosalud}
          reloadData={getDetailsAllBedsGrdCosalud}
          setDetailsGrdCosalud={setDetailsGrdCosalud}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableGrdFonasa
          detailsGrdFonasa={detailsGrdFonasa}
          reloadData={getDetailsAllBedsGrdFonasa}
          setDetailsGrdFonasa={setDetailsGrdFonasa}
        />
      </TabPanel>
    </div>
  )
}
