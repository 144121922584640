export const handleExcelData = (type, data)=>{ 

    let dataParsed = []
    switch (type) {
        case 'Análisis por estado de camas':
                dataParsed = data.map( element => ({ 
                    "unidad": element.unitName,
                    "fecha": element.day,
                    "Disponibles": element.available,
                    "Ocupadas": element.busy,
                    "En recepción" : element.atReception,
                    "Bloqueadas": element.blocked,
                    "En transito": element.inTransit,
                    "Reservadas": element.reserved
            }))

            return dataParsed
            
        case 'Dias Cama ocupados totales':
                dataParsed = data.map( element => ({ 
                    "unidad": element.unitName,
                    "fecha": element.day,
                    "Ocupadas": element.busy
            }))

            return dataParsed

        case 'Ocupación días camas':
                dataParsed = data.map( element => ({ 
                    "fecha": element.day,
                    "unidad": element.unitName,
                    "Ocupadas": `${element.averageBusy}%`
            }))

            return dataParsed

        case 'camas disponibles-totales':
                dataParsed = data.map( element => ({ 
                    "fecha": element.day,
                    "unidad": element.unitName,
                    "Disponibles": `${element.averageAvailable}%`
            }))

            return dataParsed
        case 'historico media medico':
            dataParsed = data.map( element => ({ 
                "medico": element.name,
                "plan": element.plan,
                "piso": element.floorName,
                "clinica": element.unitName,
                "Mediana dias estadia": element.median
            }))
            return dataParsed
        case 'historico media por piso':
            dataParsed = data.map( element => ({ 
                "Piso": element.floorName,
                "Clinica": element.unitName,
                "Plan salud": element.plan,
                "Mediana dias (nro días)": element.median
            }))
            return dataParsed
    
        default:
            return dataParsed
    }
}