import React, { useState, useEffect } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { editBedGrdConsaludByAdmissionCode, createBedGrdSalud } from "../../services/sheets"

const useStyles = makeStyles({
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  input: {
    margin: "1rem",
    width: "100%",
  },
  save: {
    background: "#30AFC0",
    color: "#ffffff",
    fontWeight: "600",
    borderRadius: "2rem",
    minWidth: "7rem",
    marginTop: "5rem",
    "&:hover": {
      background: "#30AFC0",
      color: "#ffffff",
    },
  },
})

const FormConsalud = ({ registerDate, onClose, reloadData, patientes }) => {
  const classes = useStyles()
  const [dataGrdConsalud, setDataGrdConsalud] = useState(
    !registerDate ? { admissionCode: "", urgencyLaw: "SI", patientRut: "", stable: "", bedCode: "" } : registerDate,
  )
  const { admissionCode } = registerDate || {}

  const editBedGrdConsalud = async () => {
    try {
      const resp = await editBedGrdConsaludByAdmissionCode(admissionCode, dataGrdConsalud)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const createNewBedGrdConsalud = async () => {
    try {
      const resp = await createBedGrdSalud(dataGrdConsalud)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!admissionCode) {
      createNewBedGrdConsalud()
    } else {
      editBedGrdConsalud()
    }
  }

  const handleChangeState = (event) => {
    setDataGrdConsalud({ ...dataGrdConsalud, [event.target.name]: event.target.value })
  }

  const handleChangeData = (event, newValue) => {
    setDataGrdConsalud({
      ...dataGrdConsalud,
      admissionCode: !newValue?.admissionCode ? "" : newValue.admissionCode,
      patientRut: !newValue?.rut ? "" : newValue.rut,
      bedCode: !newValue?.code ? "" : newValue.code,
    })
  }

  /*   const handleChangeBed = (e, newValue) => {
    setDataGrdConsalud({
      ...dataGrdConsalud,
      bedCode: !newValue?.code ? "" : newValue.code,
    })
  }
 */
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
        <Autocomplete
          id="rut-gescaec"
          className={classes.input}
          options={patientes}
          inputValue={dataGrdConsalud["patientRut"]}
          defaultValue={patientes.find((p) => p.rut === dataGrdConsalud["patientRut"])}
          onChange={(event, newValue) => handleChangeData(event, newValue)}
          onInputChange={(event, newInputValue) => {
            setDataGrdConsalud({
              ...dataGrdConsalud,
              patientRut: newInputValue,
            })
          }}
          getOptionLabel={(option) => option.rut}
          getOptionSelected={(option, value) => option.rut === dataGrdConsalud["patientRut"]}
          renderInput={(params) => <TextField {...params} label="RUT" variant="outlined" />}
        />
        <Autocomplete
          id="admissionCode-covid19"
          className={classes.input}
          options={patientes}
          inputValue={dataGrdConsalud["admissionCode"]}
          defaultValue={patientes.find((p) => p.admissionCode === dataGrdConsalud["admissionCode"])}
          onChange={(event, newValue) => handleChangeData(event, newValue)}
          onInputChange={(event, newInputValue) => {
            setDataGrdConsalud({
              ...dataGrdConsalud,
              admissionCode: newInputValue,
            })
          }}
          getOptionLabel={(option) => option.admissionCode}
          getOptionSelected={(option, value) => option.admissionCode === dataGrdConsalud["admissionCode"]}
          renderInput={(params) => <TextField {...params} label="Código de Admisión" variant="outlined" />}
        />

      <FormControl className={classes.input} variant="outlined">
        <InputLabel htmlFor="selected-state-label">Estabilizado</InputLabel>
        <Select
          labelId="selected-state-label"
          id="state-covid19"
          value={dataGrdConsalud["stable"]}
          onChange={handleChangeState}
          name="stable"
          labelWidth={105}
          placeholder="Tipo de reporte"
        >
          <MenuItem value="SI">SI</MenuItem>
          <MenuItem value="NO">NO</MenuItem>
        </Select>
      </FormControl>
      <Button type="submit" className={classes.save}>
        {!admissionCode ? `CREAR` : `GUARDAR`}
      </Button>
    </form>
  )
}

export default FormConsalud
