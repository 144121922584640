import React, { useState, useContext } from "react"
import { Link, useParams } from "react-router-dom"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import EditUnitInput from "../EditUnitInput"
import EditUnitUrlInput from "../EditUnitUrlInput"
import DeleteUnitModal from "../DeleteUnitModal"

// EXTERNAL COMPONENTS
import {
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

import clsx from "clsx"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const InfraestructureHeader = ({
  addUnit,
  createNewArea,
  singleUnit,
  unit,
  floor,
  area,
  flag,
  setSingleUnit,
  onOpenPopUpEditUnit,
  unitApi,
  setUnitApi,
}) => {
  const classes = useStyles()
  const params = useParams()
  const [hover, setHover] = useState(false)
  const [openDeleteUnitModal, setOpenDeleteUnitModal] = useState(false)
  const [error, setError] = useState(false)
  const { editGeneralInput, setEditGeneralInput } = useContext(DashboardUnit)

  const onCloseDeleteUnitModal = () => {
    setOpenDeleteUnitModal(false)
  }
  return (
    <>
      <div className={classes.header}>
        <div>
          <Typography variant="h4" className={classes.title}>
            Administrador de Unidades
          </Typography>
        </div>

        <div className={classes.container}>
          <div>
            <FormControl variant="outlined" className={classes.formControl} size="small" style={{ display: "none" }}>
              <InputLabel id="selected-filter-label">Filtrar por</InputLabel>
              <Select labelId="selected-filter-label" id="selected-filter" value="" onChange="" label="Filtrar por">
                <MenuItem value="primerItem">Primer Item</MenuItem>
                <MenuItem value="segundoItem">Segundo Item</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              size="small"
              style={{ display: "none" }}
            >
              <InputLabel htmlFor="search-input-label">Buscar</InputLabel>
              <OutlinedInput
                id="search-input-label"
                type="text"
                value=""
                onChange=""
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                labelWidth={45}
                disabled=""
              />
            </FormControl>
          </div>
          {addUnit ||
          createNewArea ||
          editGeneralInput.unit.state ||
          editGeneralInput.area.state ||
          editGeneralInput.service.state ||
          editGeneralInput.floor.state ? (
            <div className={classes.contentAdd}>
              <Button variant="contained" className={classes.iconAdd} startIcon={<AddIcon />} disabled={true}>
                Agregar unidad
              </Button>
            </div>
          ) : (
            <div className={classes.contentAdd}>
              <Link to="/architecture/addUnit" className={classes.addUnitLink}>
                <Button variant="contained" className={classes.iconAdd} startIcon={<AddIcon />}>
                  Agregar unidad
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div
        className={
          editGeneralInput.unit.state
            ? classes.unitContainerEdit
            : params.areaId
            ? classes.unitContainer2
            : classes.unitContainer
        }
      >
        {singleUnit && flag ? (
          <div
            onMouseEnter={() =>
              editGeneralInput.area.state ||
              editGeneralInput.service.state ||
              editGeneralInput.floor.state ||
              editGeneralInput.unitApi.state
                ? null
                : setHover(true)
            }
            onMouseLeave={() => setHover(false)}
            className={classes.unitIconContainer}
          >
            {editGeneralInput.unit.state ? (
              <EditUnitInput
                singleUnit={singleUnit}
                setEditGeneralInput={setEditGeneralInput}
                editGeneralInput={editGeneralInput}
                unit={unit}
                setSingleUnit={setSingleUnit}
                onOpenPopUpEditUnit={onOpenPopUpEditUnit}
              />
            ) : (
              <>
                <div className={classes.linkContainer}>
                  <p className={classes.unitName}>{singleUnit}</p>
                </div>
                <div className={classes.iconMainContainer4}>
                  {hover && (
                    <div className={classes.iconContainer4}>
                      <EditIcon onClick={() => setEditGeneralInput({ ...editGeneralInput, unit: { state: true } })} />
                      <DeleteIcon
                        onClick={() => {
                          setError(false)
                          setOpenDeleteUnitModal(true)
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <p className={classes.unitName}>
              {unit} - {area} - {floor}
            </p>
          </div>
        )}
      </div>
      <DeleteUnitModal
        open={openDeleteUnitModal}
        onClose={onCloseDeleteUnitModal}
        unitId={unit}
        unitName={singleUnit}
        error={error}
        setError={setError}
      />

      {singleUnit && (
        <>
          <div
            className={
              editGeneralInput.unit.state
                ? classes.unitContainerEdit
                : params.areaId
                ? classes.unitContainer2
                : classes.unitContainer
            }
          >
            {singleUnit && flag ? (
              <div
                onMouseEnter={() =>
                  editGeneralInput.area.state ||
                  editGeneralInput.service.state ||
                  editGeneralInput.floor.state ||
                  editGeneralInput.unitApi.state
                    ? null
                    : setHover(true)
                }
                onMouseLeave={() => setHover(false)}
                className={classes.unitIconContainer}
              >
                {editGeneralInput.unitApi.state ? (
                  <EditUnitUrlInput
                    singleUnit={unitApi}
                    setEditGeneralInput={setEditGeneralInput}
                    editGeneralInput={editGeneralInput}
                    unit={unit}
                    setSingleUnitUrl={setUnitApi}
                    onOpenPopUpEditUnit={onOpenPopUpEditUnit}
                  />
                ) : (
                  <>
                    <div className={classes.linkContainer}>
                      <p className={classes.unitName}>{unitApi}</p>
                    </div>
                    <div className={classes.iconMainContainer4}>
                      {hover && (
                        <div className={classes.iconContainer4}>
                          <EditIcon
                            onClick={() => setEditGeneralInput({ ...editGeneralInput, unitApi: { state: true } })}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : null}
          </div>
          <DeleteUnitModal
            open={openDeleteUnitModal}
            onClose={onCloseDeleteUnitModal}
            unitId={unit}
            unitName={singleUnit}
            error={error}
            setError={setError}
          />
        </>
      )}
    </>
  )
}

export default InfraestructureHeader
