import React, { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import {
  getUnit,
  getAreas,
  getSingleArea,
  getAllServices,
  getSubServices,
  createRoom,
  roomCodeExists,
  moveBeds,
  bulkDeleteBeds,
} from "../../services/architechture"
import PopUp from "../../components/PopUp"
// EXTERNAL COMPONENTS
import {
  Typography,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@material-ui/core"
import SaveIcon from "@material-ui/icons/Save"
import axios from "axios"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const MoveBeds = () => {
  const [floor, setFloor] = useState("")
  const [service, setService] = useState("")
  // const [subservice, setSubservice] = useState("Sin subservicio")
  const [selectedBeds, setSelectedBeds] = useState([])

  // UNIT
  const [unitId, setUnitId] = useState(null)
  const [unit, setUnit] = useState({})

  // AREAS
  const [areas, setAreas] = useState([])
  const [area, setArea] = useState(null)
  const [areaId, setAreaId] = useState(null)

  //FLOORS
  const [floors, setFloors] = useState([])
  const [floorId, setFloorId] = useState(null)

  // SERVICES
  const [services, setServices] = useState([])
  const [serviceId, setServiceId] = useState(null)

  // SUBSERVICES
  const [subservices, setSubservices] = useState([])
  const [subServiceName, setSubServiceName] = useState(null)
  const [subServiceId, setSubServiceId] = useState(null)
  const [subservice, setSubservice] = useState(null)

  // ROOM CODE
  const [roomCode, setRoomCode] = useState(null)

  // BACK DATA
  const [backData, setBackData] = useState({})
  const [deleteBeds, setDeleteBeds] = useState(false)
  const [user, setUser] = useState({})
  const [popUpUndefinedBeds, setPopUpUndefinedBeds] = useState(false)

  function newLocation() {
    window.location.href = `http://localhost:3001`
  }

  const onClosePopUp = () => {
    setPopUpUndefinedBeds(false)
    newLocation()
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      setUser(user)
    }
  }, [])

  const getRoomCode = async () => {
    try {
      const code = `SUB${subservice?.subServiceId}`
      const resp = await roomCodeExists(code)
      if (resp.code) setRoomCode(resp.code)
    } catch (e) {
      if (e.data.code === 404) {
        const resp = await createRoom({
          code: `SUB${subservice?.subServiceId}`,
          unitId,
          areaId,
          floorId,
          serviceId,
          subServiceId: subservice?.subServiceId,
        })
        setRoomCode(resp.code)
      }
    }
  }

  useEffect(() => {
    getRoomCode()
  }, [subservice])

  const handleChangeArea = (event) => {
    setArea(event.target.value)
  }

  const handleChangeFloor = (event) => {
    setFloor(event.target.value)
  }

  const handleChangeService = (event) => {
    setService(event.target.value)
  }

  const handleChangeSubservice = (event) => {
    setSubServiceName(event.target.value)
  }

  const getFloorsFromArea = async () => {
    try {
      if (areaId && unitId) {
        const resp = await getSingleArea(unitId, areaId)
        setFloors(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getServicesFromFloor = async () => {
    try {
      if (unitId && areaId && floorId) {
        const resp = await getAllServices(unitId, areaId, floorId)
        setServices(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getSubservicesFromService = async () => {
    try {
      const resp = await getSubServices(unitId, areaId, floorId, serviceId)
      setSubservices(resp)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getSubservicesFromService()
  }, [serviceId, floorId, areaId])

  useEffect(() => {
    getServicesFromFloor()
  }, [floorId, areaId])

  useEffect(() => {
    setFloorId(null)
    setServiceId(null)
    setSubServiceId(null)
    setFloor("")
    setService("")
  }, [areaId])

  const { moveSelectedBeds, onOpenPopUpMoveBed } = useContext(DashboardUnit)

  const filterFunction = () => {
    let obj = {}
    let arr = []

    for (let i = 0; i < moveSelectedBeds.length; i++) {
      obj[moveSelectedBeds[i]?.code] = moveSelectedBeds[i]
    }

    for (let key in obj) {
      if (obj[key].checked) arr.push(obj[key])
      else if (!obj[key].hasOwnProperty("checked")) arr.push(obj[key])
    }

    setSelectedBeds(arr)
  }

  const helper = (arr) => {
    let obj
    let array = []
    for (let i = 0; i < arr.length; i++) {
      array.push(arr[i].code)
    }
    if (arr.length) {
      obj = {
        beds: array,
      }
    }
    setBackData(obj)
  }

  useEffect(() => {
    filterFunction()
    // setSelectedBeds(moveSelectedBeds)
    setUnitId(moveSelectedBeds[0]?.unitId)
    setArea(moveSelectedBeds[0]?.areaName)
    setFloors(moveSelectedBeds[0]?.floors)
    setServices(moveSelectedBeds[0]?.services)
    setAreaId(moveSelectedBeds[0]?.areaId)
    setFloorId(moveSelectedBeds[0]?.floorId)
    setServiceId(moveSelectedBeds[0]?.serviceId)
    setSubservices(moveSelectedBeds[0]?.subservices)
    setSubServiceId(moveSelectedBeds[0]?.subServiceId)
    // if (contextSubServiceName) setSubServiceName(contextSubServiceName)
  }, [])

  useEffect(() => {
    helper(selectedBeds)
  }, [selectedBeds])

  const singleUnit = async () => {
    try {
      if (unitId) {
        const resp = await getUnit(unitId)
        setUnit(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAreasFromUnit = async () => {
    try {
      if (unitId) {
        const resp = await getAreas(unitId)
        setAreas(resp)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    singleUnit()
    getAreasFromUnit()
  }, [unitId])

  useEffect(() => {
    getFloorsFromArea()
  }, [areaId])

  useEffect(() => {
      setUnitId(null)
      setAreaId(null)
      setFloorId(null)
      setServiceId(null)
      setSubServiceId(null)
  }, [])

  useEffect(() => {
    if (!subservices?.length) {
      setSubServiceId(null)
      setSubservice(null)
    }
  }, [subservices, areaId, floorId])

  useEffect(() => {
    if (!services?.length) setServiceId(null)
  }, [serviceId, areaId, floorId])

  const handleSubmitMoveBed = async () => {
    try {
      if (deleteBeds) {
        axios({
          method: "DELETE",
          url: `${window.processEnv.REACT_APP_API_BASE_URL}/beds`,
          headers: { Authorization: `Bearer ${user?.accessToken}` },
          data: { beds: backData?.beds },
          withCredentials: false,
        }).then(() => setPopUpUndefinedBeds(true))
      } else {
        const resp = await moveBeds({ ...backData, unitId, areaId, floorId, serviceId, subServiceId, roomCode })
        onOpenPopUpMoveBed()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const history = useHistory()
  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        <div>
          <Typography variant="h4" className={classes.title}>
            Administrador de Unidades
          </Typography>
        </div>

        <div className={classes.moveTitleContainer}>
          <p className={classes.moveTitleText}>Mover Camas</p>
        </div>
      </div>

      <div className={classes.moveContentContainer}>
        <div className={classes.moveContentFirstDiv}>
          <div className={classes.selectedBeds}>
            <p className={classes.selectedBedsP}>Camas seleccionadas: {selectedBeds?.length}</p>
            <div className={classes.selectedBedsDiv}>
              {selectedBeds?.map((x, i) => {
                return (
                  <p key={i} className={classes.selectedBedNumber}>
                    {x.code}
                  </p>
                )
              })}
            </div>
          </div>
          <small className={classes.wrongBed}>
            ¿Cama equivocada?
            <span onClick={() => history.goBack()} className={classes.wrongBedSpan}>
              Volver a seleccionar camas
            </span>
          </small>
        </div>
        <div className={classes.moveContentSecondDiv}>
          <div className={classes.moveContentSingleDiv}>
            <div className={classes.firstColumn}>
              <p className={classes.firstColumnP}>Unidad</p>
            </div>
            <div className={classes.secondColumn}>
              <p className={classes.firstColumnP2}>{unit?.name}</p>
            </div>
          </div>
          <div className={classes.moveContentSingleDivArea}>
            <div className={classes.firstColumn}>
              <p className={classes.firstColumnP}>Área</p>
            </div>
            <div className={classes.secondColumn}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="area" name="area" value={area} onChange={handleChangeArea}>
                  {areas?.map((x, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        className={classes.moveFormControlLabel}
                        value={x.name}
                        control={<Radio color="#566573" />}
                        label={x.name}
                        onClick={() => {
                          setDeleteBeds(false)
                          setAreaId(x.areaId)
                        }}
                      />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {deleteBeds ? null : (
            <>
              <div className={classes.moveContentSingleDiv}>
                <div className={classes.firstColumn}>
                  <p className={classes.firstColumnP}>Piso</p>
                </div>
                <div className={classes.secondColumn}>
                  <FormControl className={classes.formControlMove} variant="outlined" size="small">
                    <InputLabel id="floor-selected" margin="dense">
                      Seleccione un piso
                    </InputLabel>
                    <Select
                      labelId="floor-selected"
                      id="select-outlined"
                      label="Seleccione un piso"
                      value={floor}
                      onChange={handleChangeFloor}
                    >
                      {floors?.map((x, i) => {
                        return (
                          <MenuItem onClick={() => setFloorId(x.floorId)} key={i} value={x.name}>
                            {x.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.moveContentSingleDiv}>
                <div className={classes.firstColumn}>
                  <p className={classes.firstColumnP}>Servicio</p>
                </div>
                <div className={classes.secondColumn}>
                  {services?.length ? (
                    <>
                      <FormControl className={classes.formControlMove} variant="outlined" size="small">
                        <InputLabel id="service-selected" margin="dense">
                          Seleccione un servicio
                        </InputLabel>
                        <Select
                          labelId="service-selected"
                          id="select-outlined"
                          label="Seleccione un servicio"
                          value={service}
                          onChange={handleChangeService}
                        >
                          {services?.map((x, i) => {
                            return (
                              <MenuItem key={i} value={x.name} onClick={() => setServiceId(x.serviceId)}>
                                {x.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <p>No hay servicios en este piso</p>
                  )}
                </div>
              </div>
              <div className={classes.lastDiv}>
                <div className={classes.firstColumn}>
                  <p className={classes.firstColumnP}>Subservicio</p>
                </div>
                <div className={classes.secondColumn}>
                  {subservices?.length ? (
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="area"
                        name="subservice"
                        value={subServiceName}
                        onChange={handleChangeSubservice}
                      >
                        {subservices?.map((x, i) => {
                          return (
                            <>
                              <FormControlLabel
                                className={classes.moveFormControlLabel}
                                value={x.name}
                                control={<Radio color="#566573" />}
                                label={x.name}
                                onClick={() => {
                                  setSubservice(x)
                                  setSubServiceId(x.subServiceId)
                                }}
                              />
                            </>
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <p>No hay subservicios</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {unitId && areaId && floorId && serviceId && subServiceId && !deleteBeds ? (
        <Link to={`/architecture/${unitId}/${areaId}/${floorId}`} className={classes.addUnitLink}>
          <div className={classes.buttonContainer} onClick={() => handleSubmitMoveBed()}>
            <Button variant="contained" className={classes.iconAdd} startIcon={<SaveIcon />}>
              GUARDAR NUEVA UBICACIÓN
            </Button>
          </div>
        </Link>
      ) : null}

      {deleteBeds ? (
        <div className={classes.buttonContainer} onClick={() => handleSubmitMoveBed()}>
          <Button variant="contained" className={classes.iconAdd} startIcon={<SaveIcon />}>
            GUARDAR NUEVA UBICACIÓN
          </Button>
        </div>
      ) : null}

      <PopUp
        open={popUpUndefinedBeds}
        onClose={onClosePopUp}
        title="En 5 minutos podrá ver las camas en la ubicación seleccionada."
      />
    </>
  )
}

export default MoveBeds
