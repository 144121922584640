import React, { useState, useEffect } from "react"

// EXTERNAL COMPONENTS
import { Button, makeStyles, Modal } from "@material-ui/core"
import { deleteFloor, getSingleArea } from "../../services/architechture"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"
import { warningSvg } from "../../utils/svgHelper"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const DeleteFloorModal = ({
  open,
  onClose,
  floorId,
  floors,
  setFloors,
  name,
  areaId,
  unitId,
  onOpenPopUpDeleteFloor,
}) => {
  const classes = useStyles()

  const handleClickDeleteFloor = async () => {
    try {
      if (floorId) {
        const resp = await deleteFloor(floorId)
        if (resp && areaId && unitId) {
          const newFloors = await getSingleArea(unitId, areaId)
          setFloors(newFloors)
        }
        onOpenPopUpDeleteFloor()
        onClose()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.deleteModal}
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <div className={classes.paperChild}>
            <div>{warningSvg}</div>

            <p className={classes.deleteText}>
              ¿Desea borrar el piso <span className={classes.deleteTextSpan}>{name}</span>?
            </p>

            <small className={classes.small1}>Si borra el piso, se eliminará todo lo que contenga.</small>
            <div className={classes.deleteButtonContainer}>
              <Button variant="contained" className={classes.noDeleteButton} onClick={onClose}>
                NO
              </Button>

              <Button variant="contained" className={classes.deleteButton} onClick={() => handleClickDeleteFloor()}>
                SI, BORRAR PISO
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteFloorModal
