import { useEffect, useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"

import DashboardUnit from "../../contexts/dashboardUnitContext"

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#009DB1",
    width: "83.75vw",
    height: "2rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0.5rem",
    position: "fixed",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  text: {
    fontSize: "0.9rem",
    color: "#ffffff",
    letterSpacing: "0.25px",
    margin: "0 2rem 0 0",
    fontWeight: "600",
  },
}))

const UpdateDateFooter = () => {
  const classes = useStyles()
  const { lastDateUpdate } = useContext(DashboardUnit)

  const date = `${lastDateUpdate} GMT`
  const localDate = new Date(date).toLocaleString()

  return (
    <div className={classes.root}>
      {!!lastDateUpdate && <p className={classes.text}>{`Última actualización: ${localDate} hs`}</p>}
    </div>
  )
}

export default UpdateDateFooter
