import useMediaQuery from "@material-ui/core/useMediaQuery"

export default function CustomContainer({
  lenght = "25px",
  marginTop = "0",
  marginBottom = "0",
  height = "100%",
  width = "100%",
  children,
}) {
  const matches = useMediaQuery("(max-width:1280px)")
  const xs = useMediaQuery("(max-width:600px)")

  const styles = {
    marginLeft: lenght,
    marginRight: lenght,
    marginTop: matches ? (xs ? "0.5rem" : "-0.10rem") : marginTop,
    marginBottom: marginBottom,
    width: matches ? (xs ? width : "80%") : width,
    height: height,
  }

  return <div style={styles}>{children}</div>
}
