import { useContext, useEffect, useState } from "react"
import { NavLink, useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import TimelineIcon from "@material-ui/icons/Timeline"
import AuthContext from "../../contexts/authContext"
import { Divider } from "@material-ui/core"

import DashboardUnit from "../../contexts/dashboardUnitContext"

const useStyles = makeStyles((theme) => ({
  siderbar: {
    width: "16.25vw",
    height: "100vh",
    padding: "0.9rem 0.9rem 0.9rem 0",
    borderRight: "1px solid #D5D8DC",
    position: "fixed",
    overflowY: "auto",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#566573",
    fontSize: "1rem",
    textDecoration: "none",
    width: "100%",
    padding: "0.8rem",
    marginBottom: ".3rem",
  },
  menuItemImg: {
    textDecoration: "none",
    width: "100%",
    padding: "0",
  },
  img: {
    width: "14rem",
    [theme.breakpoints.down("md")]: {
      width: "12rem",
    },
  },
  menuText: {
    marginLeft: "1rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0.6rem",
    },
  },
  isActived: {
    backgroundColor: "#009DB1",
    opacity: "0.9",
    color: "#fff",
    borderRadius: "0 2rem 2rem 0",
  },
  linkHome: {
    marginTop: "0.5rem",
    marginBottom: "2.3rem",
  },
  dropdown: {
    boxShadow: "none",
    border: "none",
    color: "#566573",
    "&::before": {
      background: "none",
    },
  },
  textDropdown: {
    padding: "0.8rem",
    fontWeight: "bold",
    "& div": {
      alignItems: "center",
    },
    "& svg": {
      width: "1.3rem",
      height: "1.3rem",
    },
  },
  optionsDropdown: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    lineHeight: "26px",
    textTransform: "uppercase",
    color: "#566573",
    marginLeft: "1.125rem",
    marginBottom: ".5rem",
    marginTop: "3.628rem",
  },
  areaTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    lineHeight: "26px",
    textTransform: "uppercase",
    color: "#566573",
    margin: "1rem 0 0 1.125rem",
  },
  contentText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem 0",
  },
}))

const MenuItem = ({ icon, text, to, img, ...props }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleReloadPage = () => {
    if (history.location.pathname === to) {
      window.location.reload()
    }
  }

  return (
    <div>
      {!img ? (
        <NavLink to={to} className={classes.menuItem} {...props}>
          {typeof icon === "string" ? <i className={icon}></i> : icon}
          <p className={classes.menuText}>{text}</p>
        </NavLink>
      ) : (
        <NavLink to={to} className={classes.menuItemImg} {...props} onClick={handleReloadPage}>
          <img src={img} alt={text} className={classes.img} />
        </NavLink>
      )}
    </div>
  )
}

const MenuDropdown = ({ text, options }) => {
  const classes = useStyles()
  return (
    <Accordion className={classes.dropdown}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.textDropdown}
      >
        <i className="fas fa-building"></i>
        <p className={classes.menuText}>{text}</p>
      </AccordionSummary>
      <AccordionDetails className={classes.optionsDropdown}>
        {options && options.map((op, i) => <MenuItem key={i} text={op.service} to={`/services/${op.idService}`} />)}
      </AccordionDetails>
    </Accordion>
  )
}

const SideBar = () => {
  const classes = useStyles()

  const { detailsUnit } = useContext(DashboardUnit)

  const {
    auth: { roles },
  } = useContext(AuthContext)

  const formatDataDetailsArea = (floors) => {
    const data = floors
      .map((el) => el.services.map((ser) => ({ service: `${el.name} - ${ser.name}`, idService: ser.id })))
      .flat()
    return data
  }

  return (
    <div className={classes.siderbar}>
      <div className={classes.linkHome}>
        <MenuItem img="/logo-redsalud.png" text="Inicio" to="/" />
      </div>

      <p className={classes.title}>ADMINISTRADOR</p>

      {roles && roles[0] && (roles[0] === "ROLE_SUPER_ADMIN" || roles[0] === "ROLE_ADMIN_FILIAL") ? (
        <MenuItem
          icon="fas fa-hospital-user"
          text="Unidades"
          to={`/architecture/${detailsUnit?.unit[0]?.id}`}
          activeClassName={classes.isActived}
        />
      ) : null}
      <MenuItem
        icon="fas fa-user-shield"
        text="Privilegios"
        to="/usersAdministration"
        activeClassName={classes.isActived}
      />
      <Divider />

      <MenuItem
        icon="fas fa-list-alt"
        text="Vista Comercial"
        to="/commercialview"
        activeClassName={classes.isActived}
      />
      <MenuItem icon="fas fa-gavel" text="Ley de Urgencia" to="/urgencylaw" activeClassName={classes.isActived} />
      <MenuItem
        icon="fas fa-user-injured"
        text="Pacientes de Contingencia"
        to="/contingency"
        activeClassName={classes.isActived}
      />
      <MenuItem icon="fas fa-book-open" text="Planillas" to="/sheets" activeClassName={classes.isActived} />
      <MenuItem icon="fa fa-sitemap" text="Estado De Red" to="/redStatus" activeClassName={classes.isActived} />
      {detailsUnit?.unit[0]?.name === "Providencia" ? (
        <MenuItem
          icon="fas fa-building"
          text="Pre-Operatorio"
          to="/services/operative"
          activeClassName={classes.isActived}
        />
      ) : null}
      <MenuItem
        icon={<TimelineIcon style={{ marginRight: "0px !important" }} />}
        text="Historico de Camas"
        to="/history"
        activeClassName={classes.isActived}
      />
      <Divider />
      <p className={classes.areaTitle}>ÁREAS</p>
      {detailsUnit?.unit.map((u, i) => {
        return u.areas.map((area, index) => {
          if (area.name !== "INDEFINIDO" && area.name !== "NO ASIGNADA" && area.name !== "NO ASIGNADAS") {
            return <MenuDropdown key={index} text={area.name} options={formatDataDetailsArea(area.floors)} />
          }
        })
      })}
      {detailsUnit?.unit.map((u, i) => {
        return u.areas.map((area, index) => {
          if (area.name === "NO ASIGNADA" || area.name === "NO ASIGNADAS") {
            return <MenuDropdown key={index} text={area.name} options={formatDataDetailsArea(area.floors)} />
          }
        })
      })}
      {detailsUnit?.unit.map((u, i) => {
        return u.areas.map((area, index) => {
          if (area.name === "INDEFINIDO") {
            return <MenuDropdown key={index} text={area.name} options={formatDataDetailsArea(area.floors)} />
          }
        })
      })}
      <Divider />
    </div>
  )
}

export default SideBar
