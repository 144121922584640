import { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DrawerSheets from "../DrawerSheets"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"
import { deleteBedGrdConsalud } from "../../services/sheets"
import clsx from "clsx"
import GetAppIcon from "@material-ui/icons/GetApp"
import { CSVLink } from "react-csv"
import DashboardUnit from "../../contexts/dashboardUnitContext"

const headCells = [
  { id: "patientRut", numeric: false, disablePadding: true, label: "RUT" },
  { id: "bedCode", numeric: false, disablePadding: false, label: "CÓDIGO DE CAMA" },
  { id: "state", numeric: false, disablePadding: true, label: "ESTABILIZADO" },
  { id: "action", numeric: false, disablePadding: true, label: "ACCION" },
]

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" padding="normal">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80rem",
    margin: "auto",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusService: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2rem",
    margin: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  title: {
    fontSize: "2.3rem",
    color: "#566573",
    margin: "2rem 0 3rem 0",
  },
  contentAdd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  iconAdd: {
    backgroundColor: "#009DB1",
    marginRight: "2rem",
    marginBottom: "0.5rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#009DB1",
    },
  },
  red: {
    background: "#F1948A",
    width: "10rem",
  },
  green: {
    width: "8rem",
    background: "#7DCEA0",
  },
  yellow: {
    background: "#F9E79F",
  },
  link: {
    textDecoration: "none",
  },
}))

const TableGrdConsalud = ({ detailsGrdCosalud, reloadData, setDetailsGrdCosalud }) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [registerDate, setRegisterData] = useState()
  const [openDrawer, setOpenDrawer] = useState()

  const [unit, setUnit] = useState(0)
  const { detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    const unitId = detailsUnit?.unit[0]?.id
    setUnit(unitId)
  }, [detailsUnit])

  useEffect(() => {
    if (detailsUnit) {
      if (unit !== detailsUnit?.unit[0]?.id) reloadData()
    }
    return () => setDetailsGrdCosalud([])
  }, [detailsUnit, unit])

  const onOpenDrawer = (code) => {
    setOpenDrawer(true)
    setRegisterData(code)
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDeleteBedGrdConsalud = async (code, bedCode) => {
    try {
      const resp = await deleteBedGrdConsalud(code, bedCode)
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, detailsGrdCosalud.length - page * rowsPerPage)

  const formatStatus = (value, classes) => {
    switch (value) {
      case "SI":
        return <div className={clsx(classes.statusService, classes.green)}>LU Estabilizado</div>
      case "NO":
        return <div className={clsx(classes.statusService, classes.red)}>LU NO Estabilizado</div>
      default:
        return <div className={classes.statusService}>{`--`}</div>
    }
  }

  const headersCsv = [
    { key: "admissionCode", label: "ID Ingreso" },
    { key: "patientRut", label: "RUT" },
    { key: "bedCode", label: "Código de Cama" },
    { key: "urgencyLaw", label: "Ley de Urgencia" },
    { key: "stable", label: "Estabilizado" },
  ]

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.contentAdd}>
          <CSVLink
            data={detailsGrdCosalud}
            filename={"planillaGRDConSalud.csv"}
            headers={headersCsv}
            className={classes.link}
          >
            <Button variant="contained" className={classes.iconAdd} startIcon={<GetAppIcon />}>
              Exportar a CSV
            </Button>
          </CSVLink>
          <Button
            variant="contained"
            className={classes.iconAdd}
            startIcon={<AddIcon />}
            onClick={() => onOpenDrawer()}
          >
            Agregar registro
          </Button>
        </div>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <EnhancedTableHead classes={classes} rowCount={detailsGrdCosalud.length} />
            <TableBody>
              {detailsGrdCosalud.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={labelId}>
                    <TableCell component="th" id={labelId} scope="row" align="center">
                      {row.patientRut}
                    </TableCell>
                    <TableCell align="center">{!row.bedCode ? "-" : row.bedCode}</TableCell>
                    <TableCell align="center">{formatStatus(row.stable, classes)}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteBedGrdConsalud(row.admissionCode, row.bedCode)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() =>
                          onOpenDrawer({
                            admissionCode: row.admissionCode,
                            urgencyLaw: row.urgencyLaw,
                            patientRut: row.patientRut,
                            stable: row.stable,
                            bedCode: row.bedCode,
                          })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={detailsGrdCosalud.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <DrawerSheets
        open={openDrawer}
        registerDate={registerDate}
        mode="grdconsalud"
        onClose={() => onCloseDrawer()}
        reloadData={reloadData}
      />
    </div>
  )
}

export default TableGrdConsalud
