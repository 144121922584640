import { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import { Link, useHistory } from "react-router-dom"
import ComercialBeds from "../../contexts/comercialBedsContext"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80rem",
    margin: "1.5rem auto",
  },
  table: {
    minWidth: 750,
  },
  cellHead: {
    fontSize: "1.2rem",
    fontWeight: "600",
  },
}))

export default function TableFonasaCommercialView({ data }) {
  const classes = useStyles()
  const [grdconsalud, setGrdconsalud] = useState([])
  const [gescaecgrdfonasa, setGescaecgrdfonasa] = useState([])
  const [gescaec, setGescaec] = useState([])
  const [grdfonasa, setGrdfonasa] = useState([])
  const [gescaecgrdfonasagrdconsalud, setGescaecgrdfonasagrdconsalud] = useState([])

  const history = useHistory()
  const { setComercialBeds } = useContext(ComercialBeds)

  const headCells = [
    { id: "type", numeric: false, disablePadding: true, label: "Tipo" },
    { id: "amount", numeric: false, disablePadding: false, label: "Cantidad" },
  ]

  const cleanWhite = (str) => {
    return str.split(" ").join("")
  }

  const setUpDetailBeds = () => {
    const filterGrdSalud = data.filter((d) => cleanWhite(d.grd) === "GRD-CONSALUD")
    const filterGesCaecGrdFonasa = data.filter((d) => cleanWhite(d.grd) === "GES-CAECGRD-FONASA")
    const filterGesCaec = data.filter((d) => cleanWhite(d.grd) === "GES-CAEC")
    const filterGrdFonasa = data.filter((d) => cleanWhite(d.grd) === "GRD-FONASA")
    const filterGesCaecGrdFonasaConsalud = data.filter((d) => cleanWhite(d.grd) === "GES-CAECGRD-FONASAGRD-CONSALUD")
    setGrdconsalud(filterGrdSalud)
    setGescaecgrdfonasa(filterGesCaecGrdFonasa)
    setGescaec(filterGesCaec)
    setGrdfonasa(filterGrdFonasa)
    setGescaecgrdfonasagrdconsalud(filterGesCaecGrdFonasaConsalud)
  }

  useEffect(() => {
    setUpDetailBeds()
  }, [data])

  const total =
    grdconsalud.length +
    gescaecgrdfonasa.length +
    gescaec.length +
    grdfonasa.length +
    gescaecgrdfonasagrdconsalud.length

  const handleGoToComercialBeds = (path, details, title) => {
    setComercialBeds({ details, title })
    history.push(path)
  }

  const EnhancedTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align="left" padding="normal" className={classes.cellHead}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <>
      <div className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead />
            <TableBody>
              <TableRow className={classes.table} hover>
                <TableCell
                  align="left"
                  onClick={() => handleGoToComercialBeds("/commercialview/GRD-CONSALUD", grdconsalud, "GRD-CONSALUD")}
                >
                  GRD-CONSALUD
                </TableCell>
                <TableCell align="left">{grdconsalud.length}</TableCell>
              </TableRow>
              <TableRow className={classes.table} hover>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleGoToComercialBeds(
                      "/commercialview/GES-CAECGRD-FONASA",
                      gescaecgrdfonasa,
                      "GES-CAEC GRD-FONASA",
                    )
                  }
                >
                  GES-CAEC GRD-FONASA
                </TableCell>
                <TableCell align="left">{gescaecgrdfonasa.length}</TableCell>
              </TableRow>
              <TableRow className={classes.table} hover>
                <TableCell
                  align="left"
                  onClick={() => handleGoToComercialBeds("/commercialview/GES-CAEC", gescaec, "GES-CAEC")}
                >
                  GES-CAEC
                </TableCell>
                <TableCell align="left">{gescaec.length}</TableCell>
              </TableRow>
              <TableRow className={classes.table} hover>
                <TableCell
                  align="left"
                  onClick={() => handleGoToComercialBeds("/commercialview/GRD-FONASA", grdfonasa, "GRD-FONASA")}
                >
                  GRD-FONASA
                </TableCell>
                <TableCell align="left">{grdfonasa.length}</TableCell>
              </TableRow>
              <TableRow className={classes.table} hover>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleGoToComercialBeds(
                      "/commercialview/GES-CAECGRD-FONASAGRD-CONSALUD",
                      gescaecgrdfonasagrdconsalud,
                      "GES-CAEC GRD-FONASA GRD-CONSALUD",
                    )
                  }
                >
                  GES-CAEC GRD-FONASA GRD-CONSALUD
                </TableCell>
                <TableCell align="left">{gescaecgrdfonasagrdconsalud.length}</TableCell>
              </TableRow>
              <TableRow className={classes.table} hover>
                <TableCell align="left">
                  <strong>TOTAL</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>{total}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
