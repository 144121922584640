import { useState, useEffect, useContext } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import DashboardUnit from "../../contexts/dashboardUnitContext"

import { editBedCovid19ByAdmissionCode, createBedCovid19 } from "../../services/sheets"

const useStyles = makeStyles({
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  input: {
    margin: "1rem",
    width: "100%",
  },
  save: {
    background: "#30AFC0",
    color: "#ffffff",
    fontWeight: "600",
    borderRadius: "2rem",
    minWidth: "7rem",
    marginTop: "5rem",
    "&:hover": {
      background: "#30AFC0",
      color: "#ffffff",
    },
  },
})

const FormCovid19 = ({ registerDate, onClose, reloadData, patientes, setPatientes }) => {
  const classes = useStyles()
  const [dataCovid19, setDataCovid19] = useState(
    !registerDate ? { admissionCode: "", state: "", patientRut: "", bedCode: "" } : registerDate,
  )
  const [unit, setUnit] = useState(0)

  const { admissionCode } = registerDate || {}
  const { detailsUnit } = useContext(DashboardUnit)

  useEffect(() => {
    const unitId = detailsUnit?.unit[0].id
    setUnit(unitId)
  }, [detailsUnit])

  useEffect(() => {
    if (detailsUnit) {
      if (unit !== detailsUnit.unit[0].id) reloadData()
    }
  }, [detailsUnit, unit])

  const editBedCovid19 = async () => {
    try {
      const resp = await editBedCovid19ByAdmissionCode(admissionCode, dataCovid19)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const createNewBedCovid19 = async () => {
    try {
      const resp = await createBedCovid19(dataCovid19)
      onClose()
      reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!admissionCode) {
      createNewBedCovid19()
    } else {
      editBedCovid19()
    }
  }

  const handleChangeState = (event) => {
    setDataCovid19({ ...dataCovid19, [event.target.name]: event.target.value })
  }

  const handleChangeData = (event, newValue) => {
    setDataCovid19({
      ...dataCovid19,
      admissionCode: !newValue?.admissionCode ? "" : newValue.admissionCode,
      patientRut: !newValue?.rut ? "" : newValue.rut,
      bedCode: !newValue?.code ? "" : newValue.code,
    })
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Autocomplete
        id="admissionCode-covid19"
        className={classes.input}
        options={patientes}
        getOptionLabel={(option) => option.bedCode}
        inputValue={dataCovid19["bedCode"]}
        defaultValue={patientes.find((p) => p.bedCode === dataCovid19["bedCode"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataCovid19({
            ...dataCovid19,
            bedCode: newInputValue,
          })
        }}
        getOptionSelected={(option, value) => option.bedCode === dataCovid19["bedCode"]}
        renderInput={(params) => <TextField {...params} label="Código de Cama" variant="outlined" />}
      />
      <Autocomplete
        id="rut-covid19"
        className={classes.input}
        options={patientes}
        inputValue={dataCovid19["patientRut"]}
        defaultValue={patientes.find((p) => p.rut === dataCovid19["patientRut"])}
        onChange={(event, newValue) => handleChangeData(event, newValue)}
        onInputChange={(event, newInputValue) => {
          setDataCovid19({
            ...dataCovid19,
            patientRut: newInputValue,
          })
        }}
        getOptionLabel={(option) => option.rut}
        getOptionSelected={(option, value) => option.rut === dataCovid19["patientRut"]}
        renderInput={(params) => <TextField {...params} label="RUT" variant="outlined" />}
      />
      <FormControl className={classes.input} variant="outlined">
        <InputLabel id="selected-state-label">Estado</InputLabel>
        <Select
          labelId="selected-state-label"
          id="state-covid19"
          value={dataCovid19["state"]}
          onChange={handleChangeState}
          label="Estado"
          name="state"
          labelWidth={30}
        >
          <MenuItem value="POSITIVO">POSITIVO</MenuItem>
          <MenuItem value="NEGATIVO">NEGATIVO</MenuItem>
          <MenuItem value="SOSPECHA">SOSPECHA</MenuItem>
        </Select>
      </FormControl>
      <Button type="submit" className={classes.save}>
        {!registerDate ? `CREAR` : `GUARDAR`}
      </Button>
    </form>
  )
}

export default FormCovid19
