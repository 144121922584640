import { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { getServiceById, searchBedsForDashboard, getReservesBeds } from "../../services/beds"
import { CardBeds } from "../../components"
import { CustomContainer } from "../../components/common"
import Divider from "@material-ui/core/Divider"
import { refreshApi } from "../../utils/refreshApi"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { expiredToken } from "../../utils/expiredToken"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import clsx from "clsx"
import GroupBeds from "./GroupBeds"
import useStyles from "./styles"

export default function BedsServices() {
  const classes = useStyles()
  const { id } = useParams()
  const [subServices, setSubServices] = useState([])
  const { setDateUpdate, detailsUnit } = useContext(DashboardUnit)
  const [refresh, setRefresh] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [serchAvailable, setSerchAvailable] = useState(true)
  const [thereResult, setThereResult] = useState(true)
  const [respSearch, setRespSearch] = useState([])
  const [searchRequest, setSearchRequest] = useState({
    serviceId: id,
    patientRut: null,
    patientName: null,
    professionalName: null,
    admissionCode: null,
  })

  useEffect(() => {
    if (id !== "operative") {
      getService()
    } else {
      getServiceOperative()
    }
    if (id !== searchRequest.serviceId) {
      setSearchRequest({ ...searchRequest, serviceId: id })
      setRespSearch([])
      setThereResult(true)
    }
  }, [id, refresh, detailsUnit])

  useEffect(() => {
    getService()
  }, [id])

  useEffect(() => {
    refreshApi(setRefresh, 300)
  }, [])

  useEffect(() => {
    if (!!filter && !!search) {
      getSearchBeds()
    }
  }, [searchRequest])

  const getSearchBeds = async () => {
    try {
      const resp = await searchBedsForDashboard(searchRequest)
      setRespSearch(resp.subServices)
      setThereResult(resp.subServices.length)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
      setThereResult(false)
    }
  }

  const handleChangeSearch = (event) => {
    setSearchRequest({ ...searchRequest, [`${filter}`]: event.target.value })
    setSearch(event.target.value)
  }

  const getService = async () => {
    setIsLoading(true)
    try {
      const ser = id === "-2" ? await getReservesBeds() : await getServiceById(id)
      if (detailsUnit?.unit[0]?.name === "Providencia") {
        const filterNotPOQ = ser.subServices.map((el) => {
          const name = el.name
          const bedsFilter = el.beds.filter((bed) => !bed.code.includes("PO"))
          return { name, beds: id === "-2" ? el.beds : bedsFilter }
        })
        setSubServices(filterNotPOQ)
      } else {
        setSubServices(ser.subServices)
      }
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getServiceOperative = async () => {
    setIsLoading(true)
    try {
      const ser = await getServiceById(-1)
      const filterPOQ = ser.subServices.map((el) => {
        const name = "Pre-Operatorio"
        const bedsFilter = el.beds.filter((bed) => bed.code.includes("PO"))
        return { name, beds: bedsFilter }
      })
      setSubServices(filterPOQ)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
    setSearchRequest({
      serviceId: id,
      patientRut: null,
      patientName: null,
      professionalName: null,
      admissionCode: null,
    })
    setSearch("")
    setSerchAvailable(false)
  }

  return (
    <>
      <div>
        {id !== "-2" && (
          <>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="selected-filter-label">Filtrar por</InputLabel>
              <Select
                labelId="selected-filter-label"
                id="selected-filter"
                value={filter}
                onChange={handleChangeFilter}
                label="Filtrar por"
              >
                <MenuItem value="patientRut">RUT</MenuItem>
                <MenuItem value="patientName">Nombre Paciente</MenuItem>
                <MenuItem value="professionalName">Nombre Doctor</MenuItem>
                <MenuItem value="admissionCode">Admision Codigo</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small">
              <InputLabel htmlFor="search-input-label">Buscar</InputLabel>
              <OutlinedInput
                id="search-input-label"
                type="text"
                value={search}
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                labelWidth={45}
                disabled={serchAvailable}
              />
            </FormControl>
          </>
        )}
        {!thereResult && <p className={classes.messagge}>No se encontraron camas.</p>}
      </div>
      {respSearch.length > 0 ? (
        <CustomContainer lenght="0px" marginTop="20px" width="98%">
          <ul>{respSearch && respSearch.map((s, index) => <GroupBeds servicename={s} key={index} />)}</ul>
        </CustomContainer>
      ) : (
        <div className={classes.contentBedsGroup}>
          {subServices &&
            subServices.map((subService, idx) => (
              <>
                <div className={classes.nameService} id={idx}>
                  {subService.name}
                  <Divider className={classes.divider} />
                </div>
                {subService.beds && subService.beds.map((bed) => <CardBeds data={bed} key={bed.code} />)}
              </>
            ))}
        </div>
      )}
    </>
  )
}
