import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

//services
import { getOcuppiedBeds } from "../../services/beds"

//contexts
import DashboardUnit from "../../contexts/dashboardUnitContext"

//components
import LoadingComponent from "../../components/LoadingComponent"

//bizcharts
import { PieChart } from "bizcharts"

const RedStatus = () => {
  const [ocuppiedBeds, setOcuppiedBeds] = React.useState([])
  const { setUnit } = useContext(DashboardUnit)
  const history = useHistory()

  React.useEffect(() => {
    const getAllOcuppiedBeds = async () => {
      const response = await getOcuppiedBeds()
      const bedsMap = response.unit?.map((item) => {
        return {
          totals: item.busyBedCount,
          id: item.id,
          description: item.name,
        }
      })
      setOcuppiedBeds(bedsMap)
    }

    getAllOcuppiedBeds()
  }, [])

  let totalBeds = 0
  for (const unit of ocuppiedBeds) {
    totalBeds += unit.totals
  }

  if (ocuppiedBeds?.length) {
    return (
      <div>
        <PieChart
          height={600}
          data={ocuppiedBeds}
          title={{
            visible: true,
            text: "Gráfico de camas ocupadas en todas las unidades",
          }}
          description={{
            visible: true,
            text: `Total de camas ocupadas ${totalBeds}`,
          }}
          events={{
            onPlotClick: (event) => {
              if (event.data) {
                // for some reason the event occurs two times so i check if the pathname is the same
                if (history.location.pathname === "/redStatus") {
                  localStorage.setItem("unit", event.data.data.id)
                  setUnit(event.data.data.id)
                  history.push("/monitor")
                }
              }
            },
          }}
          radius={0.8}
          angleField="totals"
          colorField="description"
          label={{
            visible: false,
          }}
          tooltip={{
            visible: true,
            formatter: ({ description, totals }) => {
              return {
                name: description,
                value: `${totals} camas ocupadas`,
              }
            },
          }}
        />
      </div>
    )
  }
  return (
    <div
      style={{
        height: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingComponent />
    </div>
  )
}

export default RedStatus
