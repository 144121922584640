import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "1.3rem",
    color: "#6E6E6E",
  },
  nameService: {
    color: "#4CAF50",
    width: "100%",
    margin: "2rem 0 1rem 0",
    fontSize: "1.2rem",
  },
  contentBedsGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
  messagge: {
    margin: "0.5rem 0 0 0.5rem",
    color: "#ff0000",
    fontSize: "1rem",
    fontWeight: "600",
  },
}))
export default useStyles
