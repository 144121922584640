import React from "react"
import { Link } from "react-router-dom"
import { Card, CardContent, Typography } from "@material-ui/core"
import useStyles from "./styles"

export default function CardTable({ name, link, count }) {
  const classes = useStyles()
  console.log(link,'link')
  return (
    <Card className={classes.root}>
      <CardContent>
        {link ? (
          <Link className={classes.link} to={link}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {name}
            </Typography>
          </Link>
        ) : (
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {name}
          </Typography>
        )}
        <hr />
        <Typography className={classes.count} color="textSecondary" gutterBottom>
          {count}
        </Typography>
      </CardContent>
    </Card>
  )
}
