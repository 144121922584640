import { useState, useEffect } from "react"
import { Divider, Drawer } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { getBusyBeds, putBusyBeds, getBblockedBedsReasson, editBblockedBedsReasson } from "../../services/beds"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { format } from "date-fns"
import { KeyboardDateTimePicker } from "@material-ui/pickers"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  drawer: {
    width: 350,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 350,
    display: "flex",
    justifyContent: "space-between",
  },
  bodyState: {
    position: "relative",
    top: "20px",
    left: "20px",
    fontSize: "24px",
    fontWeight: 400,
    color: "#E3E3E3",
  },
  bodyDivider: {
    position: "relative",
    top: "35px",
  },
  bodyContainer: {
    padding: "30px",
    fontSize: "16px",
  },
  formControl: {
    position: "relative",
    top: "50px",
    left: "45px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexBetween: {
    height: "27rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonCancel: {
    borderRadius: "20px",
    position: "relative",
    left: "50px",
    paddingLeft: "20px",
    paddingRigth: "20px",
  },
  buttonAccept: {
    borderRadius: "20px",
    position: "absolute",
    left: "200px",
    paddingLeft: "20px",
    paddingRigth: "20px",
  },
  contentPicker: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  picker: {
    maxWidth: "15rem",
  },
  buttons: {
    marginBottom: "5rem",
  },
}))

const DrawerForBeds = ({ open, onClose, code, admissionCode, stateId, reload }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState()
  const [dataDrawer, setDataDrawer] = useState([])
  const [saveSelected, setSaveSelected] = useState()
  const [dischargeDate, setDischargeDate] = useState(null)

  const [checkedsProcess, setCheckedProcess] = useState({})

  useEffect(() => {
    if (stateId === 2) {
      loadReasonsBeds()
    }
    if (stateId === 3) {
      loadProcessBeds()
    }
  }, [])

  const loadReasonsBeds = async () => {
    try {
      const resp = await getBblockedBedsReasson(code)
      setDataDrawer(resp.reassons)
      const select = resp.reassons.filter((el) => el.selected)
      const value = select.length > 0 ? select[0].id : 0
      setSelected(value)
    } catch (e) {
      console.log(e)
    }
  }

  const loadProcessBeds = async () => {
    try {
      const resp = await getBusyBeds(code, admissionCode)
      setDataDrawer(resp.dischargeProcedures)
      setDischargeDate(!resp.dischargeDate ? null : handleFormatDate(resp.dischargeDate))
      const result = loadCheckedProcess(resp.dischargeProcedures)
      setCheckedProcess(result)
      setSaveSelected(resp.dischargeProcedures)
    } catch (e) {
      console.log(e)
    }
  }

  const saveProcessBusy = async () => {
    try {
      const resp = await putBusyBeds(code, admissionCode, {
        dischargeDate: dischargeDate,
        dischargeProcedures: saveSelected,
      })
      loadProcessBeds()
      reload()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const saveReasonsBeds = async () => {
    try {
      const resp = await editBblockedBedsReasson(code, { reassons: saveSelected })
      reload()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const changeCheckedReasson = (event) => {
    setSelected(Number(event.target.value))
    const formatSelected = dataDrawer.map((el) => {
      if (el.id === Number(event.target.value)) {
        return { ...el, selected: true }
      }
      return { ...el, selected: false }
    })
    setSaveSelected(formatSelected)
  }

  const loadCheckedProcess = (data) => {
    const roles = data?.reduce((a, obj) => {
      let rObj = { ...a }
      rObj[`${obj.id}checked`] = obj.selected
      return rObj
    }, {})
    return roles
  }

  const changeCheckedProcess = (event) => {
    setCheckedProcess({ ...checkedsProcess, [event.target.name]: event.target.checked })
    const arr = []
    for (let i = 0; i < saveSelected.length; i++) {
      if (saveSelected[i].id === Number(event.target.value)) {
        saveSelected[i]["selected"] = !saveSelected[i]["selected"]
        arr.push(saveSelected[i])
      } else {
        arr.push(saveSelected[i])
      }
    }
    setSaveSelected(arr)
  }

  const handleFormatDate = (date) => {
    const formatDate = format(new Date(date), "yyyy-MM-dd HH:mm:ss")
    return formatDate
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <div>
        <h2 className={classes.bodyState}>{stateId === 3 ? `PROCEDIMIENTOS` : `MOTIVOS`}</h2>
        <Divider className={classes.bodyDivider} />
        <div className={classes.flexBetween}>
          <div className={classes.flexColumn}>
            {stateId === 3 ? (
              dataDrawer?.map((el) => (
                <FormControlLabel
                  key={el.id}
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={checkedsProcess[`${el.id}checked`]}
                      onChange={changeCheckedProcess}
                      name={`${el.id}checked`}
                      value={el.id}
                    />
                  }
                  label={el.name}
                />
              ))
            ) : (
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={selected}
                onChange={changeCheckedReasson}
                className={classes.formControl}
              >
                {dataDrawer?.map((el) => (
                  <FormControlLabel key={el.id} value={el.id} control={<Radio />} label={el.name} />
                ))}
              </RadioGroup>
            )}
          </div>
        </div>
      </div>

      <div className={classes.buttons}>
        <Button variant="outlined" color="primary" className={classes.buttonCancel} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonAccept}
          onClick={stateId !== 3 ? saveReasonsBeds : saveProcessBusy}
        >
          Aceptar
        </Button>
      </div>
    </Drawer>
  )
}

export default DrawerForBeds
