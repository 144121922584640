import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: "2.3rem",
      color: "#566573",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
        margin: "0 0 1rem",
        [theme.breakpoints.down("md")]: {
          margin: "2rem 0 1rem",
        },
      },
    }
  }))

export const HistoryHeader = () => {

    const classes = useStyles()

    return <Typography variant="h3" className={classes.title}>
                Histórico de Camas
            </Typography>
}
