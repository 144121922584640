// EXTERNAL COMPONENTS
import { Button, makeStyles, Input } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"

// INTERNAL COMPONENTS
import { useState, useContext, useEffect } from "react"
import { svg } from "../../utils/svgHelper"
import { architechtureStyles } from "../../utils/architechtureStyles"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { createSingleArea } from "../../services/architechture"
import PopUp from "../PopUp"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const NewArea = ({ unit, setAreaId }) => {
  const { setAddUnit, setAreas, areas } = useContext(DashboardUnit)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [createArea, setCreateArea] = useState(false)
  const [areaName, setAreaName] = useState("")

  // ERROR IN AREA CREATION
  const [areaError, setAreaError] = useState(false)

  const handleClickCreateArea = () => {
    setCreateArea(true)
    setAddUnit(true)
  }

  useEffect(() => {
    return () => {
      setAddUnit(false)
    }
  })

  const handleChangeCreateArea = (e) => {
    setAreaError(false)
    const value = e.target.value
    setAreaName(value.toUpperCase())
  }

  const onOpenPopUp = () => {
    setOpenPopUp(true)
  }

  const onClosePopUp = () => {
    setOpenPopUp(false)
  }

  const handleSubmitArea = async () => {
    if (!areaName) setAreaError(true)
    if (areaName) {
      onOpenPopUp()
      const resp = await createSingleArea({ name: areaName, unitId: unit })
      setAreaError(false)
      setTimeout(() => {
        setAreaId(resp.areaId)
        setAreas([...areas, resp])
      }, 2000)
    }
  }

  const handleClickDischargeChanges = async () => {
    setAreaName("")
    setCreateArea(false)
    setAreaError(false)
  }

  const classes = useStyles()
  return (
    <>
      {!createArea ? (
        <>
          <div className={classes.svgContainer}>
            <div>{svg}</div>
          </div>

          <div className={classes.textAndButtonContainer}>
            <p className={classes.textP}>Todavía no hay áreas en esta unidad</p>

            <div className={classes.addAreaButton} onClick={() => handleClickCreateArea()}>
              <Button variant="contained" className={classes.addUnitIcon} startIcon={<AddIcon />}>
                Agregar área
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.areaAndEditContainer}>
            <div className={areaError ? classes.areaContainerError2 : classes.areaContainer}>
              <Input
                className={classes.addAreaInput}
                placeholder="NOMBRE DE ÁREA"
                inputProps={{ "aria-label": "description" }}
                disableUnderline={true}
                onChange={(e) => handleChangeCreateArea(e)}
                autoFocus
                value={areaName}
              />
            </div>
            <div className={classes.edit2Container}>
              <div className={classes.closeIconContainer} onClick={() => handleClickDischargeChanges()}>
                <CloseIcon className={classes.editUnitIcon} />
                <p className={classes.editUnitP}>Descartar Cambios</p>
              </div>

              <div className={classes.saveIconContainer} onClick={() => handleSubmitArea()}>
                <SaveIcon className={classes.editUnitIcon} />
                <p className={classes.editUnitP}>Guardar Cambios</p>
              </div>
            </div>
          </div>
        </>
      )}

      <PopUp open={openPopUp} onClose={onClosePopUp} title="Área agregada con éxito" />
    </>
  )
}

export default NewArea
