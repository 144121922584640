// INTERNAL COMPONENTS
import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { architechtureStyles } from "../../utils/architechtureStyles"
import { getAllServices, createSingleService, editFloor, editService, deleteFloor } from "../../services/architechture"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { GreenCheckbox } from "../../views/Architecture"
import DeleteFloorModal from "../DeleteFloorModal"
import DeleteServiceModal from "../DeleteServiceModal"

import NewServiceInput from "../NewServiceInput"
import PopUp from "../../components/PopUp"

// EXTERNAL COMPONENTS
import { makeStyles, Divider, Button, Input, FormControlLabel } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import SaveIcon from "@material-ui/icons/Save"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const Card = ({
  floorId,
  areaId,
  name,
  unitId,
  urgencyFloor,
  setEditFloorInput,
  editFloorInput,
  floors,
  setFloors,
  onOpenPopUpEditFloor,
  onOpenPopUpDeleteFloor,
  onOpenPopUpEditService,
  onOpenPopUpDeleteService,
  setOpenPopUpDeleteService,
}) => {
  const [hover, setHover] = useState(false)
  const [hoverList, setHoverList] = useState(false)
  const [editDelete, setEditDelete] = useState(null)
  const [singleService, setSingleService] = useState([])
  const [addService, setAddService] = useState(false)
  const [serviceName, setServiceName] = useState("")
  const [floorName, setFloorName] = useState("")
  const [checked, setChecked] = useState(false)
  const [newService, setNewService] = useState(false)
  const [openPopUpError, setOpenPopUpError] = useState(false)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [openFloorModal, setOpenFloorModal] = useState(false)
  const [openServiceModal, setOpenServiceModal] = useState(false)
  const [deleteServiceId, setDeleteServiceId] = useState(null)
  const [deleteServiceName, setDeleteServiceName] = useState("")
  const { setEditGeneralInput, editGeneralInput } = useContext(DashboardUnit)

  // ERROR IN SERVICE CREATION
  const [serviceError, setServiceError] = useState(false)

  const onCloseServiceModal = () => {
    setOpenServiceModal(false)
  }

  const onCloseFloorModal = () => {
    setOpenFloorModal(false)
  }
  const onOpenFloorModal = () => {
    setOpenFloorModal(true)
  }

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked)
  }

  const getServices = async () => {
    const resp = await getAllServices(unitId, areaId, floorId)
    setSingleService(resp)
  }

  const handleChangeService = ({ target }) => {
    setServiceError(false)
    const { value } = target
    setServiceName(value)
  }

  const handleClickCloseIcon = (fn) => {
    setServiceError(false)
    fn(false)
    setServiceName("")
  }
  const onOpenPopUpError = () => {
    setOpenPopUpError(true)
  }
  const onClosePopUpError = () => {
    setOpenPopUpError(false)
  }
  const onClosePopUp = () => {
    setOpenPopUp(false)
  }
  const onOpenPopUp = () => {
    setOpenPopUp(true)
    setTimeout(() => onClosePopUp(), 5000)
  }

  const onActivePopUp = (fn, boolean) => {
    fn(boolean)
  }

  const handleSubmitService = async () => {
    if (!serviceName) setServiceError(true)
    if (serviceName) {
      const resp = await createSingleService({
        name: serviceName,
        unitId,
        areaId,
        floorId,
        urgencyService: urgencyFloor,
        autoCreate: true,
      })
      setServiceError(false)
      if (resp.data) {
        onOpenPopUpError()
      } else {
        const services = await getAllServices(unitId, areaId, floorId)
        setSingleService(services)
        setAddService(false)
        setNewService(false)
        setServiceName("")
        setChecked(false)
        onOpenPopUp()
      }
    }
  }

  const onHoverCardChange = (boolean, i) => {
    setHover(boolean)
    boolean ? setEditDelete(i) : setEditDelete(null)
  }
  const onHoverListChange = (boolean, i) => {
    setHoverList(boolean)
    setEditDelete(i)
  }

  const handleClickDeleteFloor = async () => {
    onOpenFloorModal()
  }
  const handleFloorEdit = async () => {
    try {
      const respFloorEdit = await editFloor(floorId, { name: floorName, urgencyFloor })
      setEditFloorInput(!editFloorInput)
      onOpenPopUpEditFloor()
      setEditGeneralInput({ ...editGeneralInput, floor: { name: null, state: false } })
    } catch (e) {
      console.log("error floor edit", e)
    }
  }
  const handleServiceEdit = async (serviceId) => {
    try {
      const respServiceEdit = await editService(serviceId, { name: serviceName, urgencyService: checked ? 1 : 0 })
      setEditGeneralInput({ ...editGeneralInput, service: { name, state: false } })
      onOpenPopUpEditService()
      getServices()
    } catch (e) {
      console.log("error floor edit", e)
    }
  }

  useEffect(() => {
    getServices()
  }, [floorId])

  const classes = useStyles()
  return (
    <>
      <div
        className={classes.card}
        key={Math.random()}
        onMouseEnter={() => (editGeneralInput.area.state ? null : onHoverCardChange(true, floorId))}
        onMouseLeave={() => onHoverCardChange(false)}
      >
        {editGeneralInput.floor.state && editGeneralInput.floor.name === name ? (
          <div className={classes.areaAndEditContainer2}>
            <div className={classes.areaContainer3}>
              <Input
                className={classes.addAreaInput3}
                placeholder={!editGeneralInput.floor.name ? "NOMBRE DEL PISO" : editGeneralInput.floor.name}
                inputProps={{ "aria-label": "description" }}
                disableUnderline={true}
                onChange={(e) => setFloorName(e.target.value)}
                autoFocus
                value={floorName}
              />
              <CloseIcon
                className={classes.editUnitIcon}
                onClick={() => setEditGeneralInput({ ...editGeneralInput, floor: { name: null, state: false } })}
              />
            </div>
          </div>
        ) : (
          <div className={hover && !editGeneralInput.service.state ? classes.contentCode2 : classes.contentCode}>
            <div className={classes.linkContainer}>
              {editGeneralInput.service.state ||
              editGeneralInput.unit.state ||
              editGeneralInput.area.state ||
              editGeneralInput.floor.state ||
              !singleService.length ? (
                <p className={classes.textData1}>{name}</p>
              ) : (
                <Link to={`/architecture/${unitId}/${areaId}/${floorId}`} className={classes.addUnitLink}>
                  <p className={classes.textData1}>{name}</p>
                </Link>
              )}
            </div>
            <div className={classes.iconMainContainer3}>
              {editDelete === floorId && (
                <div
                  className={
                    editGeneralInput.service.state || editGeneralInput.unit.state || editGeneralInput.floor.state
                      ? classes.none
                      : classes.iconContainer2
                  }
                >
                  <EditIcon
                    onClick={() => setEditGeneralInput({ ...editGeneralInput, floor: { name, state: true } })}
                  />
                  <DeleteIcon onClick={() => handleClickDeleteFloor(floorId)} />
                </div>
              )}
            </div>
          </div>
        )}
        <Divider className={classes.divider} />
        <div className={classes.services}>
          {singleService.length ? (
            <ul>
              {singleService?.map(({ name, serviceId }, i) => {
                return editGeneralInput.service.state && editGeneralInput.service.name === name ? (
                  <NewServiceInput
                    handleChangeService={handleChangeService}
                    serviceName={serviceName}
                    checked={checked}
                    handleChangeCheck={handleChangeCheck}
                    hover={hover}
                    setService={setAddService}
                    name={name}
                    state={editGeneralInput.service.state}
                    setEditGeneralInput={setEditGeneralInput}
                    editGeneralInput={editGeneralInput}
                    handleServiceEdit={handleServiceEdit}
                    edit={true}
                    serviceId={serviceId}
                    serviceError={serviceError}
                    setServiceError={setServiceError}
                  />
                ) : (
                  <div
                    key={i}
                    onMouseEnter={() =>
                      editGeneralInput.floor.state || editGeneralInput.area.state || editGeneralInput.unit.state
                        ? null
                        : onHoverListChange(true, name)
                    }
                    onMouseLeave={() =>
                      editGeneralInput.floor.state || !editGeneralInput.area.state || editGeneralInput.unit.state
                        ? onHoverListChange(false, floorId)
                        : null
                    }
                    className={editDelete === name ? classes.serviceItemDivHover : classes.serviceItemDiv}
                  >
                    <div className={classes.liContainer}>
                      <li className={classes.serviceItem}>
                        <span className={hoverList ? classes.serviceSpan2 : classes.serviceSpan}>{name}</span>
                      </li>
                      <div className={classes.iconsMainContainer}>
                        {editDelete === name && !editGeneralInput.floor.state && (
                          <div className={classes.iconsContainer}>
                            <EditIcon
                              onClick={() =>
                                setEditGeneralInput({ ...editGeneralInput, service: { name, state: true } })
                              }
                            />
                            <DeleteIcon
                              onClick={() => {
                                setDeleteServiceName(name)
                                setDeleteServiceId(serviceId)
                                setOpenServiceModal(true)
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
              {addService ? (
                <NewServiceInput
                  handleChangeService={handleChangeService}
                  serviceName={serviceName}
                  handleClickCloseIcon={handleClickCloseIcon}
                  checked={checked}
                  handleChangeCheck={handleChangeCheck}
                  handleSubmitService={handleSubmitService}
                  hover={hover}
                  setService={setAddService}
                  serviceError={serviceError}
                  setServiceError={setServiceError}
                />
              ) : null}
            </ul>
          ) : (
            <>
              {editGeneralInput.floor.state && editGeneralInput.floor.name === name ? (
                <div>
                  <Divider />
                  <div className={classes.addServiceContent3}>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <GreenCheckbox
                          checked={urgencyFloor ? urgencyFloor : checked}
                          onChange={handleChangeCheck}
                          name="checkedG"
                        />
                      }
                      label="Piso de Urgencia"
                    />
                  </div>
                  <div className={classes.saveIconContainer8} onClick={() => handleFloorEdit()}>
                    <SaveIcon className={classes.editUnitIcon} />
                    <p className={classes.editUnitP}>Guardar Cambios</p>
                  </div>
                </div>
              ) : (
                <div className={classes.noServiceContainer} style={{ display: newService ? "none" : null }}>
                  <p className={classes.noServiceP}>Todavia no hay servicios en este piso</p>
                  <Button
                    onClick={() => setNewService(true)}
                    variant="contained"
                    className={classes.iconAdd2}
                    startIcon={<AddIcon />}
                    disabled={
                      editGeneralInput.floor.state ||
                      editGeneralInput.service.state ||
                      editGeneralInput.area.state ||
                      editGeneralInput.unit.state
                    }
                  >
                    Agregar servicio
                  </Button>
                </div>
              )}
            </>
          )}
          <ul>
            {newService ? (
              <NewServiceInput
                handleChangeService={handleChangeService}
                serviceName={serviceName}
                handleClickCloseIcon={handleClickCloseIcon}
                checked={checked}
                handleChangeCheck={handleChangeCheck}
                handleSubmitService={handleSubmitService}
                hover={hover}
                setService={setNewService}
                serviceError={serviceError}
                setServiceError={setServiceError}
              />
            ) : null}
          </ul>
        </div>
        {}
        {singleService.length ? (
          <>
            {editGeneralInput.floor.state && editGeneralInput.floor.name === name ? (
              <div>
                <Divider />
                <div className={classes.addServiceContent2}>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                      <GreenCheckbox
                        checked={urgencyFloor ? urgencyFloor : checked}
                        onChange={handleChangeCheck}
                        name="checkedG"
                      />
                    }
                    label="Piso de Urgencia"
                  />
                </div>
                <div className={classes.saveIconContainer8} onClick={() => handleFloorEdit()}>
                  <SaveIcon className={classes.editUnitIcon} />
                  <p className={classes.editUnitP}>Guardar Cambios</p>
                </div>
              </div>
            ) : (
              <div
                className={hover ? classes.addServiceContainer2 : classes.addServiceContainer}
                onClick={() =>
                  editGeneralInput.floor.state ||
                  editGeneralInput.service.state ||
                  editGeneralInput.area.state ||
                  editGeneralInput.unit.state
                    ? null
                    : setAddService(true)
                }
                style={{ display: addService ? "none" : null }}
              >
                +<span className={classes.addServiceSpan}>AGREGAR SERVICIO</span>
              </div>
            )}
          </>
        ) : null}
      </div>
      <PopUp open={openPopUp} onClose={onClosePopUp} title="Servicio agregado con éxito" />
      <PopUp
        open={openPopUpError}
        onClose={onClosePopUpError}
        errorPopUp={true}
        title="Error al crear Servicio, nombre repetido"
      />
      <DeleteFloorModal
        open={openFloorModal}
        onClose={onCloseFloorModal}
        floorId={floorId}
        floors={floors}
        setFloors={setFloors}
        name={name}
        areaId={areaId}
        unitId={unitId}
        onOpenPopUpDeleteFloor={onOpenPopUpDeleteFloor}
      />
      <DeleteServiceModal
        open={openServiceModal}
        onClose={onCloseServiceModal}
        deleteServiceId={deleteServiceId}
        deleteServiceName={deleteServiceName}
        setSingleService={setSingleService}
        unitId={unitId}
        areaId={areaId}
        floorId={floorId}
        setServiceName={setServiceName}
        onOpenPopUpDeleteService={onOpenPopUpDeleteService}
        onActivePopUp={onActivePopUp}
        setOpenPopUpDeleteService={setOpenPopUpDeleteService}
      />
    </>
  )
}

export default Card
