import React, { useEffect, useState } from 'react'
import { Chart, Legend, Line, Point, Tooltip } from 'bizcharts'
import { Typography } from '@material-ui/core'
import { handleTypeTime } from '../../../../utils/handleTypeTime'

export const TotalBedsOcuppied = ({ data }) => {

    const [maxMinValues, setMaxMinValues] = useState({ min:0, max:0 })
    const [ itemsParse, setItemParse] =  useState([])

    const getMaxValue = ()=>{ 
        if(data.length !== 0){ 

            const arrNumbers = data.map( element => Number(element.busy))

            setMaxMinValues({ max: Math.max(...arrNumbers), min: Math.min(...arrNumbers) })
        }
    }
    
    const handleParseData = ()=>{ 

        if( data.length !== 0 ){
            const arrParse = data.map( element => ({ 
                "fecha": handleTypeTime(element),
                "Ocupadas": element.busy,
                "unidad": element.unitName
            }))

            setItemParse(arrParse)
        }

    }

    useEffect(()=>{ 
        getMaxValue()
        handleParseData()
    },[])
     
    console.log(maxMinValues)
    const scale = {
        Ocupadas: { min:  maxMinValues.min, max: maxMinValues.max + 5},
        fecha: {
            type:"cat",
			range: [0, 0.98]
		}
        }

  return (
    <div style={{ width:'100%', border:'1px solid #CBD0D4' }}>
        <Typography style={{ fontSize: 20, color:'#535461', padding:'10px 0px 0px 10px', fontWeight:700 }}> Días Cama Ocupados Totales </Typography>
        <Chart key={itemsParse} scale={scale} padding={[30, 20, 60, 40]} autoFit height={320} data={itemsParse} interactions={['element-active']}>
            <Point position="fecha*Ocupadas" color="unidad" shape='circle' />
            <Line 
                shape="smooth" 
                position="fecha*Ocupadas" 
                color="unidad"
                label="temperature" 
            />
            <Tooltip shared showCrosshairs region={null} g2-tooltip-list-item={{display:'flex'}}/>
            <Legend background={{
                padding:[5,100,5,36],
                style: {
                    width:'100%',
                    fill: '#eaeaea',
                    stroke: '#fff'
                }
            }} />
        </Chart>
    </div>
  )
}
