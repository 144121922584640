import { Typography } from "@material-ui/core"
import { useState, useContext, useEffect } from "react"
import clsx from "clsx"
import { TableMaterial, TableServices } from "../../components"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import { getReservesBeds, searchBedsForDashboard } from "../../services/beds"
import CardBeds from "../../components/CardBeds"
import Divider from "@material-ui/core/Divider"
import { expiredToken } from "../../utils/expiredToken"
import { CustomContainer } from "../../components/common"
import PopUp from "../../components/PopUp"
import useStyles from "./styles"
import Button from "@material-ui/core/Button"
import GetAppIcon from "@material-ui/icons/GetApp"
import { CSVLink } from "react-csv"

import ReactExport from "react-data-export"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const GroupBeds = ({ servicename }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.nameService}>{servicename.name}</div>
      <Divider className={classes.divider} />
      <div className={classes.contentBedsGroup}>
        {servicename && servicename.beds.map((service) => <CardBeds data={service} />)}
      </div>
    </>
  )
}

const Dashboard = () => {
  const classes = useStyles()
  const { detailsUnit, openPopUpDeleteUnit, onClosePopUpDeleteUnit, unit } = useContext(DashboardUnit)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState("")
  const [reservedBeds, setReservedBeds] = useState(0)
  const [search, setSearch] = useState("")
  const [respSearch, setRespSearch] = useState([])
  const [searchRequest, setSearchRequest] = useState({
    patientRut: null,
    patientName: null,
    professionalName: null,
    admissionCode: null,
  })
  const [serchAvailable, setSerchAvailable] = useState(true)
  const [thereResult, setThereResult] = useState(true)

  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
    setSearchRequest({
      patientRut: null,
      patientName: null,
      professionalName: null,
      admissionCode: null,
    })
    setSearch("")
    setSerchAvailable(false)
  }

  const handleChangeSearch = (event) => {
    setSearchRequest({ ...searchRequest, [`${filter}`]: event.target.value })
    setSearch(event.target.value)
  }

  useEffect(() => {
    getReserveBedCount()
  }, [])

  useEffect(() => {
    getReserveBedCount()
  }, [unit])

  const getReserveBedCount = async () => {
    const res = await getReservesBeds()
    let acumulado = 0
    for (let i = 0; i < res.subServices.length; i++) {
      acumulado = acumulado + res.subServices[i].beds.length
    }
    setReservedBeds(acumulado)
  }

  useEffect(() => {
    if (!!filter && !!search) {
      getSearchBeds()
    }
  }, [searchRequest])

  const getSearchBeds = async () => {
    setIsLoading(true)
    try {
      const resp = await searchBedsForDashboard(searchRequest)
      setRespSearch(resp.subServices)
      setThereResult(resp.subServices.length)
    } catch (error) {
      console.log(error)
      if (error?.status === 401) {
        expiredToken()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const {
    areas,
    availableBedCount,
    blockedBedCount,
    busyBedCount,
    inProgressBedCount,
    name,
    totalBedCount,
    urgencyBedCount,
    reserveBedCount,
    virtualBedCount,
    inTransitBedCount,
    oncologyBedCount
  } = detailsUnit?.unit[0] || []

  const handleCalculatePercentage = (isUseBeds, totalBeds) => {
    const per = Math.floor((isUseBeds * 100) / totalBeds) || 0
    return per
  }

  const formatDataForTableServices =
    areas &&
    areas
      .map((area) =>
        area.floors.map((floor) =>
          floor.services.map((service) =>
            service.subServices.reduce(
              (a, acc) => {
                return {
                  ...a,
                  floor: `${area.name} - ${floor.name}`,
                  service: service.name,
                  inUse: acc.busyBedCount + a.inUse,
                  available: acc.availableBedCount + a.available,
                  blocked: acc.blockedBedCount + a.blocked,
                  totalBed: acc.totalBedCount + a.totalBed,
                  virtual: acc.virtualBedCount + a.virtual,
                  inTransit: acc.inTransitBedCount + a.inTransit,
                  oncology: acc.oncologyBedCount + a.oncology
                }
              },
              {
                floor: "",
                service: "",
                inUse: 0,
                available: 0,
                blocked: 0,
                totalBed: 0,
                virtual: 0,
                inTransit: 0,
                oncology: 0
              },
            ),
          ),
        ),
      )
      .flat(2)
      .map((el) => ({ ...el, occupation: handleCalculatePercentage(el.inUse, el.totalBed) }))

  const filename = new Intl.DateTimeFormat("es-CL", { dateStyle: "full", timeStyle: "short" }).format(new Date())

  return (
    <div className={classes.dashboard}>
      <div>
        <Typography variant="h4" className={classes.title}>
          Gestión de camas online
        </Typography>
      </div>
      <div>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="selected-filter-label">Filtrar por</InputLabel>
          <Select
            labelId="selected-filter-label"
            id="selected-filter"
            value={filter}
            onChange={handleChangeFilter}
            label="Filtrar por"
          >
            <MenuItem value="patientRut">RUT</MenuItem>
            <MenuItem value="patientName">Nombre Paciente</MenuItem>
            <MenuItem value="professionalName">Nombre Doctor</MenuItem>
            <MenuItem value="admissionCode">Admision Codigo</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small">
          <InputLabel htmlFor="search-input-label">Buscar</InputLabel>
          <OutlinedInput
            id="search-input-label"
            type="text"
            value={search}
            onChange={handleChangeSearch}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            labelWidth={45}
            disabled={serchAvailable}
          />
        </FormControl>
        {!thereResult && <p className={classes.messagge}>No se encontraron camas.</p>}
      </div>
      {respSearch.length > 0 ? (
        <CustomContainer lenght="0px" marginTop="20px" width="98%">
          <ul>{respSearch && respSearch.map((service, idx) => <GroupBeds servicename={service} key={idx} />)}</ul>
        </CustomContainer>
      ) : (
        <div>
          <div>
            <TableMaterial
              availableBedCount={availableBedCount}
              blockedBedCount={blockedBedCount}
              busyBedCount={busyBedCount}
              inProgressBedCount={inProgressBedCount}
              totalBedCount={totalBedCount}
              urgencyBedCount={urgencyBedCount}
              reserveBedCount={reserveBedCount}
              virtualBedCount={virtualBedCount}
              inTransitBedCount={inTransitBedCount}
              oncologyBedCount={oncologyBedCount}
            />
          </div>

          <div className={classes.tableServices}>
            <div className={classes.csvDiv}>
              <ExcelFile
                element={
                  <Button startIcon={<GetAppIcon />} variant="contained" className={classes.button}>
                    Exportar
                  </Button>
                }
                filename={filename}
              >
                <ExcelSheet data={formatDataForTableServices} name={filename}>
                  <ExcelColumn label="PISO" value="floor" />
                  <ExcelColumn label="SERVICIO" value="service" />
                  <ExcelColumn label="EN USO" value="inUse" />
                  <ExcelColumn label="DISPONIBLES" value="available" />
                  <ExcelColumn label="BLOQUEADAS" value="blocked" />
                  <ExcelColumn label="OCUPACIÓN (EN %)" value="occupation" />
                </ExcelSheet>
              </ExcelFile>
            </div>
            <TableServices detailsServices={formatDataForTableServices} />
          </div>
        </div>
      )}
      <PopUp open={openPopUpDeleteUnit} onClose={onClosePopUpDeleteUnit} title="Unidad eliminada con éxito" />
    </div>
  )
}

export default Dashboard
