import { makeStyles, useMediaQuery } from "@material-ui/core"
import SideBar from "../SideBar"
import Header from "../Header"
import UpdateDateFooter from "../UpdateDateFooter"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    minHeight: "100vh",
  },
  dashboard: {
    width: "100%",
    marginLeft: "16.25vw",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  children: {
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      padding: "0.9rem",
    },
  },
  headerTop: {
    backgroundColor: "#009DB1",
    height: "2rem",
    width: "100%",
  },
}))

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const matches = useMediaQuery("(min-width:600px)")

  return (
    <div className={classes.root}>
      {matches ? <SideBar /> : null}

      <div className={classes.dashboard}>
        <div>
          <div className={classes.headerTop} />
          <Header />
          <div className={classes.children}>{children}</div>
        </div>
        <UpdateDateFooter />
      </div>
    </div>
  )
}

export default DashboardLayout
