import React, { useState } from "react"
// EXTERNAL COMPONENTS
import { makeStyles, Input } from "@material-ui/core"

// INTERNAL COMPONENTS
import { architechtureStyles } from "../../utils/architechtureStyles"

const useStyles = makeStyles((theme) => architechtureStyles(theme))

const EditSubserviceInput = ({ subserviceName, setValueEditSub }) => {
  const classes = useStyles()
  const [value, setValue] = useState(subserviceName)

  const handleChangeEditSub = ({ target }) => {
    const { value } = target
    setValueEditSub(value.toUpperCase())
    setValue(value.toUpperCase())
  }

  return (
    <div className={classes.subServiceInputContainer}>
      <Input
        className={classes.addSubserviceInput}
        placeholder={!value ? "NOMBRE DE SUBSERVICIO" : value}
        inputProps={{ "aria-label": "description" }}
        disableUnderline={true}
        onChange={(e) => handleChangeEditSub(e)}
        autoFocus
        value={value}
      />
    </div>
    // <div className={classes.areaContainer5}>
    //   <Input
    //     className={classes.addAreaInput4}
    //     placeholder={!value ? "NOMBRE DE SUBSERVICIO" : value}
    //     inputProps={{ "aria-label": "description" }}
    //     disableUnderline={true}
    //     onChange={(e) => handleChangeEditSub(e)}
    //     autoFocus
    //     value={value}
    //   />
    // </div>
  )
}

export default EditSubserviceInput
