import { Typography } from '@material-ui/core';
import { Axis, Chart, Geom, Guide, Interval, Legend, Tooltip } from 'bizcharts';
import React, { useEffect, useState } from 'react'
const { Line } = Guide;

export const HistoricalServiceFloor = ({ data }) => {

  const [ itemsParse, setItemParse] =  useState([])

  const handleParseData = ()=>{ 

    if( data.length !== 0 ){

        const dataPlans = []
        const dataFiltereed = []
        data.forEach( element => {
            element.floors.forEach(floor =>{ 
              let name = floor.name
              floor.plans.forEach( plan =>{ 
                dataPlans.push({ unitName: element.unitName, name, planName: plan.name, mediana: plan.median })
              })
            })

            const arrFiltered = dataPlans.filter( plan => ( plan.unitName === element.unitName ))
            const firstElement = arrFiltered[0]
            const lastItem = arrFiltered[arrFiltered.length - 1]

            dataFiltereed.push({ dataFloor:[...arrFiltered], avgMedian:[{ mediana: element.medianAverage, checked: true, start: firstElement.name, end: lastItem.name }] })
        })

        setItemParse(dataFiltereed)
    }

}

useEffect(() => {
  handleParseData()
}, [data])


  const cols = {
    mediana: {
      range: [0, 1],
    }
  };

  return (
    <div style={{ width:'100%', border:'1px solid #CBD0D4' }}>
        <Typography style={{ fontSize: 20, color:'#535461', padding:'10px 0px 0px 40px', fontWeight:700 }}> Histórico de días de estadía por plan de salud </Typography>
        {
          itemsParse.length !== 0 ? itemsParse.map( item =>{ 
            return (
              <div>
                <Typography style={{ fontSize: 18, color:'#535461', padding:'10px 0px 0px 40px', fontWeight: 700, marginTop:10 }}> {item.dataFloor[0].unitName} </Typography>
                <div style={{ width: '100%', display:'flex'}}>
                  <Chart height={400} data={item.dataFloor}
                    padding={[20, 50, 50]}
                    scale={cols}
                    autoFit
                    >
                      {/* <Tooltip>
                        {
                          (title, items) => {
                            const data = items[0].data
                            return (
                              <div style={{ width:200, maxWidth:'100%', display:'flex', flexDirection:'column', padding:'0.5rem' }}>
                                <article>
                                  <p style={{ fontSize:15, fontWeight:'bold'}}> 1er Piso - MQ ONCO </p>
                                </article>
                                <article style={{ marginTop: 10 }}>
                                  <p style={{ fontSize:12, fontWeight:600}}> {data.name}: {data.value} </p>
                                </article>
                              </div>
                            );
                          }
                        }
                      </Tooltip> */}
                      <Legend visible={false} />
                      <Interval
                        size={15}
                        adjust={[
                          {
                              type: 'dodge',
                              marginRatio:0.5
                            }
                          ]}
                          color={['planName', (xVal) => {
                            if (xVal) {
                              return '#7DCEA0';
                            }
                          }]} 
                        position="name*mediana"
                      />
                      <Guide>
                        {item.avgMedian.map((item, index) => {
                          return <Line
                          top
                          start={{ name: item.start, mediana: item.mediana }}
                          end={{ name: item.end, mediana: item.mediana }}
                          style={{
                            lineWidth: 3,
                            stroke: 'red',
                          }}
                          text={{
                            position: 'end',
                            style: {
                              fill: 'black',
                              fontSize:15
                            },
                            content: `${item.mediana}`
                          }}
                          />
                        })}
                    </Guide>
                  </Chart>
                </div>
                </div>
            )
          }) 
          :
          null
        }
    </div>
);

}
